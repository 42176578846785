import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentMethods: null,
  isMethodsLoading: true,
  requisiteNumber: '',
  depositAmount: '',
  withdrawalAmount: '',
  primaryMethod: 'Fiat',
  paymentMethod: '',
  bankName: '',
  pageFrom: '',
  activeRequisite: null,
  crypto: null,
};

const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState,
  reducers: {
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    setIsMethodsLoading: (state, action) => {
      state.isMethodsLoading = action.payload;
    },
    setDepositAmount: (state, action) => {
      state.depositAmount = action.payload;
    },
    setWithdrawalAmount: (state, action) => {
      state.withdrawalAmount = action.payload;
    },
    setPrimaryMethod: (state, action) => {
      state.primaryMethod = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.paymentMethod = action.payload;
    },
    setBankName: (state, action) => {
      state.bankName = action.payload;
    },
    setPaymentState: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setPageFrom: (state, action) => {
      state.pageFrom = action.payload;
    },
    setActiveRequisite: (state, action) => {
      state.activeRequisite = action.payload;
    },
    setCryptoData: (state, action) => {
      state.crypto = action.payload;
    },
    clearState: state => {
      return {
        ...state,
        requisiteNumber: '',
        depositAmount: '',
        withdrawalAmount: '',
        paymentMethod: '',
        bankName: '',
        pageFrom: '',
        activeRequisite: null,
        crypto: null,
      };
    },
  },
});

export default paymentSlice.reducer;
export const {
  setDepositAmount,
  setWithdrawalAmount,
  setPaymentMethod,
  setBankName,
  setPaymentState,
  setPageFrom,
  setActiveRequisite,
  clearState,
  setCryptoData,
  setPrimaryMethod,
  setPaymentMethods,
  setIsMethodsLoading,
} = paymentSlice.actions;
