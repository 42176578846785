import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LoginButton from '../../../assets/components/Header/LoginButton.svg?react';
import { getAuthUrl, getRegisterUrl } from '../../../constants/ssoConstants';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { closeAllModals } from '../../../redux/slices/modalSlice';
import { Button } from '../../atoms/Button/Button';
import { LogoComponent } from '../../atoms/Logo/Logo';
import styles from './Header.module.scss';
import { ProfileIcon } from './ui/ProfileIcon';
import Support from './ui/Support';
import { Wallet } from './ui/Wallet';

export const Header = () => {
  const { crmUserData, accessToken } = useSelector(store => store.userSlice);
  const { isMobile, isTablet, isDesktop } = useWindowSize();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = getAuthUrl();
  };

  const handleRegister = () => {
    window.location.href = getRegisterUrl();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          className={styles.logo}
          onClick={() => {
            navigate('/');
            dispatch(closeAllModals());
          }}
        >
          <LogoComponent variant={isMobile ? 'small' : 'big'} />
        </div>
        {accessToken ? (
          <>
            {!isDesktop ? (
              <>
                <div className={styles.btnsWrapper}>
                  <Wallet
                    balance={(crmUserData?.wallets?.code?.credit ?? 0) + (crmUserData?.wallets?.code?.air ?? 0)}
                  />
                  {isTablet && <Support />}
                  <ProfileIcon user={crmUserData} text={isTablet ? 'Профиль' : ''} icon={isTablet} />
                </div>
              </>
            ) : (
              <div className={styles.btnsWrapper}>
                <Wallet crmUserData={crmUserData} balance={crmUserData?.wallets?.code?.credit} />
                <Wallet crmUserData={crmUserData} balance={crmUserData?.wallets?.code?.air} type='air' />
                <Support />
                <ProfileIcon user={crmUserData} text='Профиль' icon />
              </div>
            )}
          </>
        ) : isMobile ? (
          <div className={styles.btnsWrapper}>
            <Button className={styles.registerButton} buttonText='Регистрация' onClick={handleRegister} />
            <div className={styles.loginButton} onClick={handleLogin}>
              <LoginButton />
            </div>
          </div>
        ) : (
          <div className={styles.btnsWrapper}>
            <Support />
            <Button className={styles.registerButton} buttonText='Регистрация' onClick={handleRegister} />
            <ProfileIcon text='Войти' onClick={handleLogin} />
          </div>
        )}
      </div>
    </div>
  );
};
