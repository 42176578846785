import { useMutation } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import { closeAdditionModal } from '../../../../redux/slices/additionalModalSlice';
import { setIsEmailAdded } from '../../../../redux/slices/emailModalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { Button } from '../../../atoms/Button/Button';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './EmailAdd.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const EmailAdd = () => {
  const { email } = useSelector(store => store.emailModalSlice);
  const dispatch = useDispatch();

  const { postCrmRequest } = useCrmCall();

  const setEmail = useMutation({
    mutationFn: () => postCrmRequest(`/user/add_first_email`, { email }),
    onSuccess: () => {
      dispatch(setIsEmailAdded());
      dispatch(closeAdditionModal());
      dispatch(setNotifications({ type: 'success', message: 'EMAIL_ADDED' }));
    },
    onError: () => dispatch(closeAdditionModal()),
  });

  return (
    <>
      <PageMeta />
      <ModalOverlay overlayClose={false}>
        <div className={styles.modal}>
          <ModalHeader title='emailAdd' showBackArrow={false} showClose={false} />
          <div className={styles.emailAddContainer}>
            <p className={styles.areYouSure}>
              Вы уверены что хотите добавить почту: <br />
              {email}?
            </p>
            <div className={styles.buttonsContainer}>
              <Button buttonText='Назад' onClick={() => dispatch(closeAdditionModal())} />
              <Button buttonText='Продолжить' onClick={() => setEmail.mutate()} />
            </div>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
