const urlParams = new URLSearchParams(window.location.search);

export const THEME = localStorage.getItem('theme');
export const REF_CODE = urlParams.get('click_id') || localStorage.getItem('ref_code');

export const isDev = import.meta.env.VITE_IS_DEV === 'true';
export const currentProtocol = window.location.protocol;
export const currentHostname = window.location.hostname;

export const API_BASE_URL = isDev ? import.meta.env.VITE_API_URL : `${currentProtocol}//api.${currentHostname}`;

export const STATIC_URL = isDev
  ? `${import.meta.env.VITE_STATIC_DOMAIN}/`
  : `${currentProtocol}//static.${currentHostname}/`;
