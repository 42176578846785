import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { STATIC_URL } from '../../../../constants/constants';
import { getRegisterUrl } from '../../../../constants/ssoConstants';
import styles from './GameItem.module.scss';

export const GameItem = ({ id, game, activeGameId, setActiveGameId }) => {
  const { accessToken } = useSelector(store => store.userSlice);

  const navigate = useNavigate();
  const imgSource = game?.image158;
  const img = imgSource?.replace(/__domain__/g, STATIC_URL);

  const handleGameClick = () => {
    if (!accessToken) {
      window.location = getRegisterUrl();
      return;
    }

    if (activeGameId === id) {
      navigate(`/game/${game?.key}`);
    } else {
      setActiveGameId(id);
    }
  };

  return (
    <div
      onClick={handleGameClick}
      className={styles.gameIco}
      style={{ transform: activeGameId === id ? 'scale(0.95)' : 'scale(1)' }}
    >
      <img src={img} alt={`VOCXOD_${game?.translates?.en?.name}`} />
    </div>
  );
};
