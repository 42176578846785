import isMobile from 'ismobilejs';

import { useWindowSize } from './useWindowSize';

export const usePlatform = () => {
  const { isTablet } = useWindowSize();

  const mobile = isMobile(window.navigator).phone;
  const tablet = isMobile(window.navigator).tablet;

  if (mobile || tablet || (isTablet && navigator.maxTouchPoints >= 1)) {
    return 'mobile';
  }

  return 'desktop';
};
