import axios from 'axios';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import SearchIcon from '../../../../../../assets/components/SearchBar/search.svg?react';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { closeSearchModal } from '../../../../../../redux/slices/searchModalSlice';
import { GameItem } from '../../../GamesModal/ui/GameItem/GameItem';
import styles from './SearchModalContent.module.scss';

const SearchModalContent = ({ style }) => {
  const [value, setValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [searchResults, setSearchResults] = useState();
  const ref = useRef();
  const dispatch = useDispatch();
  const { isDesktop, isTablet } = useWindowSize();

  useEffect(() => {
    if (value.length > 2) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);

  const searchInputRef = useRef(null);
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  });

  // для локалки `https://test-elasticbackend.chcplatform.net/search?query=${debouncedValue}&language=ru`
  // для не локалки `${window.location.origin}/search?query=${debouncedValue}&language=ru`
  useEffect(() => {
    if (debouncedValue) {
      const fetchData = async () => {
        const { data } = await axios.get(
          import.meta.env.VITE_IS_DEV === 'true'
            ? `https://test-elasticbackend.chcplatform.net/search?query=${debouncedValue}&language=ru`
            : `${window.location.origin}/search?query=${debouncedValue}&language=ru`,
        );
        setSearchResults(data);
      };

      fetchData();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!document.body.classList.contains('disable-scroll')) {
      document.body.classList.add('disable-scroll');
      return () => {
        document.body.classList.remove('disable-scroll');
      };
    }
  }, []);

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <div className={clsx(styles.searchContainer, styles.open)}>
      <div
        className={styles.overlay}
        onClick={() => {
          setSearchResults(null);
          dispatch(closeSearchModal());
        }}
      />

      <div className={styles.wrapper} ref={ref}>
        <div className={clsx(styles.searchBar)} style={style}>
          <SearchIcon />
          <input
            ref={searchInputRef}
            className={styles.searchInput}
            value={value}
            onChange={handleChange}
            type='text'
            placeholder='Поиск'
          />
        </div>

        {!searchResults && (
          <div className={styles.noResultBlock}>
            <p>Введите от 3 символов для поиска</p>
          </div>
        )}

        {searchResults && (
          <div className={styles.searchResults}>
            <div className={styles.searchResultsGames}>
              {(isDesktop || isTablet ? searchResults : searchResults.slice(0, 12))?.map(
                item => item?.type === 'game' && <GameItem key={item?.id} game={item} />,
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModalContent;
