import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import BonusBannerMob from '../../../assets/components/BonusesPage/mob.png';
import BonusBannerPc from '../../../assets/components/BonusesPage/pc.png';
import BonusBannerTab from '../../../assets/components/BonusesPage/tab.png';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ThemeContext } from '../../../theme/ThemeContext';
import getSEOTitle from '../../../utils/getSEOTitle';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './BonusesPage.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>Казино Восход {getSEOTitle(window.location.href)} | Бонусная система</title>
    <meta
      name='description'
      content='Откройте для себя эксклюзивные бонусы онлайн казино Восход. Узнайте о приветственных бонусах, акциях и промокодах, чтобы увеличить свои шансы на выигрыш.'
    />
  </Helmet>
);
export const BonusesPage = () => {
  const { t } = useTranslation('Bonuses');
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { finalTheme } = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageMeta />
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{finalTheme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {finalTheme === 'dark' ? (
              <ArrowLeftSvg height='100%' width='100%' />
            ) : (
              <ArrowLeftDarkSvg height='100%' width='100%' />
            )}
            <h1 className={styles.title}>{t('Bonuses')}</h1>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.bonusesPageWrapper}>
            <picture>
              <source srcSet={BonusBannerMob} type='image/png' media='(max-width: 540px)' />
              <source srcSet={BonusBannerTab} type='image/png' media='(min-width: 541px) and (max-width: 1200px)' />
              <source srcSet={BonusBannerPc} type='image/png' media='(min-width: 1201px)' />
              <img src={BonusBannerPc} alt='vocxod-casino' />
            </picture>
            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>БОНУСЫ ВОСХОД – ПРОЗРАЧНО, ЧЕСТНО, БЕЗ ОГРАНИЧЕНИЙ</h2>
                <p className={styles.text}>
                  В ВОСХОДЕ бонусы работают по честной системе: никаких вейджеров, только простая цель! Достаточно
                  достичь нужного баланса, и средства сразу станут доступными для вывода.
                </p>
                <p className={styles.text}>
                  Все бонусные игры собраны в категории <a href='/category/with-bonus'>«Доступные с бонусом»</a>, а
                  максимальные депозиты дают более выгодные условия.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>БОНУСЫ ЗА ПОПОЛНЕНИЕ</h2>
                <p className={styles.text}>
                  ВОСХОД предлагает удвоение депозита как при регистрации, так и по выходным.
                </p>
                <ul>
                  <li>
                    • <a href='/news/52'>5+5 за регистрацию</a> – удвоение первого пополнения до 5 000 ₽.
                  </li>
                  <li>
                    • <strong>Бонус выходных</strong> (пятница – воскресенье) – возможность получить до 15 000 ₽. Чем
                    выше депозит, тем больше бонус.
                  </li>
                </ul>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>БОНУСЫ ЗА СТАТУСЫ</h2>
                <p className={styles.text}>
                  Чем выше ваш уровень в программе лояльности, тем больше вознаграждений. Подробности о наградах читайте
                  на странице <a href='/loyaltyPage'>«Уровни лояльности»</a>.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>ЕЖЕНЕДЕЛЬНЫЙ БУСТ</h2>
                <p className={styles.text}>
                  Каждый понедельник начисляется буст лояльности, сумма которого зависит от вашей игровой активности.
                </p>
                <ul>
                  <li>
                    • <strong>Без цели</strong> – средства можно сразу вывести или использовать для игры.
                  </li>
                  <li>
                    •{' '}
                    <strong>
                      Начисляется в <a href='/safe'>«Сейф»</a>
                    </strong>{' '}
                    – доступен для активации в разделе <a href='/profile/bonuses'>«Мои бонусы»</a>.
                  </li>
                </ul>
                <p className={styles.text}>
                  Этот бонус – дополнительное вознаграждение за вашу активность, без скрытых условий и ограничений.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>ДОПОЛНИТЕЛЬНЫЕ БОНУСЫ</h2>
                <p className={styles.text}>
                  ВОСХОД регулярно проводит розыгрыши в соцсетях и раздаёт специальные бонусы. Следите за{' '}
                  <a href='/news'>новостями</a>, чтобы не пропустить выгодные предложения.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>ЧТО ТАКОЕ ЦЕЛЬ X3?</h2>
                <p className={styles.text}>
                  Вместо привычного вейджера в ВОСХОДЕ используется цель – это сумма, которую нужно достичь, чтобы
                  перевести бонусные средства на реальный баланс.
                </p>
                <p className={styles.text}>
                  <strong>Пример:</strong>
                </p>
                <ul>
                  <li>
                    • <strong>Депозит:</strong> 5 000 ₽
                  </li>
                  <li>
                    • <strong>Бонус:</strong> 5 000 ₽
                  </li>
                  <li>
                    • <strong>Итоговый баланс:</strong> 10 000 ₽
                  </li>
                  <li>
                    • <strong>Целевой баланс:</strong> 30 000 ₽
                  </li>
                </ul>
                <p className={styles.text}>
                  Как только ваш баланс достигнет 30 000 ₽, бонус будет завершён, и все средства станут доступными для
                  вывода.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>КАК АКТИВИРОВАТЬ БОНУС?</h2>
                <ul>
                  <li>
                    • <strong>Пройдите регистрацию</strong>
                  </li>
                  <li>
                    • <strong>Сделайте депозит</strong> (если требуется)
                  </li>
                  <li>
                    • <strong>Активируйте бонус</strong> в разделе <a href='/profile/bonuses'>«Мои бонусы»</a>
                  </li>
                </ul>
                <p className={styles.text}>
                  ВОСХОД – это прозрачные бонусы без сложных условий. Активируйте, играйте и выводите средства без
                  ограничений!
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <p className={styles.text}>
                  📌 Подробнее об <a href='/news/52'>удвоении депозита за регистрацию</a>.
                </p>
                <p className={styles.text}>
                  📌 <a href='/news/29'>Как начисляется буст</a> – узнать больше.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {isMobile && <NavBarBottom />}
    </>
  );
};
