import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { STATIC_URL } from '../../../../../../constants/constants';
import { getRegisterUrl } from '../../../../../../constants/ssoConstants';
import styles from './GameItem.module.scss';

export const GameItem = forwardRef(({ game }, ref) => {
  const { accessToken } = useSelector(store => store.userSlice);

  const navigate = useNavigate();
  const imgSource = game?.image158 || game?.img;
  const img = imgSource?.replace(/__domain__/g, STATIC_URL);

  const handleGameClick = () => {
    if (!accessToken) {
      window.location = getRegisterUrl();
    } else {
      navigate(`/game/${game?.key || game?.url_key}`);
    }
  };

  return (
    <div onClick={handleGameClick} className={styles.gameIco} ref={ref}>
      {/* <div>{game?.translates?.en?.name}</div> */}
      <img src={img} alt={`VOCXOD_${game?.translates?.en?.name || game?.title_en}`} />
    </div>
  );
});
