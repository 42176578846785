import { configureStore } from '@reduxjs/toolkit';

import additionalModalSlice from './slices/additionalModalSlice';
import bonusesSlice from './slices/bonusesSlice';
import commonModalSlice from './slices/commonModalSlice';
import emailModalSlice from './slices/emailModalSlice';
import gamesSlice from './slices/gamesSlice';
import modalSlice from './slices/modalSlice';
import notificationsSlice from './slices/notificationsSlice';
import paymentSlice from './slices/paymentSlice';
import safeSlice from './slices/safeSlice';
import searchModalSlice from './slices/searchModalSlice';
import userSlice from './slices/userSlice';

const storeOptions = {
  reducer: {
    safeSlice,
    paymentSlice,
    bonusesSlice,
    gamesSlice,
    notificationsSlice,
    modalSlice,
    userSlice,
    searchModalSlice,
    emailModalSlice,
    additionalModalSlice,
    commonModalSlice,
  },
};

const store = configureStore(storeOptions);

export default store;
