import { useSelector } from 'react-redux';

import { verboxLoader } from '../../verboxLoader';

export const useVerbox = () => {
  const userState = useSelector(store => store.userSlice);

  const initVerbox = () => {
    if (userState?.accessToken) {
      verboxLoader({
        VerboxSetup: { clientId: userState?.user?.userId },
        clientInfo: {
          clientId: userState?.user?.userId,
          phone: userState?.user?.phone,
          name: userState?.user?.username,
          custom: {
            crm_id: userState?.crmUserData?.id,
          },
        },
      });
    } else {
      verboxLoader({
        VerboxSetup: { clientId: userState?.user?.userId },
      });
    }
  };

  return { initVerbox };
};
