import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import InstagramSvg from '../../../assets/components/Footer/InstagramSvg.svg?react';
import Age from '../../../assets/components/Footer/logo/age.svg?react';
import Ga from '../../../assets/components/Footer/logo/ga.svg?react';
import GameCare from '../../../assets/components/Footer/logo/gamecare.svg?react';
import Gcb from '../../../assets/components/Footer/logo/gcb.svg?react';
import MasterCard from '../../../assets/components/Footer/logo/master-card.svg?react';
import Sber from '../../../assets/components/Footer/logo/sber.svg?react';
import Sbp from '../../../assets/components/Footer/logo/sbp.svg?react';
import Tinkoff from '../../../assets/components/Footer/logo/tinkoff.svg?react';
import Tls from '../../../assets/components/Footer/logo/tls.svg?react';
import Visa from '../../../assets/components/Footer/logo/visa.svg?react';
import TGChat from '../../../assets/components/Footer/TGChat.svg?react';
import TgSvg from '../../../assets/components/Footer/TgSvg.svg?react';
import VK from '../../../assets/components/Footer/VK.svg?react';
import XSvg from '../../../assets/components/Footer/XSvg.svg?react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { closeAllModals } from '../../../redux/slices/modalSlice';
import { LogoComponent } from '../../atoms/Logo/Logo';
import styles from './Footer.module.scss';
import { useContext } from 'react';
import { ThemeContext } from '../../../theme/ThemeContext';

export const Footer = () => {
  const navigate = useNavigate();
  const { finalTheme } = useContext(ThemeContext);

  const dispatch = useDispatch();
  const { isDesktop, isTablet } = useWindowSize();

  if (isDesktop || isTablet) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.footer}>
          <div className={styles.leftContainer}>
            <div
              className={styles.logo}
              onClick={() => {
                navigate('/');
                dispatch(closeAllModals());
              }}
            >
              <LogoComponent variant='big' />
            </div>
            {(isDesktop || isTablet) && (
              <div className={styles.links}>
                <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
                  <XSvg />
                </a>
                <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
                  <TgSvg />
                </a>
                <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
                  <InstagramSvg />
                </a>
                <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
                  <VK />
                </a>
                <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
                  <TGChat />
                </a>
              </div>
            )}
          </div>

          <div className={styles.rightContainer}>
            <div className={styles.navigation}>
              <h6>Казино</h6>
              <p onClick={() => navigate('/bonuses-page')}>Бонусы</p>
              <p onClick={() => navigate('/loyaltyPage')}>Лояльность</p>
              <p
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigate('/providers');
                }}
              >
                Провайдеры
              </p>
            </div>
            <div className={styles.navigation}>
              <h6>Информация</h6>
              <p onClick={() => navigate('/news')}>Новости</p>
              <p onClick={() => navigate('/FAQ-page')}>Вопросы и ответы</p>
              <p onClick={() => navigate('/rules-page')}>Правила</p>
            </div>
          </div>
        </div>
        <div className={styles.devider} />
        <div className={styles.techs}>
          <div className={styles.techsWrapper}>
            <Sber />
            <Tinkoff />
            <Sbp />
            <Visa />
            <MasterCard />
          </div>

          <div className={styles.techsWrapper}>
            <Age />
            <GameCare />
            <Ga />
            <Gcb />
            <Tls />
          </div>
        </div>
        <div className={styles.copyright}>
          <span
            className={clsx(
              styles.licenseIcon,
              finalTheme === 'dark' ? styles.darkThemeLicense : styles.lightThemeLicense,
            )}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href='https://validator.antillelphone.com/verify-vocxodcasino' target='_blank' />
          </span>
          vocxod.com is owned and operated by Summit Dawn N.V., registered address: Santa Rosaweg 41 Curacao. Contact us
          support@vocxod.com.
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.footer}>
        <div
          className={styles.logo}
          onClick={() => {
            navigate('/');
            dispatch(closeAllModals());
          }}
        >
          <LogoComponent variant='big' />
        </div>
        <div className={styles.links}>
          <a href='https://x.com/VOCXODplay' target='_blank' className={styles.link}>
            <XSvg />
          </a>
          <a href='https://t.me/+CiXXjMmW1S5lNmEy' target='_blank' className={styles.link}>
            <TgSvg />
          </a>
          <a href='https://www.instagram.com/vocxod777' target='_blank' className={styles.link}>
            <InstagramSvg />
          </a>
          <a href='https://vk.com/vocxodcasino' target='_blank' className={styles.link}>
            <VK />
          </a>
          <a href='https://t.me/+oLFvwbzF-lM3ZTdi' target='_blank' className={styles.link}>
            <TGChat />
          </a>
        </div>
        <div className={styles.refs}>
          <h3 className={styles.refsTitle}>Казино</h3>
          <div onClick={() => navigate('/bonuses-page')}>Бонусы</div>
          <div onClick={() => navigate('/loyaltyPage')}>Лояльность</div>
          <div
            onClick={() => {
              window.scrollTo(0, 0);
              navigate('/providers');
            }}
          >
            Провайдеры
          </div>
          <h3 className={styles.refsTitle}>Информация</h3>
          <div onClick={() => navigate('/news')}>Новости</div>
          <div onClick={() => navigate('/FAQ-page')}>Вопросы и ответы</div>
          <div onClick={() => navigate('/rules-page')}>Правила</div>
        </div>
        <div className={styles.devider} />
        <div className={styles.techs}>
          <Sber />
          <Tinkoff />
          <Sbp />
          <Visa />
          <MasterCard />
        </div>
        <div className={clsx(styles.techs, styles.techsLast)}>
          <Age />
          <GameCare />
          <Ga />
          <Gcb />
          <Tls />
        </div>
        <div className={styles.copyright}>
          <span
            className={clsx(
              styles.licenseIcon,
              finalTheme === 'dark' ? styles.darkThemeLicense : styles.lightThemeLicense,
            )}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href='https://validator.antillelphone.com/verify-vocxodcasino' target='_blank' />
          </span>
          vocxod.com is owned and operated by Summit Dawn N.V., registered address: Santa Rosaweg 41 Curacao. Contact us
          support@vocxod.com.
        </div>
      </div>
    </div>
  );
};
