import { useEffect } from 'react';

export const Analytics = () => {
  useEffect(() => {
    // Функция для безопасного добавления скрипта
    const addScript = (id, callback) => {
      if (document.getElementById(id)) return;
      const script = document.createElement('script');
      script.id = id;
      script.async = true;

      if (callback) {
        script.addEventListener('load', callback);
      }

      document.head.append(script);
      return script;
    };

    // Google Analytics (GA4)
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', 'G-CE2LLHZ9XB');

      const googleScript = addScript('google-analytics');
      googleScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-CE2LLHZ9XB';
    }

    // Yandex Metrika
    if (!window.ym) {
      const yandexScript = addScript('yandex-metrika');
      yandexScript.innerHTML = `
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        ym(100556501, "init", {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true
        });
      `;

      // Добавляем noscript для Яндекс.Метрики
      if (!document.getElementById('yandex-metrika-noscript')) {
        const noscript = document.createElement('noscript');
        noscript.id = 'yandex-metrika-noscript';
        noscript.innerHTML =
          '<div><img src="https://mc.yandex.ru/watch/100556501" style="position:absolute; left:-9999px;" alt="" /></div>';
        document.body.append(noscript);
      }
    }
  }, []);

  return null;
};
