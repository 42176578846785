import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { closeAllModals } from '../../../../redux/slices/modalSlice';
import { clearState, setAmount } from '../../../../redux/slices/safeSlice';
import { getIsFlagEnabled } from '../../../../utils/getIsFlagEnabled';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { Button } from '../../../atoms/Button/Button';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import { InputWithButton } from '../../../molecules/InputWithButton/InputWithButton';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { CurrencyTransfer } from '../../../organisms/CurrencyTransfer/CurrencyTransfer';
import { getChangeUrl } from './getChangeUrl';
import { getMaxAmount } from './getMaxAmount';
import styles from './Safe.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const Safe = ({ closePortal }) => {
  const modalRef = useRef();
  const { amount, fromBalanceName } = useSelector(store => store.safeSlice);
  const { crmUserData, accessToken } = useSelector(store => store.userSlice);
  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation('Safe');

  useDisableScroll();

  useEffect(() => {
    dispatch(setAmount(''));
  }, []);

  const changeBalance = useMutation({
    mutationFn: () => postCrmRequest(`/payment${getChangeUrl(fromBalanceName)}`, { amount }),
    onSuccess: () => {
      dispatch(clearState());
      queryClient.invalidateQueries({ queryKey: ['userData'] });
    },
  });

  const handleChange = e => {
    const value = toMoneyFormat(e.target.value);

    if (value !== '') {
      dispatch(setAmount(value));
    } else {
      dispatch(setAmount(''));
    }
  };

  const handleMax = useCallback(() => {
    dispatch(setAmount(getMaxAmount(fromBalanceName, crmUserData?.wallets) ?? ''));
  }, [crmUserData, fromBalanceName]);

  const handleClosePortal = () => {
    // Если флоу через старую модалку по прямому урл
    if (pathname === '/wallet') {
      navigate('/');
    }

    dispatch(clearState());
    closePortal();
  };

  useClickOutside(modalRef, handleClosePortal);

  return (
    <>
      <PageMeta />
      <ModalOverlay>
        <div className={styles.modal} ref={modalRef}>
          <ModalHeader title='safe' closePortal={handleClosePortal} showBackArrow={false} />
          <div className={styles.bank}>
            <div className={styles.inputsContainer}>
              <ContentSwitcher active='Safe' />
              <CurrencyTransfer labelText='Перевод между кошельками' />
              <InputWithButton
                currency={t('currency')}
                placeholder='0'
                buttonText={t('inputButtonText')}
                buttonStyle='reversed'
                labelText='Сумма перевода'
                value={amount}
                onChange={handleChange}
                onButtonClick={handleMax}
              />
            </div>
            <Button
              disabled={Number(amount) === 0}
              buttonText={t('buttonText')}
              onClick={() => (amount > 0 && accessToken ? changeBalance.mutate() : null)}
            />
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
