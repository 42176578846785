import { memo } from 'react';

import styles from '../NavBarHome.module.scss';

export const NavButton = memo(({ Icon, label, isActive, onClick }) => (
  <button onClick={onClick} className={`${styles.navBarHomeItem} ${isActive ? styles.active : ''}`}>
    <Icon />
    <p>{label}</p>
  </button>
));
