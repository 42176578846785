import { useSelector } from 'react-redux';

export const useAmountLimits = paymentMethods => {
  const { paymentMethod, primaryMethod, crypto } = useSelector(store => store.paymentSlice);

  const cryptoKey = `${crypto?.currency}(${crypto?.network})`;
  const minCryptoPayin = paymentMethods?.limits?.payment?.['CRYPTO']?.[cryptoKey];
  const minCryptoPayout = paymentMethods?.limits?.payout?.['CRYPTO']?.[cryptoKey];

  const payinLimit = primaryMethod === 'CRYPTO' ? minCryptoPayin : paymentMethods?.limits?.payment[paymentMethod];
  const payoutLimit = primaryMethod === 'CRYPTO' ? minCryptoPayout : paymentMethods?.limits?.payout[paymentMethod];
  return { payinLimit, payoutLimit };
};
