import './styles/reset.scss';
import './assets/fonts/ProximaNova/stylesheet.css';
import './styles/global.scss';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import i18n from '../i18n';
import { App } from './App';
import { isDev } from './constants/constants';
import store from './redux/store';
import { ThemeProvider } from './theme/ThemeContext';
import { Analytics } from './components/organisms/Analytics/Analytics';

const queryClient = new QueryClient();

Sentry.init({
  dsn: `https://${import.meta.env.VITE_SENTRY_KEY}@sentry.${isDev ? 'chcadm.in' : window.location.host}/${import.meta.env.VITE_SENTRY_PROJECT_ID}`,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  debug: import.meta.env.VITE_SENTRY_debug,
  tracesSampleRate: import.meta.env.VITE_SENTRY_tracesSampleRate || 0,
  replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_replaysOnErrorSampleRate || 0,
  replaysSessionSampleRate: import.meta.env.VITE_SENTRY_replaysSessionSampleRate || 0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider>
          <Router>
            <Analytics />
            <App />
          </Router>
        </ThemeProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </Provider>,
);
