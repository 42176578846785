import { clsx } from 'clsx';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ArrowSvg from '../../../assets/components/Profile/ArrowSvg.svg?react';
import { openCommonModal } from '../../../redux/slices/commonModalSlice';
import { openModal } from '../../../redux/slices/modalSlice';
import { setActiveRequisite, setPaymentState } from '../../../redux/slices/paymentSlice';
import { getIsFlagEnabled } from '../../../utils/getIsFlagEnabled';
import { getMaskedRequisit } from '../../../utils/getMaskedRequisit';
import { getModalIconByMethod } from '../../../utils/getModalIconByMethod';
import { Button } from '../../atoms/Button/Button';
import DeleteSvg from './delete.svg?react';
import styles from './RequisiteSelect.module.scss';

export const RequisiteSelect = memo(() => {
  const { crmUserData } = useSelector(store => store.userSlice);
  const { paymentMethod, activeRequisite, primaryMethod, crypto } = useSelector(store => store.paymentSlice);
  const [isExpanded, setIsExpanded] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation('RequisiteSelect');
  const { t: tCrypto } = useTranslation('Crypto');

  const Icon = getModalIconByMethod(paymentMethod);
  const { pathname } = useLocation();

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCardChoose = requisite => {
    dispatch(setActiveRequisite(requisite));
  };

  const handleDelete = () => dispatch(openCommonModal({ type: 'withdrawalStep3' }));

  const requisitesByMethod =
    primaryMethod === 'Fiat'
      ? crmUserData?.cards?.filter(item => item.type === paymentMethod)
      : crmUserData?.cards?.filter(item => item.network === crypto?.network);

  useEffect(() => {
    dispatch(setActiveRequisite(null));
    dispatch(setPaymentState({ requisiteNumber: '' }));
  }, [pathname, paymentMethod]);

  return (
    <div className={clsx(styles.requisiteSelect)} onClick={toggleDropdown}>
      <label className={styles.label} htmlFor='requisiteInfo'>
        {crypto?.network ? tCrypto(`${paymentMethod}.selectLabel`) : t(`${paymentMethod}.selectLabel`)}
      </label>
      <div id='requisiteInfo' className={clsx(styles.requisiteDropdown, styles.main)}>
        {activeRequisite ? (
          <>
            <div className={styles.icon}>
              <Icon />
            </div>
            <div className={styles.requisiteInfo}>
              {activeRequisite?.bank?.name ? (
                <div className={styles.requisiteTitle}>{activeRequisite?.bank?.name}</div>
              ) : (
                <div className={styles.walletTitle}>Кошелек {activeRequisite.index}</div>
              )}

              <div className={styles.requisiteNumber}>
                {getMaskedRequisit(
                  activeRequisite?.cardNumber,
                  activeRequisite === 'CRYPTO' ? activeRequisite : paymentMethod,
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={styles.requisiteInfo}>
            <div className={styles.requisiteNumberMain}>
              {crypto?.network ? tCrypto(`${paymentMethod}.chooseRequisit`) : t(`${paymentMethod}.chooseRequisit`)}
            </div>
          </div>
        )}
        <div className={clsx(styles.requisiteArrow, isExpanded ? styles.rotate : '')}>
          <ArrowSvg />
        </div>
      </div>
      {isExpanded && (
        <div className={styles.requisiteOptions}>
          {requisitesByMethod?.length > 0 ? (
            requisitesByMethod.map((requisite, index) => {
              return (
                <div
                  key={requisite?.id}
                  onClick={() => handleCardChoose({ index: index + 1, ...requisite })}
                  className={styles.requisiteDropdown}
                >
                  <div className={styles.icon}>
                    <Icon />
                  </div>
                  <div className={styles.requisiteInfo}>
                    {requisite?.bank?.name ? (
                      <div className={styles.requisiteTitle}>{requisite?.bank?.name}</div>
                    ) : (
                      <div className={styles.walletTitle}>Кошелек {index + 1}</div>
                    )}
                    <div className={styles.requisiteNumber}>
                      {getMaskedRequisit(
                        requisite?.cardNumber,
                        activeRequisite === 'CRYPTO' ? activeRequisite : paymentMethod,
                      )}
                    </div>
                  </div>
                  <div className={styles.deleteIcon} onClick={() => handleDelete(requisite?.id)}>
                    <DeleteSvg />
                  </div>
                </div>
              );
            })
          ) : (
            <p>
              {crypto?.network
                ? tCrypto(`${paymentMethod}.notFoundRequisites`)
                : t(`${paymentMethod}.notFoundRequisites`)}
            </p>
          )}
          <Button
            buttonText={crypto?.network ? tCrypto(`${paymentMethod}.submitText`) : t(`${paymentMethod}.submitText`)}
            onClick={() => dispatch(openCommonModal({ type: 'withdrawalStep4' }))}
          />
        </div>
      )}
    </div>
  );
});
