import { useContext, useMemo } from 'react';

import a2a from '../../../../assets/components/Deposit/a2a.svg?react';
import BTC from '../../../../assets/components/Deposit/BTC.svg?react';
import cards from '../../../../assets/components/Deposit/cards.svg?react';
import ETH from '../../../../assets/components/Deposit/ETH.svg?react';
import ETHUSDC from '../../../../assets/components/Deposit/ETHUSDC.svg?react';
import LTC from '../../../../assets/components/Deposit/LTC.svg?react';
import sbp from '../../../../assets/components/Deposit/sbp.svg?react';
import TON from '../../../../assets/components/Deposit/TON.svg?react';
import TONNOT from '../../../../assets/components/Deposit/TONNOT.svg?react';
import TONUSDT from '../../../../assets/components/Deposit/TONUSDT.svg?react';
import transDark from '../../../../assets/components/Deposit/transDark.svg?react';
import transLight from '../../../../assets/components/Deposit/transLight.svg?react';
import TRXUSDT from '../../../../assets/components/Deposit/TRXUSDT.svg?react';
import { ThemeContext } from '../../../../theme/ThemeContext';

const icons = [
  { name: 'SBP P2P RUB', path: sbp },
  { name: 'MC/VISA/MIR P2P RUB', path: cards },
  { name: 'A2A RUB', path: a2a },
  {
    name: 'TRANS SBP P2P RUB',
    path: {
      light: transLight,
      dark: transDark,
    },
  },
  { name: 'BTC', path: BTC },
  { name: 'ETH', path: ETH },
  { name: 'LTC', path: LTC },
  { name: 'TON', path: TON },
  { name: 'USDC (ETH)', path: ETHUSDC },
  { name: 'NOT (TON)', path: TONNOT },
  { name: 'USDT (TRC20)', path: TRXUSDT },
  { name: 'USDT (TON)', path: TONUSDT },
];

export const usePaymentIcon = name => {
  const { finalTheme } = useContext(ThemeContext);
  return useMemo(() => {
    const method = icons.find(item => item?.name === name);
    const Icon = method?.path?.[finalTheme] || method?.path;
    return Icon ? <Icon /> : null;
  }, [name]);
};
