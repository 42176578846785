import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { closeAdditionModal } from '../../../../redux/slices/additionalModalSlice';
import { EmailAdd } from '../EmailAdd/EmailAdd';
import { SupportButtons } from '../SupportModal/SupportButtons';

const MODAL_COMPONENTS = {
  support: SupportButtons,
  email: EmailAdd,
};

export const AdditionalModal = () => {
  const { isAdditionalModalOpen, additionalModalType } = useSelector(store => store.additionalModalSlice);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeAdditionModal());
  };

  if (!isAdditionalModalOpen || !additionalModalType) return null;

  const ModalComponent = MODAL_COMPONENTS[additionalModalType];

  if (!ModalComponent) {
    return null;
  }

  return ReactDOM.createPortal(
    <ModalComponent closePortal={handleClose} />,
    document.getElementById('modal-additional'),
  );
};
