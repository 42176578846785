export const toMoneyFormat = inputValue => {
  let value = inputValue.replace(/[^\d.,]/g, '');
  value = value.replace(',', '.');
  value = value.replace(/(\..*)\./g, '$1');

  // Если первый символ точка - удаляем её
  if (value.startsWith('.')) {
    value = value.slice(1);
  }

  return value;
};
