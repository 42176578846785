/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/effect-coverflow';

import * as Progress from '@radix-ui/react-progress';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useCrmCall } from '../../../../api/useCrmCall';
import ArrowLeftSvg from '../../../../assets/components/Loyalty/ArrowLeftSvg.svg?react';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { Button } from '../../../atoms/Button/Button';
import { LoyaltyBadge } from '../../../atoms/LoyaltyBadge/LoyaltyBadge';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import { PrivilegeItem } from '../../../atoms/privilegeItem/PrivilegeItem';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import TextAccordion from '../../../molecules/TextAccordion/TextAccordion';
import styles from './Loyalty.module.scss';

const levelWords = ['Зеленый', 'Белый', 'Бронза', 'Серебро', 'Золото', 'Платина'];
const privilegeList = [
  ['Круглосуточная поддержка', 'Бонус по выходным', 'Подарок за уровень', 'Бонус на День рождения', 'Личный менеджер'],
  ['Круглосуточная поддержка', '3+3 по выходным', 'Подарок за уровень', 'Бонус на День рождения', 'Личный менеджер'],
  [
    'Круглосуточная поддержка',
    '5+5 по выходным',
    'Подарок 1000₽ за уровень',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
  [
    'Круглосуточная поддержка',
    '7+7 по выходным',
    'Подарок 3000₽ за уровень',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
  [
    'Круглосуточная поддержка',
    '10+10 по выходным',
    'Подарок 5000₽ за уровень',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
  [
    'Круглосуточная поддержка',
    '15+15 по выходным',
    'Подарок 10 000₽ за уровень',
    'Бонус на День рождения',
    'Личный менеджер',
  ],
];

const btnsData = [
  {
    limit: 'Суточный лимит 50 000₽',
    cashback: 'Еженедельный буст',
  },
  {
    limit: 'Суточный лимит 50 000₽',
    cashback: 'Еженедельный буст',
  },
  {
    limit: 'Суточный лимит 100 000₽',
    cashback: 'Еженедельный буст',
  },
  {
    limit: 'Суточный лимит 150 000₽',
    cashback: 'Еженедельный буст',
  },
  {
    limit: 'Суточный лимит 200 000₽',
    cashback: 'Еженедельный буст',
  },
  {
    limit: 'Суточный лимит 400 000₽',
    cashback: 'Еженедельный буст',
  },
];

const getDisabledPrivileges = slideIndex => {
  if (slideIndex === 0) return [0];
  if (slideIndex === 1) return [0, 1];
  if (slideIndex === 2) return [0, 1, 2];
  if (slideIndex === 3) return [0, 1, 2, 3];
  return [0, 1, 2, 3, 4];
};

const getStatusLevel = status => {
  const statusArray = ['green', 'white', 'bronze', 'silver', 'gold', 'platinum'];
  const statusIndex = statusArray.indexOf(status);
  return { level: statusIndex, word: levelWords[statusIndex] };
};

const getProgress = betData => {
  if (betData) {
    const currentBet = betData?.sum_bet;
    const total = betData?.statuses?.[betData?.status]?.to;
    const result = ((currentBet * 100) / total).toFixed(2);
    return result < 100 ? result : 100;
  }
  return 0;
};

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const Loyalty = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const [initProgress, setInitProgress] = useState(0);
  const [slider, setSlider] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();

  const { getCrmRequest } = useCrmCall();

  useDisableScroll();

  const { data: betData, error } = useQuery({
    queryKey: ['loyalty'],
    queryFn: () => getCrmRequest('/user/get_loyalty'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
    enabled: Boolean(accessToken),
  });

  getProgress(betData);

  useEffect(() => {
    if (slider && betData) {
      slider.slideTo(getStatusLevel(betData?.status).level);
    }
  }, [betData]);

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }

    setTimeout(() => {
      setInitProgress(65);
    }, 1000);
  }, []);

  const slideNext = to => {
    if (slider) {
      if (to === 'next') {
        slider.slideNext(300);
      } else if (to === 'prev') {
        slider.slidePrev(300);
      }
    }
  };

  return (
    <>
      <PageMeta />
      <ModalOverlay>
        <div className={styles.modal}>
          <ModalHeader title='loyalty' />
          <div className={styles.backWrapper}>
            <div className={styles.topContentBox}>
              <div className={styles.levelWrapper}>
                <LoyaltyBadge level={getStatusLevel(betData?.status).level + 1} />
              </div>
              {getStatusLevel(betData?.status).level + 1 >= 6 ? (
                <div className={styles.progressFinal}>
                  <p>Вы достигли максимального уровня лояльности</p>
                </div>
              ) : (
                <div className={styles.progressBox}>
                  <div className={styles.progressHeader}>
                    <span>{levelWords[getStatusLevel(betData?.status).level]}</span>
                    <span>{levelWords[getStatusLevel(betData?.status).level + 1]}</span>
                  </div>
                  <Progress.Root className={styles.progressRoot} value={65}>
                    <Progress.Indicator
                      className={styles.progressIndicator}
                      style={{ transform: `translateX(-${100 - getProgress(betData)}%)`, transition: '1s' }}
                    />
                  </Progress.Root>

                  <div className={styles.progressFooter}>
                    <span>{getProgress(betData) + '%'}</span>
                    <span>100%</span>
                  </div>
                </div>
              )}
            </div>

            <div className={styles.contentWrapper}>
              <div className={styles.mainContent}>
                <div className={styles.sliderWrapper}>
                  <div className={styles.arrowBtn}>
                    <ArrowLeftSvg
                      style={{ visibility: slider?.activeIndex !== 0 ? 'visible' : 'hidden' }}
                      onClick={() => slideNext('prev')}
                    />
                  </div>

                  <Swiper
                    effect='coverflow'
                    spaceBetween={20}
                    slidesPerView={3}
                    centeredSlides={true}
                    modules={[EffectCoverflow]}
                    coverflowEffect={{
                      rotate: 0,
                      stretch: 0,
                      depth: 60,
                      modifier: 1,
                      scale: 0.9,
                      slideShadows: false,
                    }}
                    onSlideChange={swiper => {
                      setActiveIndex(swiper.activeIndex);
                    }}
                    onSwiper={swiper => {
                      setSlider(swiper);
                      swiper.activeIndex = getStatusLevel(betData?.status).level + 1;
                      setActiveIndex(getStatusLevel(betData?.status).level + 1);
                    }}
                  >
                    {levelWords.map(el => (
                      <SwiperSlide key={el} className={styles.slide}>
                        {el}
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <div className={styles.arrowBtn}>
                    <div className={clsx(styles.arrowBtn, styles.rotate)}>
                      <ArrowLeftSvg
                        style={{ visibility: slider?.activeIndex < 5 ? 'visible' : 'hidden', strke: 'red' }}
                        onClick={() => slideNext('next')}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.content}>
                  <div className={styles.contentHead}>
                    <div className={styles.btn}>{btnsData[slider?.activeIndex]?.limit}</div>
                    <div className={styles.btn}>{btnsData[slider?.activeIndex]?.cashback}</div>
                  </div>

                  {privilegeList[slider?.activeIndex]?.map((el, i) => {
                    return (
                      <PrivilegeItem
                        key={el}
                        text={el}
                        disable={!getDisabledPrivileges(slider?.activeIndex).includes(i)}
                      />
                    );
                  })}
                </div>
              </div>

              <div className={styles.contentFooter}>
                <div className={styles.accordionWrapper}>
                  <TextAccordion
                    contentClassName={styles.accordionContent}
                    triggerClassName={styles.accordionTrigger}
                    rootClassName={styles.accordionRoot}
                    title='Как начисляются очки?'
                    description='Для повышения уровня лояльности требуется выполнить необходимый оборот ставок. Чем активнее вы играете, тем выше ваш уровень и привилегии!'
                  />
                </div>

                <TextAccordion
                  contentClassName={styles.accordionContent}
                  triggerClassName={styles.accordionTrigger}
                  rootClassName={styles.accordionRoot}
                  title='Как обновляются уровни?'
                  description='Уровни лояльности обновляются каждые 30 дней на основе выполненного оборота ставок. Если необходимый оборот не достигнут, статус понижается до актуального, и для повышения уровня нужно выполнить требования в следующем периоде.'
                />
              </div>
            </div>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
