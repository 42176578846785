import * as Progress from '@radix-ui/react-progress';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DOMPurify from 'dompurify';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../../api/useCrmCall';
import { STATIC_URL } from '../../../../../constants/constants';
import useDisableScroll from '../../../../../hooks/useDisableScroll';
import { closeAllModals, closeCurrentModal } from '../../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';
import { prepareNewsImageString } from '../../../../../utils/prepareNewsImageString';
import ModalOverlay from '../../../../atoms/ModalOverlay/ModalOverlay';
import Skeleton from '../../../../atoms/Skeleton/Skeleton';
import ModalHeader from '../../../../molecules/ModalHeader/ModalHeader';
import styles from './BonusItemModal.module.scss';
import { getButton } from './utils/getButton';
import { useBonusData } from './utils/useBonusData';

const getProgress = (target, current) => {
  if (!Number.isNaN(current)) {
    return Math.floor((current * 100) / target);
  }
  return 0;
};

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const BonusItemModal = () => {
  const { crmUserData, accessToken } = useSelector(store => store.userSlice);
  const {
    filteredBonuses: { inProgressBonus },
  } = useSelector(store => store.bonusesSlice);

  const { postCrmRequest } = useCrmCall();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useDisableScroll();

  const bonusId = Number(location?.pathname?.split('/').at(-1));

  const { data: bonus, error } = useQuery({
    queryKey: ['bonus', bonusId],
    queryFn: () => postCrmRequest('/user/get_promotion_by_id', { bonusId }),
    enabled: Boolean(bonusId && !Number.isNaN(bonusId) && accessToken),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  const currentProgress = Math.ceil(crmUserData?.wallets?.code?.credit + crmUserData?.wallets?.code?.air);
  const img = prepareNewsImageString(bonus?.promo?.image, STATIC_URL);

  const activateBonusMutation = useMutation({
    mutationFn: data => postCrmRequest('/user/promotion_client_start', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bonuses'] });
      navigate('/');
      dispatch(closeAllModals());
      dispatch(setNotifications({ type: 'success', message: 'SUCCESS_BONUS_ACTIVATE' }));
    },
  });

  const deactivateBonusMutation = useMutation({
    mutationFn: data => postCrmRequest('/user/promotion_client_cancel', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bonuses'] });
      navigate('/');
      dispatch(setNotifications({ type: 'success', message: 'SUCCESS_BONUS_DEACTIVATE' }));
    },
  });

  const handleClick = () => {
    if (bonus?.status === 'ALLOW' && accessToken) {
      return activateBonusMutation.mutate({ bonusId: bonus?.id });
    }

    if (bonus?.status === 'IN_PROGRESS' && accessToken) {
      dispatch(closeCurrentModal());
      return deactivateBonusMutation.mutate();
    }
  };

  const { minDep, maxDep, target } = useBonusData(bonus);

  return (
    <>
      <PageMeta />
      <ModalOverlay>
        <div className={styles.modal}>
          <ModalHeader title={bonus?.promo?.ru?.title} />
          <div className={styles.wrapper}>
            <div className={styles.container}>
              {!bonus ? (
                <>
                  <Skeleton key={`skel-image`} className={styles.image} />
                  <Skeleton key={`skel-textContainer`} className={styles.textContainerSkeleton} />
                </>
              ) : (
                <>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: img ? `url(${img})` : 'none',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    {bonus?.status === 'IN_PROGRESS' && (
                      <div>
                        <div className={styles.progressTargets}>
                          <p>{currentProgress || 0}</p>
                          <p>{Math.ceil(bonus?.progressData?.targetBalance)}</p>
                        </div>
                        <Progress.Root
                          className={styles.progressRoot}
                          value={getProgress(Math.ceil(bonus?.progressData?.targetBalance), currentProgress)}
                        >
                          <Progress.Indicator
                            className={styles.progressIndicator}
                            style={{
                              transform: `translateX(-${100 - getProgress(Math.ceil(bonus?.progressData?.targetBalance), currentProgress)}%)`,
                              transition: '1s',
                            }}
                          />
                        </Progress.Root>
                        <div className={styles.titleWrapper}>
                          <p className={styles.title}>Баланс</p>
                          <p className={styles.title}>Цель</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={styles.textContainer}>
                    <div className={styles.params}>
                      {bonus?.settings?.targetPercent ? (
                        <div className={styles.paramsItem}>
                          <span className={styles.paramsTitle}>Цель:</span>
                          <span className={styles.paramsValue}>x{target}</span>
                        </div>
                      ) : null}

                      {minDep > 0 ? (
                        <>
                          <div className={styles.divider} />
                          <div className={styles.paramsItem}>
                            <span className={styles.paramsTitle}>Минимальный депозит:</span>
                            <span className={styles.paramsValue}>{minDep} ₽</span>
                          </div>
                        </>
                      ) : null}
                      {maxDep > 0 ? (
                        <>
                          <div className={styles.divider} />
                          <div className={styles.paramsItem}>
                            <span className={styles.paramsTitle}>Максимальный депозит:</span>
                            <span className={styles.paramsValue}>{maxDep} ₽</span>
                          </div>
                        </>
                      ) : null}
                      {bonus?.settings?.awardPercent > 0 ? (
                        <>
                          <div className={styles.divider} />
                          <div className={styles.paramsItem}>
                            <span className={styles.paramsTitle}>Размер бонуса:</span>
                            <span className={styles.paramsValue}>{bonus?.settings?.awardPercent}%</span>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div
                      className={styles.text}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(bonus?.promo?.ru?.full_text),
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {bonus ? (
              getButton(bonus, handleClick, inProgressBonus)
            ) : (
              <Skeleton key={`skel-button`} className={styles.buttonSkeleton} />
            )}
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
