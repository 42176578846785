import { useDispatch } from 'react-redux';

import { setPaymentMethod, setPrimaryMethod } from '../redux/slices/paymentSlice';

const primaryMethods = [
  {
    name: 'Карты и СБП',
    content: 'Fiat',
  },
  {
    name: 'Криптовалюта',
    content: 'CRYPTO',
  },
];

export const usePrimaryMethods = () => {
  const dispatch = useDispatch();

  const handleSwitchPrimaryMethods = method => {
    dispatch(setPaymentMethod(''));
    dispatch(setPrimaryMethod(method));
  };

  return {
    primaryMethods,
    handleSwitchPrimaryMethods,
  };
};
