import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import TribeDarkPng from '../../../../../assets/components/Header/TribeDark.png';
import TribeLightPng from '../../../../../assets/components/Header/TribeLight.png';
import { ThemeContext } from '../../../../../theme/ThemeContext';
import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter';
import { CloseButton } from '../../../../atoms/CloseButton/CloseButton';
import Skeleton from '../../../../atoms/Skeleton/Skeleton';
import styles from './SupportHeader.module.scss';

// TODO: отдельный хэдер для кнопок саппорта. Костыль, убрать если придём к единому виду хэдеров везде
export const SupportHeader = ({ title, closePortal }) => {
  const { finalTheme } = useContext(ThemeContext);
  const { t } = useTranslation('ModalHeader');

  const mutationRef = useRef();

  const handleClosePortal = () => {
    if (closePortal) {
      return closePortal();
    }
  };

  useEffect(() => {
    const imageElement = mutationRef.current;
    if (imageElement) {
      imageElement.classList.remove(styles.fade);
      setTimeout(() => {
        imageElement.classList.add(styles.fade);
      }, 10);
    }
  }, [finalTheme]);

  return (
    <div className={styles.modalHeader}>
      <div className={styles.tribe}>
        <img
          ref={mutationRef}
          style={{ opacity: 0 }}
          src={finalTheme === 'dark' ? TribeDarkPng : TribeLightPng}
          alt={finalTheme === 'dark' ? 'tribe' : 'tribeLight'}
        />
      </div>
      <div className={styles.headerContentWrapper}>
        {title ? (
          <h3> {t(title) || capitalizeFirstLetter(title)}</h3>
        ) : (
          <Skeleton key={`skel-title`} className={styles.titleSkeleton} />
        )}
        <CloseButton onClick={handleClosePortal} noScale />
      </div>
    </div>
  );
};
