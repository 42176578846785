import { useEffect } from 'react';
import { useSelector } from 'react-redux';

/**
 * Хук для обработки кликов вне указанного элемента с учетом иерархии модалок
 * @param {React.RefObject} ref - Ссылка на элемент, клики вне которого нужно отслеживать
 * @param {Function} callback - Функция, вызываемая при клике вне элемента
 * @param {string} [excludeDataAttr='data-exclude-outside-click'] - Data-атрибут для исключения элементов
 */
export const useClickOutside = (ref, callback, excludeDataAttr = 'data-exclude-outside-click') => {
  // Получаем состояние модалок из Redux
  const isAdditionalModalOpen = useSelector(state => state.additionalModalSlice?.isAdditionalModalOpen);
  const isCommonModalOpen = useSelector(state => state.commonModalSlice?.isCommonModalOpen);

  useEffect(() => {
    const handleClick = e => {
      // Проверяем основной ref
      if (ref.current && !ref.current.contains(e.target)) {
        // Проверяем, есть ли у элемента или его родителей указанный data-атрибут
        const isExcluded = e.target.closest(`[${excludeDataAttr}]`);

        // Если клик не в исключенных элементах
        if (!isExcluded) {
          // Получаем DOM-элементы модалок
          const additionalModalEl = document.getElementById('modal-additional');
          const commonModalEl = document.getElementById('modal-common');

          // Проверяем, находится ли ref внутри common модалки
          const isInCommonModal = commonModalEl && commonModalEl.contains(ref.current);

          // Проверяем, находится ли ref внутри additional модалки
          const isInAdditionalModal = additionalModalEl && additionalModalEl.contains(ref.current);

          // Проверяем, был ли клик внутри additional модалки
          const clickedInAdditional = additionalModalEl && additionalModalEl.contains(e.target);

          // Если открыты обе модалки и текущий элемент в common модалке,
          // и клик не был внутри additional модалки, не закрываем common
          if (isAdditionalModalOpen && isCommonModalOpen && isInCommonModal && !clickedInAdditional) {
            return;
          }

          // Если текущий элемент в additional модалке, закрываем его
          if (isInAdditionalModal) {
            callback(e);
            return;
          }

          // Если текущий элемент в common модалке и additional модалка не открыта,
          // закрываем common
          if (isInCommonModal && !isAdditionalModalOpen) {
            callback(e);
          }

          // Когда ref не в модалках
          if (!isInCommonModal && !isInAdditionalModal) {
            callback(e);
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [ref, callback, excludeDataAttr, isAdditionalModalOpen, isCommonModalOpen]);
};
