export const useBonusData = bonus => {
  let depsByColor;

  if (bonus?.status === 'IN_PROGRESS') {
    depsByColor = bonus?.configByClientType?.[bonus?.progressData?.currentTicketColor];
  } else if (bonus?.ticketCount > 0) {
    depsByColor = bonus?.configByClientType?.[bonus?.nextColorTicketToUse];
  } else {
    depsByColor = bonus?.configByClientType?.green;
  }

  const target = bonus?.settings?.targetPercent / 100;

  return {
    minDep: depsByColor?.min,
    maxDep: depsByColor?.max,
    target,
  };
};
