import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../api/useCrmCall';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { setNotifications } from '../../../redux/slices/notificationsSlice';
import Skeleton from '../../atoms/Skeleton/Skeleton';
import { NewsItem } from '../News/ui/NewsItem';
import styles from './NewsSlider.module.scss';

const getSkeletonCount = (isMobile, isTablet) => {
  if (isMobile) {
    return { perLine: 1, rows: 1 };
  }
  if (isTablet) {
    return { perLine: 2, rows: 1 };
  }
  return { perLine: 4, rows: 2 };
};

const NewsSlider = ({ currentNewsId }) => {
  const { accessToken } = useSelector(store => store.userSlice);

  const { isMobile, isTablet } = useWindowSize();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { getCrmRequest } = useCrmCall();

  const { data, error, isLoading } = useQuery({
    queryKey: ['lastNews', currentNewsId],
    queryFn: () => getCrmRequest(`/news/get_last_news?id=${currentNewsId}`),
    enabled: !!currentNewsId,
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['news'] });
  }, [accessToken]);

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  if (isLoading) {
    return (
      <div className={styles.news}>
        {Array.from({ length: 1 }).map(el =>
          Array.from({ length: getSkeletonCount(isMobile, isTablet).perLine }).map((_, i) => (
            <Skeleton key={`skel-${i}`} className={styles.skeleton} />
          )),
        )}
      </div>
    );
  }

  return (
    <div className={styles.news}>
      {data?.map(newsItem => {
        return <NewsItem key={newsItem?.id} news={data?.pages} newsItem={newsItem} />;
      })}
    </div>
  );
};

export default NewsSlider;
