import { useMutation, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../../../api/useCrmCall';
import useDisableScroll from '../../../../../hooks/useDisableScroll';
import { openCommonModal } from '../../../../../redux/slices/commonModalSlice';
import { openModal } from '../../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';
import { setBankName, setPaymentState } from '../../../../../redux/slices/paymentSlice';
import { getIsFlagEnabled } from '../../../../../utils/getIsFlagEnabled';
import { Button } from '../../../../atoms/Button/Button';
import { WithdrawalBase } from '../WithdrawalBase/WithdrawalBase';
import styles from './SmsCodeModal.module.scss';

export const SmsCodeModal = ({ closePortal }) => {
  const { accessToken } = useSelector(store => store.userSlice);
  const { pageFrom, paymentMethod, requisiteNumber, bankName, primaryMethod, crypto } = useSelector(
    store => store.paymentSlice,
  );
  const [timeLeft, setTimeLeft] = useState(60);
  const inputsRef = useRef([]);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { postCrmRequest } = useCrmCall();

  const [code, setCode] = useState(new Array(4).fill(''));

  const smsMutation = useMutation({
    mutationFn: state => postCrmRequest('/payment/send_verification_sms', state),
    onSuccess: () => setTimeLeft(60),
  });

  const queryClient = useQueryClient();

  const smsCode = code?.join('');

  const mutationsMap = {
    withdrawal: useMutation({
      mutationFn: data => postCrmRequest('/payment/payout_add_requisite', data),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['userData'] });
        dispatch(openCommonModal({ type: 'withdrawalStep2' }));
        dispatch(setPaymentState({ requisiteNumber: '' }));
        dispatch(setBankName(''));
      },
      onError: error => {
        setCode(new Array(4).fill(''));
        setTimeLeft(0);
        dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
      },
    }),
  };

  const submitDataMap = {
    withdrawal: {
      reqType: primaryMethod === 'CRYPTO' ? primaryMethod : paymentMethod,
      requisite: {
        reqNum: requisiteNumber,
        bank_key: bankName,
        network: crypto?.network,
        currency: crypto?.currency,
      },
    },
  };

  const mutationData = { ...submitDataMap[pageFrom], smsCode };

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      // Проверяем, что введённое значение - цифра
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Если это не последнее поле, переводим фокус на следующее
      if (index < code.length - 1) {
        inputsRef.current[index + 1]?.focus();
      }
    } else if (value === '') {
      // Если пользователь удаляет символ
      const newCode = [...code];
      newCode[index] = '';
      setCode(newCode);

      // Переводим фокус на предыдущее поле
      if (index > 0) {
        inputsRef.current[index - 1]?.focus();
      }
    }
  };

  const handleResendCode = () => {
    // { actionType: 'add_requisite' } - хардкод, который потом надо будет исправлять если модалка появится ещё где-то
    smsMutation.mutate({ actionType: 'add_requisite' });
  };

  const handleSubmit = () => mutationsMap[pageFrom]?.mutate(mutationData);

  return (
    <WithdrawalBase closePortal={closePortal} title='smsCode' showBackArrow={false} className={styles.modal}>
      <div className={styles.smsContainer}>
        <p className={styles.modalTitle}>Введите код из СМС для подтверждения личности</p>
        <div className={styles.inputsContainer}>
          {code.map((digit, idx) => (
            <input
              key={idx}
              ref={el => (inputsRef.current[idx] = el)}
              className={clsx(styles.codeInput, mutationsMap[pageFrom]?.isError && styles.errorInput)}
              type='text'
              maxLength='1'
              value={digit}
              onChange={e => handleChange(e, idx)}
            />
          ))}
        </div>
        {timeLeft !== 0 && (
          <p className={styles.retryTimer}>
            Повторить код через: <span>{timeLeft}c</span>
          </p>
        )}
        {mutationsMap[pageFrom]?.isError ? <p className={styles.errorMessage}>Неверный код из СМС</p> : null}
        {timeLeft === 0 && (
          <p className={styles.getNewCode} onClick={handleResendCode}>
            Отправить новый код
          </p>
        )}
        <Button
          buttonText='Продолжить'
          isLoading={smsMutation.isPending || mutationsMap[pageFrom]?.isPending}
          onClick={accessToken && handleSubmit}
        />
      </div>
    </WithdrawalBase>
  );
};
