import { useContext } from 'react';

import { ThemeContext } from '../../../../../theme/ThemeContext';
import MoonSVG from '../assets/Moon.svg?react';
import SunSVG from '../assets/Sun.svg?react';
import styles from './ThemeSwitcher.module.css';

export const ThemeSwitcher = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div className={styles.themeSwitcher}>
      <button
        className={theme === 'light' ? styles.active : styles.inActive}
        onClick={() => {
          if (theme !== 'light') {
            setTheme('light');
          } else {
            setTheme('system');
          }
        }}
      >
        <SunSVG />
        Светлая
      </button>
      <button
        className={theme === 'dark' ? styles.active : styles.inActive}
        onClick={() => {
          if (theme !== 'dark') {
            setTheme('dark');
          } else {
            setTheme('system');
          }
        }}
      >
        <MoonSVG />
        Темная
      </button>
    </div>
  );
};
