import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { openCommonModal } from '../../../redux/slices/commonModalSlice';
import { setPaymentMethod } from '../../../redux/slices/paymentSlice';
import { getIsFlagEnabled } from '../../../utils/getIsFlagEnabled';
import styles from './ContentSwitcher.module.scss';

export const ContentSwitcher = ({ active = 'Deposit', buttonsMap, handleClick, variant = 'primary' }) => {
  const { t } = useTranslation('ContentSwitcher');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultButtons = [
    {
      name: t('deposit'),
      content: 'Deposit',
    },
    {
      name: t('withdrawal'),
      content: 'Withdrawal',
    },
    {
      name: t('safe'),
      content: 'Safe',
    },
  ];

  const buttons = buttonsMap || defaultButtons;

  return (
    <div className={clsx(styles.contentSwitcher, variant === 'secondary' && styles.contentSwitcherSecondary)}>
      {buttons.map(item => (
        <button
          key={item.content}
          className={clsx(
            item.content === active ? styles.active : styles.inActive,
            variant === 'secondary' && (item.content === active ? styles.activeSecondary : styles.inActiveSecondary),
          )}
          onClick={() => {
            dispatch(setPaymentMethod(''));
            if (buttonsMap) {
              handleClick(item.content);
            } else {
              dispatch(openCommonModal({ type: item.content.toLowerCase() }));
            }
          }}
        >
          {item.name}
        </button>
      ))}
    </div>
  );
};
