import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import { getRegisterUrl } from '../../../constants/ssoConstants';
import { usePlatform } from '../../../hooks/usePlatform';
import styles from './GamePage.module.scss';
import { DesktopLayout } from './ui/DesktopLayout';

const PageMeta = ({ title, description }) => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>{title}</title>
    <meta name='description' content={description} />
  </Helmet>
);

const GamePage = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const location = useLocation();

  const { postCrmRequest } = useCrmCall();

  const gameKey = location?.pathname?.split('/')?.at(-1);
  // Определение платформы для того, чтобы игра отображалась корректно
  const platform = usePlatform();
  const exitURL = `${window.location.origin}/providers`;
  const cashierURL = `${window.location.origin}/deposit`;

  const { data: gameUrl = '' } = useQuery({
    queryKey: ['gameSession', gameKey, platform, exitURL, cashierURL],
    queryFn: () => postCrmRequest('/games/get_game_session', { gameKey, platform, exitURL, cashierURL }),
    //TODO: gameKey.includes('_') - костыль чтобы не перезапрашивались данные, надо исправить в будущем!
    enabled: Boolean(accessToken && gameKey.includes('_')),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  const gameData = useQuery({
    queryKey: ['gameData', gameKey],
    queryFn: () => postCrmRequest('/games/game_by_key', { gameKey }),
    //TODO: gameKey.includes('_') - костыль чтобы не перезапрашивались данные, надо исправить в будущем!
    enabled: Boolean(accessToken && gameKey.includes('_')),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  useEffect(() => {
    if (!accessToken) {
      window.location = getRegisterUrl();
    }
  }, [accessToken]);

  return (
    <>
      <PageMeta
        title={gameData?.data?.game?.translates?.en?.name || ''}
        description={gameData?.data?.game?.translates?.en?.short_description || ''}
      />
      {platform !== 'mobile' ? (
        <DesktopLayout gameData={gameData} gameUrl={gameUrl} gameKey={gameKey} />
      ) : (
        <iframe
          className={styles.iframe}
          src={gameUrl}
          title={gameKey}
          style={{
            borderRadius: 0,
          }}
          allowFullScreen={true}
        />
      )}
    </>
  );
};

export default GamePage;
