import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../api/useCrmCall';
import useDisableScroll from '../../../../hooks/useDisableScroll';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { ContentSwitcher } from '../../../atoms/ContentSwitcher/ContentSwitcher';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { TransactionItem } from '../../../molecules/TransactionItem/TransactionItem';
import styles from './Transactions.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const Transactions = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const [activeTab, setActiveTab] = useState('all');
  const dispatch = useDispatch();
  const { t } = useTranslation('Transactions');

  const { postCrmRequest } = useCrmCall();

  useDisableScroll();

  const { data: history, error } = useQuery({
    queryKey: ['transactionsHistory'],
    queryFn: () => postCrmRequest('/payment/transaction_history'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
    enabled: Boolean(accessToken),
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  const buttons = [
    {
      name: 'Все',
      content: 'all',
    },
    {
      name: 'Депозиты',
      content: 'payin',
    },
    {
      name: 'Выводы',
      content: 'payout',
    },
  ];

  const filteredHistory = activeTab === 'all' ? history : history?.filter(itm => itm?.type === activeTab);

  return (
    <>
      <PageMeta />
      <ModalOverlay>
        <div className={styles.modal}>
          <ModalHeader title='transactions' />
          <div className={styles.transactions}>
            <div className={styles.switcherWrapper}>
              <ContentSwitcher active={activeTab} buttonsMap={buttons} handleClick={setActiveTab} />
            </div>
            <div className={styles.transactionsList}>
              {filteredHistory?.length === 0 ? (
                <p className={styles.notFound}>{t('noFound')}</p>
              ) : (
                filteredHistory?.map((item, index) => (
                  <TransactionItem key={item?.date} item={item} last={index === filteredHistory?.length - 1} />
                ))
              )}
            </div>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
