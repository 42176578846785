import clsx from 'clsx';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import Login from '../../../../assets/components/Header/LoginButton.svg?react';
import Stripes from '../../../../assets/components/Header/Stripes.svg?react';
import avatar from '../../../../assets/components/Profile/pfp.png';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { openCommonModal } from '../../../../redux/slices/commonModalSlice';
import { ThemeContext } from '../../../../theme/ThemeContext';
import styles from './ProfileIcon.module.scss';

export const ProfileIcon = ({ text, userLoading, onClick, icon = false }) => {
  const { isMobile } = useWindowSize();
  const { finalTheme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(openCommonModal({ type: 'profile' }));
  };

  if (!isMobile) {
    return (
      <>
        <button className={styles.desktopWrapper} onClick={onClick || (() => handleOpenModal())}>
          <div className={styles.blob} />
          {text === 'Войти' ? (
            <Login className={clsx(styles.icon, finalTheme === 'dark' ? '' : styles.light)} />
          ) : (
            <Stripes className={styles.icon} />
          )}
          <p>{text}</p>
          {icon && <img src={avatar} alt='Аватар' />}
        </button>
      </>
    );
  }

  return (
    <button className={styles.wrapper} onClick={() => handleOpenModal()}>
      {userLoading ? <p>Загрузка...</p> : <img src={avatar} alt='Аватар' />}
    </button>
  );
};
