import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { closeCommonModal } from '../../../../redux/slices/commonModalSlice';
import { Deposit } from '../Deposit/Deposit';
import { Profile } from '../Profile/Profile';
import { Safe } from '../Safe/Safe';
import { SmsCodeModal } from '../WithdrawalCascade/SmsCodeModal/SmsCodeModal';
import { Withdrawal } from '../WithdrawalCascade/Withdrawal/Withdrawal';
import { WithdrawalRequisites } from '../WithdrawalCascade/WithdrawalRequisites/WithdrawalRequisites';
import { WithdrawalRequisitesAdd } from '../WithdrawalCascade/WithdrawalRequisitesAdd/WithdrawalRequisitesAdd';
import { WithdrawalRequisitesDelete } from '../WithdrawalCascade/WithdrawalRequisitesDelete/WithdrawalRequisitesDelete';
import { clearState } from '../../../../redux/slices/paymentSlice';

const MODAL_COMPONENTS = {
  profile: Profile,
  deposit: Deposit,
  safe: Safe,
  withdrawal: Withdrawal,
  withdrawalStep2: WithdrawalRequisites,
  withdrawalStep3: WithdrawalRequisitesDelete,
  withdrawalStep4: WithdrawalRequisitesAdd,
  withdrawalStep5: SmsCodeModal,
};

export const CommonModal = () => {
  const { isCommonModalOpen, commonModalType } = useSelector(store => store.commonModalSlice);
  const dispatch = useDispatch();

  const handleClose = type => {
    if (type === 'withdrawal') {
      dispatch(clearState());
    }
    dispatch(closeCommonModal());
  };

  if (!isCommonModalOpen || !commonModalType) return null;

  const ModalComponent = MODAL_COMPONENTS[commonModalType];

  if (!ModalComponent) {
    return null;
  }

  return ReactDOM.createPortal(<ModalComponent closePortal={handleClose} />, document.getElementById('modal-common'));
};
