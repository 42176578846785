import { clsx } from 'clsx';
import { memo } from 'react';

import styles from './NumberInput.module.scss';

/**
 * Компонент NumberInput отображает стилизованное числовое поле ввода.
 *
 * @param {string} props.name - Атрибут name.
 * @param {string|number} props.value - Значение элемента.
 * @param {string} [props.labelText] - Текст лэйбла (опционально).
 * @param {string} [props.step='0.0001'] - Шаг изменения значения.
 * @param {string|number} [props.min='0'] - Минимальное значение.
 * @param {string} [props.placeholder='0,00000'] - Плэйсхолдер.
 * @param {boolean} [props.disabled=false] - Отключение поля ввода.
 * @param {Function} [props.onChange=() => {}] - Колбэк, вызываемый при изменении значения.
 */
const NumberInputComponent = ({
  name,
  value,
  labelText,
  step = '0.0001',
  min = '0',
  placeholder = '0',
  disabled = false,
  onChange = () => {},
}) => {
  return (
    <div className={styles.field}>
      {labelText && (
        <label className={clsx(styles.label, disabled && styles.disabled)} htmlFor={name}>
          {labelText}
        </label>
      )}
      <div className={clsx(styles.fieldInput, disabled && styles.disabled)}>
        <div className={styles.iCO}>
          <p>₽</p>
        </div>
        <input
          type='text'
          id={name}
          value={value}
          name={name}
          step={step}
          min={min}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export const NumberInput = memo(NumberInputComponent);
