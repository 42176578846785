import { useContext } from 'react';

import Bg from '../../../assets/components/404/bg.svg?react';
import BgLight from '../../../assets/components/404/bgLight.svg?react';
import Error from '../../../assets/components/404/Error.svg?react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ThemeContext } from '../../../theme/ThemeContext';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './TechnicalWorks.module.scss';

export const TechnicalWorks = () => {
  const { isMobile } = useWindowSize();
  const { finalTheme } = useContext(ThemeContext);
  return (
    <>
      <Header />
      <div className={styles.container}>
        {finalTheme === 'dark' ? <Bg className={styles.background} /> : <BgLight className={styles.background} />}
        <div className={styles.content}>
          <Error className={styles.errorCode} />
          <h3 className={styles.notFoundHead}>Что-то пошло не так!</h3>
          <p className={styles.notFoundText}>Страница не существует либо временно недоступна.</p>
        </div>
      </div>
      {isMobile && <NavBarBottom />}
      {!isMobile && <Footer />}
    </>
  );
};
