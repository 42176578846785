import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import ReverseButton from '../../../assets/components/CurrencyTransfer/ReverseButton.svg?react';
import { setFromBalanceName, setToBalanceName } from '../../../redux/slices/safeSlice';
import { TransferInput } from '../../molecules/TransferInput/TransferInput';
import styles from './CurrencyTransfer.module.scss';

export const CurrencyTransfer = ({ labelText }) => {
  const dispatch = useDispatch();
  const { fromBalanceName, toBalanceName } = useSelector(store => store.safeSlice);

  const handleReverse = () => {
    dispatch(setFromBalanceName(toBalanceName));
    dispatch(setToBalanceName(fromBalanceName));
  };

  return (
    <>
      {labelText && (
        <label className={clsx(styles.label)} htmlFor='currencyTransfer'>
          {labelText}
        </label>
      )}
      <div className={styles.transferContainer} id='currencyTransfer'>
        <TransferInput balanceName={fromBalanceName} />
        <div className={styles.devider} />
        <TransferInput balanceName={toBalanceName} />
        <div className={styles.reverseButton} onClick={handleReverse}>
          <ReverseButton />
        </div>
      </div>
    </>
  );
};
