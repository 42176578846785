export const openTelegramBot = () => {
  const botUsername = 'Vocxod_sup1_bot';
  const deepLink = `tg://resolve?domain=${botUsername}`;
  const webLink = `https://t.me/${botUsername}`;

  // Функция для открытия веб-версии
  const openWebVersion = () => {
    window.open(webLink, '_blank');
  };

  // Проверяем поддержку
  if (!window || !document) {
    openWebVersion();
    return;
  }

  // Определяем Safari
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // Если Safari, сразу открываем веб-версию
  if (isSafari) {
    openWebVersion();
    return;
  }

  // Создаем скрытый iframe для открытия deeplink без создания popup
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = deepLink;
  document.body.append(iframe);

  // Запоминаем время и фокус
  const startTime = Date.now();
  const wasInFocus = document.hasFocus();

  // Устанавливаем таймер для проверки
  setTimeout(() => {
    // Удаляем iframe в любом случае
    if (document.body.contains(iframe)) {
      iframe.remove(); // Используем remove() вместо parentNode.removeChild()
    }

    // Проверяем, был ли переход в приложение
    const timeElapsed = Date.now() - startTime;
    const stillInFocus = document.hasFocus();

    // Если пользователь все еще на странице через 1 секунду,
    // значит приложение не открылось - открываем веб-версию
    if (timeElapsed > 1000 && stillInFocus && wasInFocus) {
      openWebVersion();
    }
  }, 1500);
};
