import { STATIC_URL } from '../constants/constants';

export const replaceImagesDomain = html => {
  return html
    .replace(/src="([^"]*__domain__[^"]*)"/g, (match, url) => `src="${STATIC_URL}${url.replace('__domain__', '')}"`)
    .replace(
      /srcset="([^"]*__domain__[^"]*)"/g,
      (match, url) => `srcset="${STATIC_URL}${url.replace('__domain__', '')}"`,
    );
};
