import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

const ThemeContext = createContext();

const getAppliedTheme = theme => {
  const systemPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return theme === 'system' ? (systemPrefersDark ? 'dark' : 'light') : theme;
};

const ThemeProvider = ({ children }) => {
  // Вычисляем всё при инициализации
  const [state, setState] = useState(() => {
    const savedTheme = localStorage.getItem('theme') || 'system';
    const computedTheme = savedTheme === 'system' ? getAppliedTheme(savedTheme) : savedTheme;
    document.documentElement.dataset.theme = computedTheme;
    return { theme: savedTheme, finalTheme: computedTheme };
  });

  const setTheme = useCallback(newTheme => {
    const computedTheme = newTheme === 'system' ? getAppliedTheme(newTheme) : newTheme;
    document.documentElement.dataset.theme = computedTheme;
    localStorage.setItem('theme', newTheme);
    setState({ theme: newTheme, finalTheme: computedTheme });
  }, []);

  useEffect(() => {
    if (state.theme === 'system') {
      const systemThemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      const handleChange = e => {
        const newTheme = e.matches ? 'dark' : 'light';
        document.documentElement.dataset.theme = newTheme;
        setState(prev => ({ ...prev, finalTheme: newTheme }));
      };

      systemThemeQuery.addEventListener('change', handleChange);
      return () => systemThemeQuery.removeEventListener('change', handleChange);
    }
  }, [state.theme]);

  const value = useMemo(
    () => ({
      theme: state.theme,
      finalTheme: state.finalTheme,
      setTheme,
    }),
    [state.theme, state.finalTheme, setTheme],
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export { ThemeContext, ThemeProvider };
