import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { API_BASE_URL } from '../constants/constants';
import { setNotifications } from '../redux/slices/notificationsSlice';
import { login, logout } from '../redux/slices/userSlice';
import { makeRequest } from './makeRequest';
import { closeCommonModal } from '../redux/slices/commonModalSlice';
import { closeAdditionModal } from '../redux/slices/additionalModalSlice';
import { closeAllModals } from '../redux/slices/modalSlice';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export const useCrmCall = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Переменные для управления обновлением токена
  let isRefreshing = false;
  let refreshPromise = null;
  let pendingRequests = [];

  const showNotification = message => {
    dispatch(setNotifications({ type: 'error', message }));
  };

  // Обработка очереди запросов после обновления токена
  const processPendingRequests = (error, token = null) => {
    pendingRequests.forEach(({ resolve, reject }) => {
      if (error) {
        reject(error);
      } else {
        resolve(token);
      }
    });
    pendingRequests = [];
  };

  // Функция обновления токена
  const refreshToken = () => {
    if (isRefreshing) {
      return refreshPromise;
    }

    isRefreshing = true;
    refreshPromise = apiClient
      .post('/auth/refresh_token', {}, { withCredentials: true })
      .then(response => {
        const data = response.data;
        if (data?.token && data?.user) {
          dispatch(login({ user: data.user, token: data.token }));
          processPendingRequests(null, data.token);
          return data.token;
        } else {
          throw new Error('Invalid refresh token response');
        }
      })
      .catch(error => {
        dispatch(logout());
        dispatch(closeCommonModal());
        dispatch(closeAdditionModal());
        dispatch(closeAllModals());
        navigate('/');
        processPendingRequests(error);
        throw error;
      })
      .finally(() => {
        isRefreshing = false;
      });

    return refreshPromise;
  };

  // Обработка ошибок запросов
  const handleError = async (error, originalRequest) => {
    Sentry.captureException(error);

    const status = error?.response?.status;
    const backendMessage = error?.response?.data?.message;
    const isSnakeCase = /^[A-Z0-9_]+$/.test(backendMessage);

    if (backendMessage === 'ERROR_REFRESH_TOKEN') {
      return;
    }

    // Обработка 401 с попыткой обновления токена
    if (status === 401 && originalRequest) {
      try {
        let token;

        if (isRefreshing) {
          // Если токен уже обновляется, добавляем запрос в очередь
          token = await new Promise((resolve, reject) => {
            pendingRequests.push({ resolve, reject });
          });
        } else {
          // Иначе запускаем обновление токена
          token = await refreshToken();
        }

        // Создаем новый объект запроса с обновленным токеном
        const newRequest = {
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `Bearer ${token}`,
          },
        };

        return axios(newRequest);
      } catch (refreshError) {
        showNotification('DEFAULT');
        throw refreshError;
      }
    }

    if (status === 405) {
      showNotification('DEFAULT');
      return;
    }

    if (status === 404) {
      navigate('*');
      if (backendMessage && isSnakeCase) {
        showNotification(backendMessage);
      }
      return;
    }

    if (status >= 500 && status < 600) {
      navigate('/technical-works');
      return;
    }

    if (backendMessage && isSnakeCase) {
      showNotification(backendMessage);
      return;
    }

    showNotification('DEFAULT');
  };

  return {
    getCrmRequest: (url, params = {}) => makeRequest('get', url, params, accessToken, handleError),
    postCrmRequest: (url, data) => makeRequest('post', url, data, accessToken, handleError),
    refreshToken,
  };
};
