import { clsx } from 'clsx';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useVerbox } from '../../../../hooks/useVerbox';
import { closeAdditionModal } from '../../../../redux/slices/additionalModalSlice';
import { Button } from '../../../atoms/Button/Button';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import Chat from './assets/Chat.svg?react';
import Telegram from './assets/Telegram.svg?react';
import { openTelegramBot } from './openTelegramBot';
import styles from './SupportButtons.module.scss';
import { SupportHeader } from './ui/SupportHeader';
import { useClickOutside } from '../../../../hooks/useClickOutside';

export const SupportButtons = () => {
  const modalRef = useRef();
  const dispatch = useDispatch();

  const { initVerbox } = useVerbox();

  const handleClose = () => {
    dispatch(closeAdditionModal());
  };

  const handleClick = e => {
    const button = e.currentTarget.dataset.name;

    if (button === 'Verbox') {
      dispatch(closeAdditionModal());
      return initVerbox();
    }

    if (button === 'TG') {
      dispatch(closeAdditionModal());
      openTelegramBot();
    }
  };

  useClickOutside(modalRef, handleClose);

  return (
    <ModalOverlay className={styles.overlay} zIndex={60000} closePortal={handleClose} data-exclude-outside-click>
      <div className={styles.container} ref={modalRef} data-exclude-outside-click>
        <SupportHeader title={'supportButtons'} closePortal={handleClose} />
        <div className={styles.content}>
          <div className={clsx(styles.supportButton, 'show-verbox')} onClick={handleClick} data-name='Verbox'>
            <span className={styles.icon}>
              <Chat />
            </span>
            <Button className={styles.textAlign} buttonText='Онлайн чат' />
          </div>
          <div className={styles.supportButton} onClick={handleClick} data-name='TG'>
            <span className={styles.icon}>
              <Telegram />
            </span>
            <Button className={styles.textAlign} buttonText='Telegram' />
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
};
