import { NotFoundPage } from '../../components/pages/404/NotFoundPage';
import { TechnicalWorks } from '../../components/pages/500/TechnicalWorks';
import { Callback } from '../../components/pages/Auth/Callback';
import { BonusesPage } from '../../components/pages/BonusesPage/BonusesPage';
import { FAQ } from '../../components/pages/FAQ/FAQ';
import GamePage from '../../components/pages/GamePage/GamePage';
import LoyaltyPage from '../../components/pages/LoyaltyPage/LoyaltyPage';
import { MainPage } from '../../components/pages/MainPage/MainPage';
import { NewsDetailPage } from '../../components/pages/NewsDetailPage/NewsDetailPage';
import NewsPage from '../../components/pages/NewsPage/NewsPage';
import { Rules } from '../../components/pages/Rules/Rules';

export const PUBLIC_ROUTES = {
  main: {
    path: '/',
    element: MainPage,
  },
  recent: {
    path: '/recent',
    element: MainPage,
  },
  category: {
    path: '/category',
    element: MainPage,
  },
  categoryId: {
    path: '/category/:id',
    element: MainPage,
  },
  providers: {
    path: '/providers',
    element: MainPage,
  },
  providersId: {
    path: '/providers/:id',
    element: MainPage,
  },
  new: {
    path: '/new',
    element: MainPage,
  },
  games: {
    path: '/category/games',
    element: MainPage,
  },
  game: {
    path: '/game/:id',
    element: GamePage,
  },
  bonuses: {
    path: '/bonuses-page',
    element: BonusesPage,
  },
  rules: {
    path: '/rules-page',
    element: Rules,
  },
  faq: {
    path: '/FAQ-page',
    element: FAQ,
  },
  news: {
    path: '/news',
    element: NewsPage,
  },
  newsDetail: {
    path: '/news/:id',
    element: NewsDetailPage,
  },
  technicalWorks: {
    path: '/technical-works',
    element: TechnicalWorks,
  },
  callback: {
    path: '/callback/*',
    element: Callback,
  },
  test500: {
    path: '/test-500',
    element: TechnicalWorks,
  },
  loyalty: {
    path: '/loyaltyPage',
    element: LoyaltyPage,
  },
  notFound: {
    path: '/404',
    element: NotFoundPage,
  },
};
