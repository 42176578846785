import BTC from '../components/molecules/RequisiteSelect/icons/BTC.svg?react';
import CardSvg from '../components/molecules/RequisiteSelect/icons/card.svg?react';
import ETH from '../components/molecules/RequisiteSelect/icons/ETH.svg?react';
import ETHUSDC from '../components/molecules/RequisiteSelect/icons/ETHUSDC.svg?react';
import LTC from '../components/molecules/RequisiteSelect/icons/LTC.svg?react';
import SbpSvg from '../components/molecules/RequisiteSelect/icons/sbp.svg?react';
import TON from '../components/molecules/RequisiteSelect/icons/TON.svg?react';
import TONNOT from '../components/molecules/RequisiteSelect/icons/TONNOT.svg?react';
import TONUSDT from '../components/molecules/RequisiteSelect/icons/TONUSDT.svg?react';
import TRXUSDT from '../components/molecules/RequisiteSelect/icons/TRXUSDT.svg?react';

export const getModalIconByMethod = method => {
  const modalTitleMap = {
    'MC/VISA UAH': CardSvg,
    'SBP P2P RUB': SbpSvg,
    'MC/VISA/MIR P2P RUB': CardSvg,
    BTC: BTC,
    ETH: ETH,
    LTC: LTC,
    TON: TON,
    'USDC (ETH)': ETHUSDC,
    'NOT (TON)': TONNOT,
    'USDT (TRC20)': TRXUSDT,
    'USDT (TON)': TONUSDT,
  };

  return modalTitleMap[method] || modalTitleMap['MC/VISA UAH'];
};
