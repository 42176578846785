import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import CatSvg from '../../../../assets/components/NavBar/Cat.svg?react';
import FavSvg from '../../../../assets/components/NavBar/Fav.svg?react';
import NovSvg from '../../../../assets/components/NavBar/Nov.svg?react';
import ProSvg from '../../../../assets/components/NavBar/Pro.svg?react';
import styles from './NavBarHome.module.scss';
import { NavButton } from './ui/NavButton';

const navItems = [
  { path: '/recent', Icon: FavSvg, translationKey: 'recent' },
  { path: '/category', Icon: CatSvg, translationKey: 'category' },
  { path: '/providers', Icon: ProSvg, translationKey: 'providers' },
  { path: '/new', Icon: NovSvg, translationKey: 'new' },
];

export const NavBarHome = memo(({ style }) => {
  const { t } = useTranslation('NavBarHome');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className={styles.navBarHome} style={style}>
      {navItems.map(({ path, Icon, translationKey }) => (
        <NavButton
          key={path}
          Icon={Icon}
          label={t(translationKey)}
          isActive={pathname.includes(path.slice(1))}
          onClick={() => navigate(path)}
        />
      ))}
    </div>
  );
});
