export const getMaskedRequisit = (number, method) => {
  if (method === 'SBP P2P RUB') {
    const reqExp = /(?:79|9)(\d{2})(\d{3})(\d{2})(\d{2})/gm;

    return number.replace(reqExp, (match, g1, g2, g3, g4) => `7 (9${g1}) ${g2} ${g3}-${g4}`);
  }

  if (method === 'MC/VISA/MIR P2P RUB') {
    return number.match(/.{1,4}/g).join(' ');
  }

  if (method === 'A2A RUB') {
    return [
      number.slice(0, 3),
      number.slice(3, 5),
      number.slice(5, 8),
      number.slice(8, 9),
      number.slice(9, 13),
      number.slice(13),
    ].join(' ');
  }

  return number;
};
