import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    user: [],
    accessToken: localStorage.getItem('accessToken'),
    crmUserData: null,
    error: null,
    loading: false,
  },
  reducers: {
    login: (state, action) => {
      localStorage.setItem('accessToken', action.payload.token);
      state.accessToken = action.payload.token;
      state.user = action.payload.user;
    },
    logout: state => {
      localStorage.removeItem('accessToken');
      state.accessToken = null;
      state.user = [];
      state.crmUserData = null;
    },
    setCrmUserData: (state, action) => {
      state.crmUserData = action.payload;
    },
  },
});

export const { login, logout, setCrmUserData } = userSlice.actions;
export default userSlice.reducer;
