import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from './api/useCrmCall';
import { Notifications } from './components/organisms/Notificatiions/Notifications';
import { AdditionalModal } from './components/pages/_modals/AdditionalModal/AdditionalModal';
import { CommonModal } from './components/pages/_modals/CommonModal/CommonModal';
import { Modal } from './components/pages/_modals/Modal/Modal';
import SearchModal from './components/pages/_modals/SearchModal/SearchModal';
import { setNotifications } from './redux/slices/notificationsSlice';
import { setCrmUserData } from './redux/slices/userSlice';
import Routes from './router';
import { excludeVerboxFromOutsideClick } from './utils/excludeVerboxFromOutsideClick';

export const App = () => {
  const { crmUserData, accessToken } = useSelector(store => store.userSlice);
  const dispatch = useDispatch();
  const location = useLocation();

  const { getCrmRequest, refreshToken } = useCrmCall();
  const urlParams = new URLSearchParams(window.location.search);

  // Запрос данных пользователя
  const { error: userDataError } = useQuery({
    queryKey: ['userData'],
    queryFn: async () => {
      const response = await getCrmRequest('/user/get_client_data');
      if (response && !Array.isArray(response)) {
        dispatch(setCrmUserData(response.client));
      }
      return response;
    },
    enabled: Boolean(accessToken),
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });

  // Запрос баланса
  const { error: balanceError, refetch } = useQuery({
    queryKey: ['userBalance'],
    queryFn: async () => {
      const balance = await getCrmRequest('/user/update_balance');
      if (crmUserData) {
        dispatch(
          setCrmUserData({
            ...crmUserData,
            wallets: balance,
          }),
        );
      }
      return balance;
    },
    enabled: Boolean(accessToken),
    refetchOnWindowFocus: false,
    retryDelay: 300,
    refetchInterval: 30000,
  });

  // Обработка ошибок
  useEffect(() => {
    const errors = [userDataError, balanceError];
    const errorMessage = errors
      .filter(Boolean)
      .map(error => error?.response?.data?.message)
      .filter(Boolean)[0];

    if (errorMessage) {
      dispatch(
        setNotifications({
          type: 'error',
          message: errorMessage,
        }),
      );
    }
  }, [userDataError, balanceError]);

  // Обновление баланса при смене страницы
  useEffect(() => {
    if (accessToken && refetch) {
      refetch();
    }
  }, [location.pathname, accessToken]);

  // Обработка реферального кода
  useEffect(() => {
    const click_id = urlParams.get('click_id');
    if (click_id && click_id !== 'null') {
      localStorage.setItem('ref_code', click_id);
    }
  }, []);

  useEffect(() => {
    refreshToken();
  }, []);

  // Отслеживание появления чат-виджета
  useEffect(() => {
    excludeVerboxFromOutsideClick();

    // Создаем MutationObserver для отслеживания изменений в DOM
    const observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          excludeVerboxFromOutsideClick();
        }
      }
    });

    // Начинаем наблюдение за всем документом
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Очистка при размонтировании компонента
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Routes />
      <SearchModal />
      <CommonModal />
      <AdditionalModal />
      <Modal />
      <Notifications />
    </>
  );
};
