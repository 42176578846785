import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import SearchIcon from '../../../assets/components/SearchBar/search.svg?react';
import { openSearchModal } from '../../../redux/slices/searchModalSlice';
import styles from './SearchBar.module.scss';

export const SearchBar = ({ style }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={clsx(styles.searchBar)}
        style={style}
        onClick={() => {
          dispatch(openSearchModal());
        }}
      >
        <SearchIcon />
        <div className={styles.searchInput} type='text' placeholder='Поиск'>
          <p>Поиск</p>
        </div>
      </div>
    </>
  );
};
