import { currentHostname, currentProtocol, isDev, THEME } from './constants';

const SSO_CONFIG = {
  CLIENT_ID: 'Web App Client',
  SCOPE: 'openid email',
  LOCALE: 'ru',
  RESPONSE_TYPE: 'code',
};

const SSO_BASE_URL = `${currentProtocol}//${isDev ? 'sso.test.vocxod.fun' : import.meta.env.VITE_KEYCLOAK_URL}/realms/vocxod/protocol/openid-connect`;

const REDIRECT_URI = isDev
  ? import.meta.env.VITE_KEYCLOAK_REDIRECT_URL
  : `${currentProtocol}//${currentHostname}/callback`;

function createUrl(path, query) {
  return `${SSO_BASE_URL}/${path}?${new URLSearchParams(query).toString()}`;
}

const getUrlParams = () => {
  const params = {
    client_id: SSO_CONFIG.CLIENT_ID,
    kc_locale: SSO_CONFIG.LOCALE,
    redirect_uri: REDIRECT_URI,
    response_type: SSO_CONFIG.RESPONSE_TYPE,
    scope: SSO_CONFIG.SCOPE,
    theme: THEME,
  };

  const currentRefCode = localStorage.getItem('ref_code');
  if (currentRefCode) {
    params.ref_code = currentRefCode;
  }
  return params;
};

export const getAuthUrl = () => createUrl('auth', getUrlParams());
export const getRegisterUrl = () => createUrl('registrations', getUrlParams());
