import { memo } from 'react';

import { useWindowSize } from '../../../hooks/useWindowSize';
import styles from './CloseButton.module.scss';
import XSvg from './x.svg?react';
import XDeskSvg from './x-desk.svg?react';

/**
 * Компонент кнопки закрытия с иконкой 'X'.
 *
 * @param {function} props.onClick - Функция, вызываемая при клике на кнопку.
 */
export const CloseButton = memo(({ onClick = () => {}, noScale }) => {
  const { isDesktop } = useWindowSize();
  return (
    <button
      className={styles.closeIcon}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
    >
      {isDesktop && !noScale ? <XDeskSvg style={{ scale: '1' }} /> : <XSvg />}
    </button>
  );
});
