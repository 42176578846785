import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useWindowSize } from '../../../hooks/useWindowSize';
import getSEOTitle from '../../../utils/getSEOTitle';
import { NavBarHome } from '../../organisms/NavBar/NavBarHome/NavBarHome';
import { News } from '../../organisms/News/News';
import { Recommended } from '../../organisms/Recommended/Recommended';
import { SearchBar } from '../../organisms/SearchBar/SearchBar';
import styles from './Home.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>
      Казино Восход {getSEOTitle(window.location.href)} | официальный сайт нового поколения для игры в слоты, рулетку,
      блэкджек и другие азартные игры онлайн
    </title>
    <meta
      name='description'
      content='VOCXOD предлагает уникальную бонусную механику, удобную платформу, безопасность и круглосуточную поддержку. Играй онлайн с удобным интерфейсом и мобильной версией!'
    />
  </Helmet>
);

export const Home = () => {
  const { isMobile, isTablet, isDesktop } = useWindowSize();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageMeta />
      {isDesktop && (
        <div className={styles.desktopSearchWrapper}>
          <NavBarHome />
          <SearchBar lockScroll />
        </div>
      )}
      {(isTablet || isMobile) && (
        <>
          <SearchBar lockScroll />
          <NavBarHome />
        </>
      )}
      <Recommended />
      {isMobile && <span className={styles.newsHeader}>Новости</span>}
      <News />
    </>
  );
};
