import { createSlice } from '@reduxjs/toolkit';

const searchModalSlice = createSlice({
  name: 'searchModal',
  initialState: {
    isOpen: false,
  },
  reducers: {
    openSearchModal: state => {
      state.isOpen = true;
    },
    closeSearchModal: state => {
      state.isOpen = false;
    },
  },
});

export default searchModalSlice.reducer;
export const { openSearchModal, closeSearchModal } = searchModalSlice.actions;
