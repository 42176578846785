import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../api/useCrmCall';
import { setNotifications } from '../redux/slices/notificationsSlice';
import { setIsMethodsLoading, setPaymentMethods } from '../redux/slices/paymentSlice';

export const usePaymentMethods = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const dispatch = useDispatch();

  const { getCrmRequest } = useCrmCall();

  const { error, isLoading } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: async () => {
      dispatch(setIsMethodsLoading(true));
      const paymentMethods = await getCrmRequest('/payment/get_allowed_payment_methods');
      dispatch(setIsMethodsLoading(false));
      dispatch(setPaymentMethods(paymentMethods));
      return paymentMethods;
    },
    refetchOnWindowFocus: false,
    retryDelay: 300,
    staleTime: Infinity,
    enabled: Boolean(accessToken),
  });

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);
};
