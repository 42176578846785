export const formatPaymentMethod = (network, currency) => {
  // Если сеть и валюта совпадают, возвращаем только сеть (BTC, ETH, TON, LTC)
  if (network === currency) {
    return network;
  }

  // Особый случай для TRX USDT
  if (network === 'TRX' && currency === 'USDT') {
    return 'USDT (TRC20)';
  }

  // Для остальных случаев формат: Валюта (Сеть)
  return `${currency} (${network})`;
};
