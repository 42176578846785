import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

const initialState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      const obj = action.payload;

      if (obj?.message) {
        // Проверяем наличие перевода и используем DEFAULT если перевод отсутствует
        const messageKey = i18next.exists(`Notifications:${obj.message}`) ? obj.message : 'DEFAULT';

        // Ищем существующее уведомление с таким же сообщением
        const index = state.notifications.findIndex(item => item.message === messageKey);

        if (index !== -1) {
          state.notifications[index] = {
            ...state.notifications[index],
            ...action.payload,
            message: messageKey,
            repeat: true,
          };
        } else {
          state.notifications.push({
            ...obj,
            message: messageKey,
            repeat: false,
          });
        }
      }
    },
    deleteNotification: (state, action) => {
      state.notifications = state.notifications.filter(item => item.message !== action.payload);
    },
  },
});

export default notificationsSlice.reducer;
export const { setNotifications, deleteNotification } = notificationsSlice.actions;
