import { useContext } from 'react';

import LogoBlackBig from '../../../assets/components/Footer/LogoBlackBig.svg?react';
import LogoWhiteBig from '../../../assets/components/Footer/LogoWhiteBig.svg?react';
import LogoBlack from '../../../assets/components/Header/LogoBlack.svg?react';
import LogoWhite from '../../../assets/components/Header/LogoWhite.svg?react';
import { ThemeContext } from '../../../theme/ThemeContext';

export const LogoComponent = ({ variant = 'default', className = '' }) => {
  const { finalTheme } = useContext(ThemeContext);

  const renderLogo = () => {
    if (finalTheme === 'dark') {
      return variant === 'big' ? <LogoWhiteBig className={className} /> : <LogoWhite className={className} />;
    }

    return variant === 'big' ? <LogoBlackBig className={className} /> : <LogoBlack className={className} />;
  };

  return renderLogo();
};
