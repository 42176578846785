import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import useDisableScroll from '../../../../../../hooks/useDisableScroll';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { setFilteredGames } from '../../../../../../redux/slices/gamesSlice';
import Skeleton from '../../../../../atoms/Skeleton/Skeleton';
import GamesModalWrapper from '../../GamesModalWrapper';
import { sortByIndex } from '../../utils/sortByIndex';
import { GameItem } from '../GameItem/GameItem';
import styles from './ModalContentFilteredGames.module.scss';

const getLoadingCount = (isMobile, isTablet) => {
  if (isMobile) return { count: 15 };
  if (isTablet) return { count: 24 };
  return { count: 40 };
};

export const ModalContentFilteredGames = () => {
  const { isMobile, isTablet } = useWindowSize();
  const itemsPerPage = getLoadingCount(isMobile, isTablet).count;

  const { filteredGames } = useSelector(store => store.gamesSlice);
  const [finalGames, setFinalGames] = useState(filteredGames?.slice(0, itemsPerPage));
  const [providerHeader, setProviderHeader] = useState('');
  const { ref, inView } = useInView({ threshold: 0.2, delay: 30 });

  const { postCrmRequest } = useCrmCall();
  const location = useLocation();
  const dispatch = useDispatch();

  useDisableScroll();

  const getGamesByGameGroup = useMutation({
    mutationFn: key => postCrmRequest(`/games/games_by_game_group`, { lang: 'ru', key }),
    onSuccess: data => {
      setProviderHeader(data?.game_group?.translates?.ru?.name);
      setFinalGames(sortByIndex(data?.games)?.slice(0, itemsPerPage));
      dispatch(setFilteredGames(sortByIndex(data?.games)));
    },
  });

  useEffect(() => {
    const key = location.pathname.split('/').at(-1);
    getGamesByGameGroup.mutate(key);
    return () => {
      dispatch(setFilteredGames([]));
    };
  }, []);

  useEffect(() => {
    if (inView) {
      setFinalGames(prev => {
        return [...prev, ...filteredGames.slice(prev.length, prev.length + itemsPerPage)];
      });
    }
  }, [inView]);

  if (!getGamesByGameGroup?.isPending && filteredGames?.length === 0) {
    return (
      <GamesModalWrapper>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ color: 'var(--third-text-color)' }}>Ничего не найдено</p>
        </div>
      </GamesModalWrapper>
    );
  }

  if (filteredGames?.length === 0) {
    return (
      <GamesModalWrapper>
        <div className={styles.gamesContainer}>
          {Array.from({ length: isMobile ? 12 : 16 }, (el, i) => (
            <Skeleton key={i} className={styles.skeleton} />
          ))}
        </div>
      </GamesModalWrapper>
    );
  }

  return (
    <GamesModalWrapper providerHeader={providerHeader}>
      <div className={styles.gamesContent}>
        {/*TODO: поправить стили когда будет делаться задача по выводу заголовка*/}
        {/*<div className={styles.titleContainer}>*/}
        {/*  <p className={styles.title}>{capitalizeFirstLetter(location?.pathname?.split('/').at(-1))}</p>*/}
        {/*</div>*/}
        <div data-block-interactions={true} className={styles.gamesContainer}>
          {finalGames?.length > 0 &&
            finalGames?.map((game, i) => {
              return <GameItem key={game?.id} game={game} ref={finalGames.length - 1 === i ? ref : null} />;
            })}
        </div>
      </div>
    </GamesModalWrapper>
  );
};
