import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import { NavBarHome } from '../../../organisms/NavBar/NavBarHome/NavBarHome';
import { SearchBar } from '../../../organisms/SearchBar/SearchBar';
import styles from './GamesModalWrapper.module.scss';
import { cleanString } from './utils/cleanString';

const GamesModalWrapper = memo(({ children, providerHeader }) => {
  const { pathname } = useLocation();

  const { isMobile } = useWindowSize();

  const title = useMemo(() => {
    // Получаем последний сегмент пути
    const lastPathSegment = pathname.split('/').at(-1);

    // Определяем тип страницы по пути
    if (pathname.includes('/providers/')) {
      return providerHeader;
    } else {
      return cleanString(lastPathSegment) || '';
    }
  }, [pathname, providerHeader]);

  return (
    <ModalOverlay className={styles.navBarModalOverlay}>
      <div className={styles.navBarModal}>
        <div className={styles.navBarModaWrapper}>
          <div className={styles.navBarHeadWrapper}>
            <NavBarHome style={{ marginBottom: '0px' }} />
            {!isMobile && <SearchBar />}
          </div>
          <div className={styles.modalContentWrapper}>
            <ModalHeader title={title} />
            <div data-block-interactions={true} className={styles.modalContent}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </ModalOverlay>
  );
});

export default GamesModalWrapper;
