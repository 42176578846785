import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { closeSearchModal } from '../../../../redux/slices/searchModalSlice';
import SearchModalContent from './ui/SearchModalContent/SearchModalContent';

const SearchModal = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isOpen } = useSelector(store => store.searchModalSlice);
  useEffect(() => {
    // if (!location.pathname.includes('/game/') && location.pathname !== '/') {
    dispatch(closeSearchModal());
    // }
  }, [location.pathname]);
  return ReactDOM.createPortal(<>{isOpen && <SearchModalContent />}</>, document.getElementById('modal-search'));
};

export default SearchModal;
