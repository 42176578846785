import { memo } from 'react';
import { useSelector } from 'react-redux';

import Skeleton from '../../atoms/Skeleton/Skeleton';
import { getMaxAmount } from '../../pages/_modals/Safe/getMaxAmount';
import styles from './TransferInput.module.scss';

export const TransferInputComponent = ({ balanceName }) => {
  const { crmUserData } = useSelector(store => store.userSlice);
  const balance = getMaxAmount(balanceName, crmUserData?.wallets);

  return (
    <>
      <div className={styles.transferField}>
        <p className={styles.balanceName}>{balanceName}</p>
        {balance !== undefined ? <p className={styles.amount}>{balance}</p> : <Skeleton className={styles.skeleton} />}
      </div>
    </>
  );
};

export const TransferInput = memo(TransferInputComponent);
