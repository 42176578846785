import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import mobBanner from '../../../assets/components/LoyaltyPage/mob.png';
import pcBanner from '../../../assets/components/LoyaltyPage/pc.png';
import tabletBanner from '../../../assets/components/LoyaltyPage/tab.png';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ThemeContext } from '../../../theme/ThemeContext';
import getSEOTitle from '../../../utils/getSEOTitle';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './LoyaltyPage.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>Казино Восход {getSEOTitle(window.location.href)} | Программа лояльности</title>
    <meta
      name='description'
      content='Присоединяйтесь к программе лояльности казино Восход и получайте награды за вашу активность. Узнайте, как накопить баллы и обменять их на ценные призы и бонусы.'
    />
  </Helmet>
);

const LoyaltyPage = () => {
  const { t } = useTranslation('Loyalty');
  const { isMobile } = useWindowSize();
  const { finalTheme } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageMeta />
      <Header />
      <section className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{finalTheme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {finalTheme === 'dark' ? (
              <ArrowLeftSvg height='100%' width='100%' />
            ) : (
              <ArrowLeftDarkSvg height='100%' width='100%' />
            )}
            <h1 className={styles.title}>{t('Loyalty')}</h1>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.bonusesPageWrapper}>
            <picture>
              <source srcSet={mobBanner} type='image/png' media='(max-width: 540px)' />
              <source srcSet={tabletBanner} type='image/png' media='(min-width: 541px) and (max-width: 1200px)' />
              <source srcSet={pcBanner} type='image/png' media='(min-width: 1201px)' />
              <img src={pcBanner} alt='vocxod-casino' />
            </picture>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>ЛОЯЛЬНОСТЬ ВОСХОДА – БОЛЬШЕ ПРИВИЛЕГИЙ ДЛЯ АКТИВНЫХ КЛИЕНТОВ</h2>
                <p className={styles.text}>
                  Чем больше играете, тем выше ваш статус и тем лучше условия. Лояльность в ВОСХОДЕ – это увеличенные
                  лимиты, безвейджерные бонусы и реальные денежные призы.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>КАК РАСТИ В ПРОГРАММЕ ЛОЯЛЬНОСТИ?</h2>
                <p className={styles.text}>Уровень зависит от оборота ставок в играх.</p>
                <ul>
                  <li>
                    • <strong>Зелёный</strong> – сразу после регистрации.
                  </li>
                  <li>
                    • <strong>Белый</strong> – от 50 000 ₽.
                  </li>
                  <li>
                    • <strong>Бронза</strong> – от 500 000 ₽.
                  </li>
                  <li>
                    • <strong>Серебро</strong> – от 1 500 000 ₽.
                  </li>
                  <li>
                    • <strong>Золото</strong> – от 3 000 000 ₽.
                  </li>
                  <li>
                    • <strong>Платина</strong> – от 5 000 000 ₽.
                  </li>
                </ul>
                <p className={styles.text}>
                  Отслеживать прогресс можно в <a href='/profile'>«Профиль»</a> →{' '}
                  <a href='/profile/loyalty'>«Лояльность»</a>.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>ЧТО ДАЁТ КАЖДЫЙ УРОВЕНЬ?</h2>
                <p className={styles.text}>
                  Статусы в программе лояльности открывают увеличенные лимиты на вывод, эксклюзивные бонусы и денежные
                  призы без ограничений.
                </p>
                <p className={styles.text}>
                  <strong>За достижение уровней начисляются награды:</strong>
                </p>
                <ul>
                  <li>• 1 000 ₽ за Бронзу</li>
                  <li>• 3 000 ₽ за Серебро</li>
                  <li>• 5 000 ₽ за Золото</li>
                  <li>• 10 000 ₽ за Платину</li>
                </ul>
                <p className={styles.text}>
                  Подробности о призах и условиях их получения читайте в <a href='/rules-page'>правилах</a>.
                </p>
                <p className={styles.text}>
                  Бонусы на выходных также зависят от статуса. Чем выше уровень, тем крупнее бонусы. Сумма пополнения
                  напрямую влияет на сумму начисления, а участники программы лояльности получают улучшенные условия для
                  бонусов 3+3, 5+5, 7+7, 10+10 и 15+15.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>ЕЖЕНЕДЕЛЬНЫЙ БУСТ</h2>
                <p className={styles.text}>
                  Каждую неделю активные клиенты получают дополнительные средства без цели и ограничений. Размер зависит
                  от вашей активности. Начисляется автоматически в понедельник и сразу доступен в{' '}
                  <a href='/safe'>Сейфе</a>.
                </p>
                <p className={styles.text}>
                  Подробнее: <a href='/news/29'>Как начисляется буст</a>.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <h2 className={styles.subtitle}>ЛОЯЛЬНОСТЬ БЕЗ ОГРАНИЧЕНИЙ</h2>
                <p className={styles.text}>
                  Лояльность в ВОСХОДЕ – это не просто система уровней, а путь к новым возможностям. Программа создана
                  для тех, кто ценит честные условия, реальные награды и максимальную свободу в игре. Достижение новых
                  статусов открывает перед вами улучшенные бонусы, персональные привилегии и эксклюзивные предложения.
                </p>
                <p className={styles.text}>
                  Будь то безвейджерные бонусы, увеличенные лимиты или еженедельный буст, вы всегда играете на лучших
                  условиях.
                </p>
              </div>
            </div>

            <div className={styles.bonusesBox}>
              <div className={styles.bonusesContainer}>
                <p className={styles.text}>
                  📌 Подробнее о <a href='/news/29'>еженедельном бусте</a>.
                </p>
                <p className={styles.text}>
                  📌 <a href='/news/52'>Приветственный бонус 5+5</a> для новых клиентов.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {isMobile && <NavBarBottom />}
    </>
  );
};

export default LoyaltyPage;
