import { createSlice } from '@reduxjs/toolkit';

const additionalModalSlice = createSlice({
  name: 'commonModal',
  initialState: {
    additionalModalType: null,
    isAdditionalModalOpen: false,
  },
  reducers: {
    openAdditionModal: (state, action) => {
      state.additionalModalType = action.payload.type;
      state.isAdditionalModalOpen = true;
    },
    closeAdditionModal: state => {
      state.isAdditionalModalOpen = false;
      state.additionalModalType = null;
    },
  },
});

export default additionalModalSlice.reducer;
export const { openAdditionModal, closeAdditionModal } = additionalModalSlice.actions;
