import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './PaymentCard.module.scss';
import { usePaymentIcon } from './utils/usePaymentIcon';

export const PaymentCard = ({ paymentMethod, onClick, className, minBadge = false }) => {
  const { t } = useTranslation('Withdrawal');

  return (
    <div className={paymentMethod?.paymentLimit && minBadge ? styles.wrapper : ''}>
      <div
        key={paymentMethod?.name}
        className={clsx(
          styles.methodCard,
          className ? className : '',
          paymentMethod?.paymentLimit && minBadge ? styles.resetBorder : '',
        )}
        onClick={() => onClick(paymentMethod?.name)}
      >
        <span className={styles.methodsIcon}>{usePaymentIcon(paymentMethod?.name)}</span>
        {t(`${paymentMethod?.name}.methodName`) || paymentMethod?.name}
      </div>
      {paymentMethod?.paymentLimit && minBadge ? (
        <p className={styles.badgeText}>от {paymentMethod?.paymentLimit} ₽</p>
      ) : (
        <></>
      )}
    </div>
  );
};
