import { useQuery } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../api/useCrmCall';
import ArrowSvg from '../../../assets/components/Profile/ArrowSvg.svg?react';
import { setNotifications } from '../../../redux/slices/notificationsSlice';
import { setBankName } from '../../../redux/slices/paymentSlice';
import styles from './BanksSelect.module.scss';

const BankSelectComponent = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const { bankName } = useSelector(store => store.paymentSlice);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();

  const { getCrmRequest } = useCrmCall();
  const { pathname } = useLocation();

  const { data: banksList, error } = useQuery({
    queryKey: ['banksList'],
    queryFn: () => getCrmRequest('/payment/get_banks'),
    refetchOnWindowFocus: false,
    retryDelay: 300,
    enabled: Boolean(accessToken),
  });

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  }, [error]);

  useEffect(() => {
    dispatch(setBankName(null));
  }, [pathname]);

  const activeBank = banksList?.find(item => item?.key_value === bankName);

  const mainBanks = banksList?.filter(item => item?.index_value !== 0)?.sort((a, b) => a?.index_value - b?.index_value);
  const allBanks = banksList?.sort((a, b) => a?.name?.localeCompare(b?.name));

  return (
    <div className={styles.bankSelect} onClick={toggleDropdown}>
      <label className={styles.label} htmlFor='bankInfo'>
        Название банка
      </label>
      <div id='bankInfo' className={clsx(styles.bankDropdown, styles.main, isExpanded ? styles.opened : '')}>
        {activeBank ? (
          <div className={styles.bankName}>{activeBank?.name}</div>
        ) : (
          <div className={styles.bankNameMain}>Выберите банк</div>
        )}
        <div className={clsx(styles.bankArrow, isExpanded ? styles.rotate : '')}>
          <ArrowSvg />
        </div>
      </div>
      {isExpanded && (
        <div className={styles.bankOptions}>
          {mainBanks?.map(bank => (
            <div
              key={bank?.key_value}
              onClick={() => dispatch(setBankName(bank?.key_value))}
              className={styles.bankDropdown}
            >
              <div className={styles.bankName}>{bank?.name}</div>
            </div>
          ))}
          <div className={styles.divider} />
          {allBanks?.map(bank => (
            <div
              key={bank?.key_value}
              onClick={() => dispatch(setBankName(bank?.key_value))}
              className={styles.bankDropdown}
            >
              <div className={styles.bankName}>{bank?.name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const BanksSelect = memo(BankSelectComponent);
