import DOMPurify from 'dompurify';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { STATIC_URL } from '../../../../constants/constants';
import { convertDateFormat } from '../../../../utils/convertDateFormat';
import { replaceImagesDomain } from '../../../../utils/replaceImagesDomain';
import styles from '../News.module.scss';

export const NewsItem = forwardRef(({ news, newsItem }, ref) => {
  const navigate = useNavigate();

  const handleNewsClick = () => {
    navigate(`/news/${newsItem.id}`);
  };

  return (
    <div ref={ref} className={styles.newsItem} onClick={handleNewsClick}>
      {newsItem?.main_image && (
        <img
          className={styles.newsImage}
          src={`${STATIC_URL}${newsItem.main_image.replace('__domain__', '')}`}
          alt={newsItem.local_en.alt_desc}
        />
      )}
      <div className={styles.newsPanel}>
        <div className={styles.panelInfo}>
          <div>
            <h5>{newsItem?.local_ru?.title}</h5>

            <div
              className={styles.smallText}
              dangerouslySetInnerHTML={{
                __html: replaceImagesDomain(DOMPurify.sanitize(newsItem?.local_ru.short_text)),
              }}
            />
          </div>
          <span className={styles.date}>{convertDateFormat(newsItem?.publishedAt)}</span>
        </div>
      </div>
    </div>
  );
});
