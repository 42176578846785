import { createSlice } from '@reduxjs/toolkit';

const commonModalSlice = createSlice({
  name: 'commonModal',
  initialState: {
    commonModalType: null,
    isCommonModalOpen: false,
  },
  reducers: {
    openCommonModal: (state, action) => {
      state.commonModalType = action.payload.type;
      state.isCommonModalOpen = true;
    },
    closeCommonModal: state => {
      state.isCommonModalOpen = false;
      state.commonModalType = null;
    },
  },
});

export default commonModalSlice.reducer;
export const { openCommonModal, closeCommonModal } = commonModalSlice.actions;
