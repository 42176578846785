import { useInfiniteQuery } from '@tanstack/react-query';

import { useCrmCall } from '../api/useCrmCall';

export const useInfiniteNews = (perPage = 4) => {
  const { getCrmRequest } = useCrmCall();
  return useInfiniteQuery({
    queryKey: ['news', perPage],
    queryFn: ({ pageParam = 1 }) => getCrmRequest('/news', { page: pageParam, perPage }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage === null || (Array.isArray(lastPage) && lastPage.length === 0)) {
        return undefined;
      }
      return pages.length + 1;
    },
    refetchOnWindowFocus: false,
    retryDelay: 300,
  });
};
