import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../../../api/useCrmCall';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import useDisableScroll from '../../../../../../hooks/useDisableScroll';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { setFilteredGames } from '../../../../../../redux/slices/gamesSlice';
import { setNotifications } from '../../../../../../redux/slices/notificationsSlice';
import Skeleton from '../../../../../atoms/Skeleton/Skeleton';
import GamesModalWrapper from '../../GamesModalWrapper';
import { sortByIndex } from '../../utils/sortByIndex';
import { GameItem } from '../GameItem/GameItem';
import styles from './ModalContentNovelties.module.scss';

export const ModalContentNovelties = () => {
  const { filteredGames } = useSelector(store => store.gamesSlice);

  const { isMobile } = useWindowSize();
  const { postCrmRequest } = useCrmCall();

  const dispatch = useDispatch();

  useDisableScroll();
  useDisableInteractionOnScroll();

  const getGamesByNovelties = useMutation({
    mutationFn: () => postCrmRequest(`/games/games_by_game_group`, { lang: 'ru', key: 'new' }),
    onSuccess: data => dispatch(setFilteredGames(sortByIndex(data?.games))),
  });

  useEffect(() => {
    getGamesByNovelties.mutate();

    return () => {
      dispatch(setFilteredGames([]));
    };
  }, []);

  if (filteredGames.length === 0) {
    return (
      <GamesModalWrapper>
        <div className={styles.gamesContainer}>
          {Array.from({ length: isMobile ? 9 : 16 }, (el, i) => (
            <Skeleton key={i} className={styles.skeleton} />
          ))}
        </div>
      </GamesModalWrapper>
    );
  }

  return (
    <GamesModalWrapper>
      <div data-block-interactions={true} className={styles.gamesContainer}>
        {filteredGames?.map(game => (
          <GameItem key={game?.id} game={game} />
        ))}
      </div>
    </GamesModalWrapper>
  );
};
