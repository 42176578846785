import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from '../../../hooks/useWindowSize';
import styles from './ModalOverlay.module.scss';

const ModalOverlay = ({ className, children, overlayClose = true, zIndex = 10000, closePortal }) => {
  const modalRef = useRef();
  const overlayRef = useRef();
  const navigate = useNavigate();

  const { isMobile } = useWindowSize();

  useEffect(() => {
    const handleClickOutside = e => {
      // Проверяем, что клик был именно по этому оверлею
      if (e.target !== overlayRef.current) {
        return; // Клик был не по этому оверлею, игнорируем
      }

      e.stopPropagation();

      if (closePortal) {
        return closePortal();
      }
      if (overlayClose) {
        navigate('/');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closePortal, overlayClose, overlayRef.current]);

  return (
    <div
      ref={overlayRef}
      style={{ zIndex }}
      className={clsx(styles.modalOverlay, className, isMobile ? styles.mobileOverlay : '')}
      onClick={e => {
        if (e.target === overlayRef.current) {
          e.stopPropagation();
        }
      }}
    >
      <div ref={modalRef}>{children}</div>
    </div>
  );
};

export default ModalOverlay;
