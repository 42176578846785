import { createSelector } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PRIVATE_ROUTES } from '../../../../constants/routes/private';
import { getAuthUrl } from '../../../../constants/ssoConstants';
import { closeAllModals, openModal } from '../../../../redux/slices/modalSlice';
import { getModalContent } from './routes';

const selectLastModal = createSelector(
  state => state.modalSlice.modals,
  modals => modals[modals.length - 1],
);

const renderModalContent = modal => {
  const Component = getModalContent(modal?.route)?.component;
  return Component ? <Component {...modal.contentProps} /> : 'Контент модалки';
};

export const Modal = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const modal = useSelector(selectLastModal);
  const modals = useSelector(state => state.modalSlice.modals);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const currentPath = pathname;
    const modalConfig = getModalContent(currentPath);
    const lastModalRoute = modals[modals.length - 1]?.route;

    if (Object.values(PRIVATE_ROUTES).some(item => pathname.includes(item.path.replace(/[/*]/g, ''))) && !accessToken) {
      window.location = getAuthUrl();
    } else if (modalConfig && lastModalRoute !== currentPath) {
      dispatch(
        openModal({
          route: currentPath,
          title: modalConfig.title,
          contentProps: {},
        }),
      );
    } else if (!modalConfig) {
      dispatch(closeAllModals());
    }
  }, [pathname]);

  return useMemo(() => {
    if (modals.length === 0) return null;

    return ReactDOM.createPortal(
      <div key={modal?.route} className='modal-backdrop'>
        <div className='modal-content'>{renderModalContent(modal)}</div>
      </div>,
      document.getElementById('modal-root'),
    );
  }, [modal, modals.length, renderModalContent]);
};
