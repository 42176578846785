import { useDispatch } from 'react-redux';

import LogoSupport from '../../../../assets/components/Header/Support.svg?react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { openAdditionModal } from '../../../../redux/slices/additionalModalSlice';
import styles from './Support.module.scss';

const Support = () => {
  const { isDesktop } = useWindowSize();
  const dispatch = useDispatch();

  const handleClick = () => dispatch(openAdditionModal({ type: 'support' }));

  return (
    <div className={styles.wrapper} onClick={handleClick} data-exclude-outside-click>
      <LogoSupport />
      {isDesktop && <p className={styles.text}>24/7</p>}
    </div>
  );
};

export default Support;
