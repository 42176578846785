import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftSvg from '../../../assets/components/ArrowLeft/arrowLeft.svg?react';
import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDark from '../../../assets/components/Header/TribeDark.svg?react';
import TribeLight from '../../../assets/components/Header/TribeLight.svg?react';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ThemeContext } from '../../../theme/ThemeContext';
import getSEOTitle from '../../../utils/getSEOTitle';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import styles from './Rules.module.scss';

export const Rules = () => {
  const { t } = useTranslation('Rules');
  const { isMobile } = useWindowSize();
  const { finalTheme } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const PageMeta = () => (
    <Helmet>
      <meta charSet='utf-8' />
      <title>Казино Восход {getSEOTitle(window.location.href)} | Правила и соглашения казино Восход</title>
      <meta
        name='description'
        content='Ознакомьтесь с правилами и условиями использования казино Восход. Убедитесь, что вы играете в соответствии с нашими правилами, чтобы обеспечить безопасную и честную игру.'
      />
    </Helmet>
  );

  return (
    <>
      <PageMeta />
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && <div className={styles.tribe}>{finalTheme === 'dark' ? <TribeDark /> : <TribeLight />}</div>}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {finalTheme === 'dark' ? (
              <ArrowLeftSvg height='100%' width='100%' />
            ) : (
              <ArrowLeftDarkSvg height='100%' width='100%' />
            )}
            <h1 className={styles.title}>{t('Rules')}</h1>
          </div>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentItem}>
            <h2>1. Введение</h2>
            <p>
              Настоящие Правила и Условия (далее – "Условия") регулируют использование веб-сайта vocxod.com (далее –
              "Веб-сайт") и всех связанных с ним сервисов (далее – "Сервис").
            </p>
            <p>
              Перед использованием Веб-сайта внимательно ознакомьтесь с настоящими Условиями, так как они содержат
              важную информацию о ваших правах и обязанностях. Данные Условия представляют собой юридически обязательное
              соглашение между вами (далее – "Клиент") и Summit Dawn N.V. (далее – "Компания").
            </p>
            <p>
              Используя Веб-сайт и/или получая доступ к Сервису, вы соглашаетесь с настоящими Условиями, а также с
              возможными изменениями, которые могут вноситься Компанией. Если вы не принимаете Условия, воздержитесь от
              использования Веб-сайта и Сервиса.
            </p>
            <p>
              Сервис управляется Summit Dawn N.V., зарегистрированной в Кюрасао под регистрационным номером 120225, с
              юридическим адресом Santa Rosaweg 41, Curacao. Деятельность Компании осуществляется на основании лицензии
              No.1225/JAZ.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>2. Общие условия</h2>
            <p>
              Мы оставляем за собой право вносить изменения и дополнения в настоящие Условия, включая все связанные
              документы, в любое время. Актуальная версия Условий всегда доступна на Веб-сайте, и мы рекомендуем
              регулярно проверять эту страницу, чтобы быть в курсе возможных обновлений.
            </p>
            <p>
              Все изменения вступают в силу немедленно после публикации на Веб-сайте и становятся обязательными для всех
              пользователей. Если вы не согласны с обновленными Условиями, вы должны прекратить использование Сервиса.
              Ваше дальнейшее использование Веб-сайта после публикации обновлений означает ваше полное согласие с
              изменениями.
            </p>
            <p>
              Ставки, сделанные до вступления в силу новых Условий, регулируются редакцией, действующей на момент их
              размещения.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>3. Требования к пользователям</h2>
            <p>Используя Веб-сайт и Сервис, вы подтверждаете и гарантируете, что:</p>
            <p>
              3.1. Вам исполнилось 18 лет или вы достигли иного минимального возраста, установленного законодательством
              вашей юрисдикции для участия в азартных играх. Мы имеем право запросить документы для подтверждения вашего
              возраста в любое время.
            </p>
            <p>
              3.2. Вы обладаете дееспособностью и имеете право заключать юридически обязательные соглашения. Если вы не
              дееспособны, доступ к Веб-сайту и использование Сервиса для вас запрещены.
            </p>
            <p>
              3.3. Вы являетесь резидентом страны, где азартные игры разрешены. Вы не находитесь в юрисдикции, где
              онлайн-гемблинг запрещен. Вы несете единоличную ответственность за соблюдение законов вашей страны.
            </p>
            <p>3.4. Вы используете только разрешенные способы оплаты и являетесь их авторизованным владельцем.</p>
            <p>
              3.5. Все платежи в пользу Компании осуществляются добросовестно. Вы не будете оспаривать или отменять
              переводы без законных оснований.
            </p>
            <p>
              3.6. Вы осознаете, что при размещении ставок можете потерять часть или все средства, внесенные на ваш
              игровой счет, и берете на себя полную ответственность за возможные убытки.
            </p>
            <p>
              3.7. Вы не используете незаконно полученную информацию при размещении ставок и не нарушаете
              законодательство вашей юрисдикции.
            </p>
            <p>
              3.8. Вы действуете от собственного имени, не представляя интересы третьих лиц и не используете Сервис в
              коммерческих целях.
            </p>
            <p>
              3.9. Вы не предпринимаете недобросовестных действий, направленных на манипуляцию системой, нарушающих
              целостность Сервиса или наносящих вред Компании.
            </p>
            <p>3.10. Вы всегда ведете себя честно и добросовестно при использовании Сервиса и размещении ставок.</p>
            <p>
              3.11. Вы, а также, при наличии, ваши сотрудники, работодатели, агенты или члены семьи не зарегистрированы
              в партнерской программе Компании.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>4. Ограничения по использованию</h2>
            <p>4.1. Вам запрещено использовать Сервис в следующих случаях:</p>
            <p>
              4.1.1. Если вам не исполнилось 18 лет (или иного возраста совершеннолетия, установленного
              законодательством вашей юрисдикции), либо если вы действуете от имени несовершеннолетнего лица.
            </p>
            <p>
              4.1.2. Если вы проживаете в стране, где азартные онлайн-игры запрещены законодательством, либо доступ к
              ним ограничен для жителей данной юрисдикции.
            </p>
            <p>4.1.3. Если вы являетесь резидентом или заходите на Веб-сайт из одной из следующих стран:</p>
            <ul>
              <li>Соединенные Штаты Америки и их территории</li>
              <li>Иран</li>
              <li>Северная Корея</li>
              <li>Мьянма</li>
              <li>Грузия</li>
              <li>Украина</li>
            </ul>
            <p>
              4.1.4. Для сбора никнеймов, адресов электронной почты и/или другой информации других Клиентов любыми
              способами (например, путем рассылки спама, несанкционированной рекламы или создания нелегальных ссылок на
              Сервис).
            </p>
            <p>
              4.1.5. Если ваши действия нарушают или оказывают негативное влияние на других Клиентов либо на работу
              Сервиса в целом.
            </p>
            <p>
              4.1.6. Для продвижения коммерческой рекламы, партнерских ссылок и иных видов привлечения клиентов без
              согласования с Компанией. Любые такие материалы могут быть удалены без предварительного уведомления.
            </p>
            <p>4.1.7. Если вы предпринимаете попытки:</p>
            <ul>
              <li>(i) Обмануть Сервис или других Клиентов;</li>
              <li>(ii) Вступить в сговор с другими Клиентами с целью получения нечестного преимущества.</li>
            </ul>
            <p>4.1.8. Если ваши действия направлены на нарушение прав Компании на интеллектуальную собственность.</p>
            <p>4.1.9. Для любой другой противоправной деятельности.</p>
            <p>
              4.2. Вам запрещено продавать, передавать свою учетную запись третьим лицам, а также приобретать учетные
              записи у других Клиентов.
            </p>
            <p>
              4.3. Переводы средств между учетными записями Клиентов запрещены до введения соответствующей
              функциональности в рамках агентской системы. В случае изменений информация будет опубликована на
              Веб-сайте.
            </p>
            <p>
              4.4. В случае использования Сервиса в неправомерных целях мы можем незамедлительно закрыть вашу учетную
              запись с уведомлением. В определенных случаях Компания оставляет за собой право обратиться в суд.
            </p>
            <p>
              4.5. Сотрудникам Компании, её партнёрам, агентам, подрядчикам, рекламным и промоутерским агентствам, а
              также членам их семей запрещено использовать Сервис на реальные деньги без предварительного одобрения
              Директора Компании или Генерального Директора. В случае нарушения учётная запись будет немедленно
              заблокирована, а все бонусы и выигрыши аннулированы. Компания оставляет за собой право применять данное
              правило индивидуально в каждом конкретном случае.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>5. Регистрация</h2>
            <p>
              5.1. Мы оставляем за собой право отказать в регистрации без объяснения причин и без каких-либо
              обязательств перед заявителем.
            </p>
            <p>
              5.2. Перед использованием Сервиса вам необходимо лично заполнить регистрационную форму, ознакомиться и
              согласиться с настоящими Условиями. Для возможности размещения ставок и вывода выигрышей может
              потребоваться верификация, включающая проверку личности. Мы можем запросить:
            </p>
            <ul>
              <li>Документ, удостоверяющий личность (паспорт или национальное удостоверение личности).</li>
              <li>Подтверждение места жительства (счет за коммунальные услуги, банковская выписка и пр.).</li>
              <li>Другие документы, необходимые в рамках политики Компании.</li>
              <li>
                Фотография вашего лица с раскрытым паспортом в руках, а также иные фотографии, в целях установления
                вашей личности.
              </li>
            </ul>
            <p>
              До предоставления запрошенных данных мы имеем право ограничить или приостановить доступ к Сервису. Данные
              меры соответствуют требованиям законодательства о борьбе с отмыванием денег и ответственному ведению
              игорного бизнеса.
            </p>
            <p>
              5.3. Вы обязуетесь указывать актуальную контактную информацию, включая номер телефона с возможностью
              получения SMS и своевременно обновлять его при изменении. В случае предоставления некорректных данных:
            </p>
            <ul>
              <li>Вы не сможете завершить регистрацию, если не добавите корректный номер телефона.</li>
              <li>Вы можете не получать важные уведомления о работе учетной записи.</li>
              <li>Компания не несет ответственности за возможные убытки из-за отсутствия актуальной информации.</li>
              <li>
                Если будет установлено намеренное указание ложных данных, ваша учетная запись может быть немедленно
                приостановлена.
              </li>
            </ul>
            <p>
              5.4. Запрещено регистрировать более одной учетной записи. В случае выявления нескольких аккаунтов (включая
              создание через родственников, партнеров или третьих лиц), все такие учетные записи подлежат немедленному
              закрытию.
            </p>
            <p>
              5.5. Для подтверждения владения платежными средствами и идентификации личности мы можем запросить
              дополнительные данные или воспользоваться проверками через сторонних поставщиков. Если такая информация
              будет получена из внешних источников, мы уведомим вас.
            </p>
            <p>
              5.6. Вы обязаны хранить пароль в тайне и не передавать его третьим лицам. Ваша учетная запись защищена
              введенными вами учетными данными, и любые транзакции, выполненные с корректным паролем, считаются
              совершенными вами.
            </p>
            <p>Рекомендуем регулярно менять пароль.</p>
            <p>При подозрении на взлом (утечку данных, доступ третьих лиц) вы должны немедленно уведомить нас.</p>
            <p>До момента уведомления вы несете ответственность за все действия в учетной записи.</p>
            <p>
              5.7. Запрещено передавать конфиденциальное содержимое Сервиса третьим лицам, в том числе через скриншоты
              или иные методы, отличные от стандартного просмотра сайта.
            </p>
            <p>
              5.8. Валютой счета на проекте является только российский рубль (RUB). Все депозиты, ставки и вывод средств
              производятся исключительно в рублях. Если пополнение или вывод осуществляется в другой валюте, включая
              криптовалюты, средства будут автоматически конвертированы в рубли по курсу соответствующей платежной
              системы.
            </p>
            <p>
              5.9. Регистрация учетной записи – это приглашение к участию, а не гарантия ее открытия. Мы вправе отказать
              в регистрации без объяснения причин.
            </p>
            <p>5.10. После подачи заявки мы можем связаться с вами для выполнения требований юридического характера.</p>
          </div>

          <div className={styles.contentItem}>
            <h2>6. Учетная запись</h2>
            <p>
              6.1. Все остатки на счетах и транзакции отображаются в рублях, независимо от способа пополнения или
              вывода.
            </p>
            <p>6.2. Мы не предоставляем кредит для использования Сервиса.</p>
            <p>6.3. Мы вправе приостановить или закрыть вашу учетную запись, если:</p>
            <ul>
              <li>Вы не соблюдаете настоящие Условия, или у нас есть основания полагать, что вы их нарушаете.</li>
              <li>Это необходимо для обеспечения целостности или безопасности Сервиса.</li>
              <li>У нас есть иные обоснованные причины для таких действий.</li>
            </ul>
            <p>
              В отдельных случаях закрытие учетной записи может произойти без предварительного уведомления. В этом
              случае мы оставляем за собой право аннулировать любые ставки и удержать или списать частично или в полном
              объеме денежные средства на вашем счете (включая депозит).
            </p>
            <p>
              6.4. Мы также оставляем за собой право закрыть или приостановить учетную запись без предварительного
              уведомления, при этом все средства на счете могут быть возвращены Клиенту, за исключением случаев
              нарушения настоящих Условий. Все уже истекшие контрактные обязательства перед Клиентом будут выполнены.
            </p>
            <p>
              6.5. Мы вправе отказать, ограничить, аннулировать или лимитировать любую ставку в любое время по любой
              причине, включая попытки:
            </p>
            <ul>
              <li>Обойти лимиты ставок и/или правила системы.</li>
              <li>Разместить ставку мошенническим способом.</li>
            </ul>
            <p>
              6.6. Если на ваш счет ошибочно зачислены денежные средства, они остаются собственностью Компании. При
              выявлении ошибки мы уведомим вас, а сумма будет списана с вашего счета.
            </p>
            <p>6.7. Вы обязаны немедленно сообщить нам о любых ошибках, связанных с вашей учетной записью.</p>
            <p>
              6.8. Для закрытия учетной записи отправьте запрос на электронную почту support@vocxod.com, указав причину
              блокировки. Обращения обрабатываются в течение до 30 дней с момента получения.
            </p>
            <p>
              6.9. Передача учетной записи запрещена. Вы не можете продавать, передавать, закладывать или передавать в
              залог свою учетную запись третьим лицам. Этот запрет распространяется на:
            </p>
            <ul>
              <li>Права собственности на учетную запись.</li>
              <li>Выигрыши, депозиты, ставки и любые связанные активы.</li>
              <li>Юридические и коммерческие претензии.</li>
            </ul>
            <p>
              Любые попытки передачи, обмена, переуступки или обременения учетной записи третьим лицам недействительны.
            </p>
            <p>
              6.10. Ограничение на создание учетных записей: Каждый Клиент может зарегистрировать только один аккаунт на
              Веб-сайте. Также запрещено создание нескольких учетных записей с одного физического адреса, устройства или
              IP-адреса без согласования с Сервисом.
            </p>
            <ul>
              <li>Обнаружение двух и более учетных записей у одного Клиента приведет к блокировке всех аккаунтов.</li>
              <li>Средства со счетов нарушителя могут быть списаны в пользу Веб-сайта.</li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>7. Неактивные учетные записи</h2>
            <p>
              7.1. Если ваша учётная запись остаётся неактивной в течение 10 месяцев, вы получите уведомление о
              возможном списании комиссии, начиная с 11-го месяца бездействия. У вас всегда будет возможность войти в
              систему и вывести свои средства до начала начисления комиссии. Размер комиссии устанавливается Компанией.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>8. Внесение средств</h2>
            <p>
              8.1. Все депозиты должны производиться только с платежных средств, зарегистрированных на ваше имя.
              Злонамеренное и/или противоправное использование чужих платежных средств может повлечь за собой меры в
              виде аннулирования баланса и блокировки аккаунта.
            </p>
            <ul>
              <li>Все депозиты принимаются в российских рублях (RUB).</li>
              <li>
                При пополнении счета в другой валюте, включая криптовалюты, средства будут автоматически конвертированы
                в рубли по текущему курсу платежной системы.
              </li>
              <li>
                Некоторые платежные системы могут взимать дополнительную комиссию за конвертацию, которая будет удержана
                из суммы депозита.
              </li>
            </ul>

            <p>
              8.2. Компания не является финансовым учреждением и использует сторонние платежные системы для обработки
              депозитов.
            </p>
            <ul>
              <li>
                Депозиты по кредитным и дебетовым картам зачисляются только после подтверждения платежа эмитентом карты.
              </li>
              <li>Если банк-эмитент отклонит платеж, средства не будут зачислены на ваш игровой счет.</li>
              <li>
                Компания не несет ответственности за платежи, не подтвержденные банком-эмитентом или платежными
                системами.
              </li>
            </ul>

            <p>8.3. Вы соглашаетесь:</p>
            <ul>
              <li>Полностью оплачивать все комиссии и сборы, связанные с пополнением счета.</li>
              <li>Не осуществлять возвратные платежи, отмены и аннулирования внесенных депозитов.</li>
              <li>
                В случае отмены или возврата депозита, все выигрыши, полученные с этих средств, будут аннулированы.
              </li>
              <li>
                Понимать, что ваш игровой счет не является банковским счетом, не защищен гарантиями, страховками или
                другими мерами финансовой безопасности. На средства на счете не начисляются проценты.
              </li>
            </ul>

            <p>
              8.4. Если вы вводите бонусный код при пополнении счета, вы автоматически соглашаетесь с условиями
              предоставления бонусов.
            </p>
            <p>8.5. Запрещено вносить средства, полученные незаконным или мошенническим путем.</p>
            <p>
              8.6. Если вы пополняете счет с кредитной карты, рекомендуется сохранять копии транзакций и ознакомиться с
              актуальными Условиями.
            </p>
            <p>
              8.7. Вы несете единоличную ответственность за соблюдение законов вашей страны в отношении азартных игр.
            </p>
            <ul>
              <li>
                Если онлайн-гемблинг запрещен в вашей юрисдикции, использование платежных карт для депозитов запрещено.
              </li>
              <li>Ознакомьтесь с законодательством вашей страны перед внесением средств.</li>
            </ul>

            <p>
              8.8. Минимальная сумма депозита зависит от выбранной платёжной системы и может отличаться. Актуальная
              минимальная сумма отображается в кассе при выборе соответствующего платёжного метода.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>9. Вывод средств</h2>
            <p>
              9.1. Вывод средств доступен только с Реального баланса. Средства, находящиеся в разделе «Сейф», необходимо
              предварительно перевести на Реальный баланс в разделе Профиль.
            </p>
            <p>
              Вы можете подать запрос на вывод только отыгранных и неиспользованных средств, находящихся на Реальном
              балансе.
            </p>
            <ul>
              <li>Минимальная сумма вывода за одну транзакцию — 3 000 рублей.</li>
              <li>Максимальная сумма вывода за одну транзакцию — 100 000 рублей.</li>
            </ul>

            <p>
              9.2. Для обеспечения безопасности и соблюдения законодательства, мы оставляем за собой право запросить:
            </p>
            <ul>
              <li>Документ, удостоверяющий личность с фотографией;</li>
              <li>Подтверждение адреса проживания;</li>
              <li>Селфи с документом, видеоподтверждение и иные формы верификации.</li>
            </ul>
            <p>
              Верификация может быть проведена в любое время, включая момент подачи запроса на вывод. В случае отказа
              предоставить документы или обнаружения ложной информации, мы вправе:
            </p>
            <ul>
              <li>Блокировать учетную запись;</li>
              <li>Аннулировать все выигрыши.</li>
            </ul>

            <p>9.3. Все выводы осуществляются на тот же способ оплаты, с которого ранее был сделан депозит.</p>
            <p>
              В отдельных случаях и по усмотрению администрации допускается вывод на другой метод, при условии
              дополнительной проверки.
            </p>

            <p>
              9.4. Если ваш аккаунт временно заблокирован, закрыт или недоступен, но вы хотите вывести средства,
              обратитесь в службу поддержки на сайте или по электронному адресу: support@vocxod.com.
            </p>

            <p>
              9.5. На платформе действуют лимиты на вывод средств, зависящие от уровня лояльности и суммы пополнений:
            </p>
            <ul>
              <li>Зеленый и Белый — до 50 000₽ в день</li>
              <li>Бронза — до 100 000₽ в день</li>
              <li>Серебро — до 150 000₽ в день</li>
              <li>Золото — до 200 000₽ в день</li>
              <li>Платина — до 400 000₽ в день</li>
            </ul>

            <p>
              9.6. Мы не гарантируем успешную обработку вывода средств в случаях нарушения правил, включая пункты,
              касающиеся запрещенных юрисдикций (см. 3.3) и ограничений использования (см. 4).
            </p>

            <p>
              9.7. Запрос на вывод средств возможен только после того, как сумма внесённого депозита будет полностью
              прокручена (×1) в играх.
            </p>
            <p>В противном случае Веб-сайт вправе:</p>
            <ul>
              <li>приостановить обработку вывода;</li>
              <li>удержать комиссию, соответствующую затратам на проведение транзакции;</li>
              <li>отклонить запрос до выполнения обязательного оборота.</li>
            </ul>

            <p>
              9.8. Принимая настоящие Условия, Клиент соглашается с установленным Компанией регламентом обработки вывода
              средств. Срок рассмотрения и обработки заявки на вывод занимает от 15 минут до 5 рабочих дней. Компания не
              несёт ответственности за возможные задержки на стороне банков или платежных систем после успешной
              обработки Компанией заявки.
            </p>

            <p>
              9.9. Компания не является финансовым учреждением и для проведения финансовых операций, включая пополнение
              счета и вывод средств, пользуется услугами сторонних платёжных систем и сервисов.
            </p>

            <p>
              9.10. Компания оставляет за собой право пересматривать и изменять установленные лимиты на вывод средств,
              как в сторону увеличения, так и снижения, исходя из индивидуальной оценки игровой активности и профиля
              каждого Клиента.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>10. Платежные транзакции и платежные системы</h2>
            <p>
              10.1. Вы несёте полную ответственность за своевременную и добросовестную оплату всех денежных обязательств
              перед Компанией.
            </p>
            <ul>
              <li>Запрещены попытки отмены или возврата ранее совершённых платежей с целью уклонения от оплаты.</li>
              <li>
                В случае подобных действий мы оставляем за собой право аннулировать выигрыши и ограничить доступ к
                Сервису.
              </li>
            </ul>

            <p>10.2. Компания использует сторонние платёжные системы и банки для обработки транзакций.</p>
            <ul>
              <li>
                Вы соглашаетесь с тем, что при использовании этих сервисов вы обязаны соблюдать их правила и условия,
                если они были доведены до вашего сведения и не противоречат данным Условиям.
              </li>
            </ul>

            <p>
              10.3. Все транзакции, проводимые на Веб-сайте, могут быть проверены на предмет соответствия
              законодательству, в том числе:
            </p>
            <ul>
              <li>Борьба с отмыванием денег (AML);</li>
              <li>Предотвращение финансирования терроризма (CFT).</li>
            </ul>
            <p>О любых подозрительных операциях будет направлена информация в соответствующие надзорные органы.</p>
          </div>

          <div className={styles.contentItem}>
            <h2>11. Ошибки</h2>
            <p>
              11.1. В случае ошибки или технического сбоя в работе системы, программного обеспечения или внутренних
              процессов все ставки совершенные после сбоя подлежат аннулированию.
            </p>
            <ul>
              <li>Вы обязаны незамедлительно уведомить нас, если обнаружили ошибку или сбой в работе Сервиса.</li>
              <li>
                Мы не несем ответственности за прямые или косвенные убытки, возникшие в результате технических сбоев,
                ошибок или багов.
              </li>
              <li>
                Веб-сайт оставляет за собой право аннулировать соответствующие игры или ставки и принять иные меры для
                устранения последствий ошибки.
              </li>
            </ul>

            <p>
              11.2. Мы прилагаем все усилия для исключения ошибок при установке деноминации ставок, параметров бонусов,
              условий акций и других игровых настроек. Однако в случае, если из-за человеческого фактора или
              технического сбоя были установлены значения, которые:
            </p>
            <ul>
              <li>Существенно отличаются от стандартных или рыночных условий;</li>
              <li>Очевидно являются ошибочными с учетом текущих игровых параметров,</li>
            </ul>
            <p>
              — Веб-сайт оставляет за собой право аннулировать такие ставки, отменить участие в бонусе или акции, а
              также отменить все игровые действия, совершённые после обнаружения ошибки, включая те, что произошли в
              результате неправильно настроенных условий.
            </p>

            <p>11.3. В случае переплаты или другой ошибки на вашем счете, мы оставляем за собой право:</p>
            <ul>
              <li>Взыскать переплаченную сумму;</li>
              <li>Скорректировать баланс вашей учетной записи;</li>
              <li>При недостатке средств на счете — потребовать компенсацию долга.</li>
            </ul>
            <p>
              Мы также оставляем за собой право отменить, сократить или удалить незавершённые розыгрыши, независимо от
              источника задействованных средств.
            </p>

            <p>11.4. Веб-сайт не несёт ответственности за ошибки, связанные с:</p>
            <ul>
              <li>Неисправностями устройства, с которого осуществляется вход;</li>
              <li>Неполадками периферийного оборудования;</li>
              <li>Проблемами у интернет-провайдеров;</li>
              <li>Нарушениями в работе игровых серверов провайдеров.</li>
            </ul>

            <p>
              11.5. Если Клиент обнаружил ошибку или уязвимость на Веб-сайте, он обязан немедленно сообщить об этом в
              службу поддержки.
            </p>
            <ul>
              <li>Запрещено использовать уязвимости для личной выгоды.</li>
              <li>
                При нарушении этого правила Веб-сайт имеет право компенсировать убытки за счёт средств на счете Клиента.
              </li>
            </ul>
            <p>11.6. При наличии подозрений в использовании уязвимостей Веб-сайт вправе:</p>
            <ul>
              <li>Приостановить вывод средств до выяснения всех обстоятельств.</li>
              <li>Потребовать дополнительные документы или объяснения.</li>
            </ul>

            <p>11.7. Нарушение или даже обоснованное подозрение в нарушении настоящих Условий дает нам право:</p>
            <ul>
              <li>Приостановить или закрыть учетную запись Клиента;</li>
              <li>Заморозить или отменить вывод выигрышей;</li>
              <li>Списать частично или полностью средства со счета Клиента.</li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>12. Правила игры, возвраты и аннулирование</h2>
            <p>
              12.1. Результаты игр и розыгрышей считаются окончательными в момент их завершения. Мы не пересматриваем
              итоги, даже если исход был опротестован или отменён сторонними источниками, за исключением предусмотренных
              настоящими Условиями случаев.
            </p>
            <p>
              12.2. После завершения игры или розыгрыша все результаты считаются окончательными через 72 часа с момента
              их публикации.
            </p>
            <ul>
              <li>
                В течение этого времени результат может быть исправлен только в случае ошибки: технической, человеческой
                или ошибки со стороны провайдера игры.
              </li>
              <li>По истечении 72 часов запросы на пересмотр не принимаются.</li>
            </ul>
            <p>
              12.3. Минимальные и максимальные суммы ставок в играх, акциях и бонусных предложениях устанавливаются
              Веб-сайтом и могут изменяться без предварительного уведомления.
            </p>
            <ul>
              <li>
                Также мы оставляем за собой право устанавливать индивидуальные лимиты ставок для конкретных аккаунтов.
              </li>
            </ul>
            <p>12.4. Клиенты несут полную ответственность за все транзакции в своих учетных записях.</p>
            <ul>
              <li>После того как ставка была размещена и подтверждена, она не может быть изменена или отменена.</li>
              <li>
                Веб-сайт не несет ответственности за ошибочные, случайные или дублирующие ставки, а также за упущенные
                действия клиента.
              </li>
              <li>
                Вся история активности доступна в разделе «Мой аккаунт», где вы можете самостоятельно проверять
                размещенные ставки и операции после каждой игровой сессии.
              </li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>13. Сообщения и уведомления</h2>
            <p>
              13.1. Все обращения к нам, предусмотренные настоящими Условиями, должны направляться через форму обратной
              связи на Веб-сайте или по официальным каналам связи в мессенджерах, информация о которых доступна на
              сайте.
            </p>
            <p>13.2. Все уведомления, которые мы направляем вам, могут быть:</p>
            <ul>
              <li>Размещены на Веб-сайте;</li>
              <li>Отправлены на регистрационный номер телефона, указанный в вашей учетной записи.</li>
            </ul>
            <p>Способ направления уведомлений выбирается по усмотрению Компании.</p>
            <p>
              13.3. Все официальные сообщения между вами и Компанией должны быть составлены в письменной форме на
              русском языке и направлены с регистрационного номера телефона, указанного в вашей учетной записи.
            </p>
            <p>
              13.4. Мы можем направлять вам информационные и рекламные сообщения (например, об акциях, обновлениях,
              специальных предложениях) по номеру телефона, указанному вами при регистрации, в том числе через SMS и
              мессенджеры.
            </p>
            <ul>
              <li>
                Согласие на получение таких сообщений считается предоставленным при регистрации и принятии настоящих
                Условий.
              </li>
              <li>
                Вы можете отказаться от получения рекламных уведомлений в любой момент, направив соответствующий запрос
                в службу поддержки.
              </li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>14. Обстоятельства вне нашего контроля</h2>
            <p>
              Мы не несем ответственности за сбои или задержки в работе Сервиса, вызванные форс-мажорными
              обстоятельствами, не зависящие от воли сторон, несмотря на принятие всех разумных превентивных мер.
            </p>
            <p>К таким обстоятельствам относятся, в том числе:</p>
            <ul>
              <li>Стихийные бедствия</li>
              <li>Трудовые или торговые конфликты</li>
              <li>Военные конфликты</li>
              <li>Общественные беспорядки</li>
              <li>Отключение электроэнергии</li>
              <li>Действия, бездействие или отказы со стороны государственных органов</li>
              <li>Проблемы в работе телекоммуникационных или интернет-сетей</li>
              <li>Сбои, вызванные сторонними поставщиками или техническими подрядчиками</li>
            </ul>
            <p>
              В таких случаях мы оставляем за собой право приостановить или отменить доступ к Сервису без компенсации
              или ответственности перед вами.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>15. Ответственность</h2>
            <p>
              15.1. Сервис предоставляется исключительно для личного и некоммерческого использования. Мы не несем
              ответственности за любые коммерческие, деловые или косвенные убытки.
            </p>
            <p>15.2. Мы настоятельно рекомендуем вам:</p>
            <ul>
              <li>Проверять совместимость вашего устройства с работой Сервиса;</li>
              <li>Использовать антивирусное ПО и другие средства безопасности для защиты от вредоносных программ.</li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>16. Запрет игры несовершеннолетним</h2>
            <p>
              16.1. Если мы заподозрим, что вам не исполнилось 18 лет (или вы не достигли иного установленного законом
              возраста), ваша учетная запись будет немедленно приостановлена.
            </p>
            <p>Мы проведем внутреннее расследование, в том числе:</p>
            <ul>
              <li>Проверим факт ставок от имени несовершеннолетнего,</li>
              <li>Установим, не действовали ли вы как посредник.</li>
            </ul>
            <p>Если подтвердится, что вы:</p>
            <ul>
              <li>На момент ставок были младше 18 лет,</li>
              <li>Делали ставки от имени несовершеннолетнего лица,</li>
            </ul>
            <p>Мы оставляем за собой право:</p>
            <ul>
              <li>Аннулировать все выигрыши, в том числе уже зачисленные;</li>
              <li>Потребовать возврата всех выигрышей, полученных в период нарушения;</li>
              <li>Вернуть или заморозить средства на счете до достижения вами совершеннолетия.</li>
            </ul>
            <p>
              16.2. Данное правило также применяется, если вам исполнилось 18 лет, но вы находитесь в юрисдикции, где
              минимальный возраст участия в азартных играх выше, и вы его не достигли.
            </p>
            <p>16.3. В случае подозрения в мошенничестве, связанном с возрастом, мы вправе:</p>
            <ul>
              <li>Провести дополнительную проверку,</li>
              <li>При необходимости — связаться с правоохранительными органами.</li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>17. Мошенничество</h2>
            <p>
              Мы строго противодействуем любым попыткам мошенничества, злоупотребления Сервисом или совершения
              противоправных действий.
            </p>
            <ul>
              <li>
                В случае подозрений в подобных действиях мы оставляем за собой право задержать выплату, провести
                проверку и принять другие необходимые меры.
              </li>
              <li>
                В случае подтверждения нарушений, Компания будет добиваться гражданской, административной или уголовной
                ответственности Клиента.
              </li>
            </ul>
            <p>Клиент обязуется:</p>
            <ul>
              <li>
                Компенсировать убытки, понесённые Веб-сайтом (включая прямые и косвенные потери, упущенную выгоду и
                репутационные риски);
              </li>
              <li>
                Возместить все расходы, возникшие в связи с расследованием, разбирательствами или принудительным
                взысканием.
              </li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>18. Интеллектуальная собственность</h2>

            <p>
              18.1. Несанкционированное использование названия, логотипа или фирменного стиля Веб-сайта запрещено и
              может повлечь за собой судебные иски.
            </p>
            <p>
              18.2. Все права на технологии, программное обеспечение, бизнес-системы, интерфейс и контент Сервиса
              принадлежат Компании.
            </p>

            <ul>
              <li>
                Вы не можете использовать свой профиль в коммерческих целях, в том числе для продвижения сторонних
                проектов.
              </li>
              <li>Мы оставляем за собой право изменить или удалить никнейм, если сочтём его неприемлемым.</li>
            </ul>

            <p>
              18.3. Запрещено использовать наши товарные знаки, логотипы и доменные имена в любом контексте, который
              может:
            </p>
            <ul>
              <li>Ввести в заблуждение других пользователей;</li>
              <li>Нанести вред деловой репутации Сервиса;</li>
              <li>Ассоциироваться с продуктами/услугами третьих лиц.</li>
            </ul>

            <p>
              18.4. За исключением прямо предусмотренных случаев, вы не получаете никаких прав или лицензий на
              использование интеллектуальной собственности Веб-сайта.
            </p>
            <ul>
              <li>
                Запрещено копировать, мониторить, воспроизводить или модифицировать содержимое Сервиса любыми средствами
                — как автоматизированными, так и ручными.
              </li>
              <li>Любое нарушение может привести к гражданской и/или уголовной ответственности.</li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>19. Ваша лицензия</h2>

            <p>
              19.1. В рамках настоящих Условий вы получаете ограниченную, неисключительную, непередаваемую лицензию на
              использование Сервиса в личных некоммерческих целях.
            </p>
            <ul>
              <li>Лицензия автоматически прекращается при завершении ваших отношений с Веб-сайтом.</li>
            </ul>
            <p>
              19.2. За исключением вашего собственного контента, вы не имеете права публиковать, изменять,
              распространять, продавать, демонстрировать или использовать содержимое Сервиса и программное обеспечение
              без прямого письменного разрешения.
            </p>
            <ul>
              <li>
                Запрещено использовать любые технологии или методы для извлечения или реорганизации данных Сервиса
                (включая парсинг, базы данных, скриншоты и т.д.).
              </li>
            </ul>
            <p>
              19.3. Нарушение условий использования контента может повлечь за собой ответственность перед третьими
              лицами, включая авторов, правообладателей и другие стороны.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>20. Ваши действия и безопасность</h2>

            <p>
              20.1. Любое незаконное, неэтичное или нежелательное поведение в рамках Сервиса строго запрещено
              ("Запрещенные действия").
            </p>

            <p>
              20.2. В случае выявления таких действий, мы оставляем за собой право немедленно заблокировать доступ к
              вашему аккаунту без предварительного уведомления, а также:
            </p>
            <ul>
              <li>Удалить ваши сообщения и материалы</li>
              <li>Сообщить о действиях компетентным органам</li>
              <li>Подать на вас в суд или инициировать иные меры</li>
            </ul>

            <p>20.3. Запрещенные действия включают (но не ограничиваются):</p>
            <ul>
              <li>Продвижение ложной, вводящей в заблуждение или незаконной информации</li>
              <li>Любую незаконную активность, нарушение прав других Клиентов</li>
              <li>Распространение вредоносного ПО</li>
              <li>Оскорбления, пропаганду насилия, расизма, вражды</li>
              <li>Нарушение авторских и интеллектуальных прав</li>
              <li>Создание фальшивых аккаунтов или учетных записей от имени других лиц</li>
              <li>Рассылку спама, схем "писем счастья", пирамид и т.п.</li>
              <li>Использование ботов, читов, модов или других сторонних программ</li>
              <li>Сбор данных или копирование содержимого без разрешения</li>
              <li>Любые попытки обмануть или навредить другим Клиентам</li>
            </ul>

            <p>
              Данный список может быть дополнен и изменён. Мы оставляем за собой право незамедлительно принять меры
              против любых лиц, участвующих в подобных действиях — прямо или косвенно.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>21. Ссылки на другие веб-сайты</h2>

            <p>
              Сервис может содержать ссылки на сторонние веб-сайты, которые не принадлежат и не контролируются
              Веб-сайтом.
            </p>

            <ul>
              <li>
                Такие ссылки предоставлены исключительно для удобства, и их наличие не означает одобрения или
                аффилированности с их владельцами.
              </li>
              <li>
                Мы не проверяем и не несем ответственности за точность, безопасность, доступность или полезность
                содержимого сторонних сайтов.
              </li>
              <li>
                Рекомендуем вам проявлять осмотрительность, внимательно читать условия использования и политику
                конфиденциальности перед взаимодействием с такими ресурсами.
              </li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>22. Жалобы</h2>

            <p>
              22.1. Все обращения и жалобы направляются через службу поддержки по электронному адресу:
              support@vocxod.com, с вашего адреса электронной почты.
            </p>
            <p>
              22.2. Мы не несем ответственности за последствия, связанные с тем, как и когда мы реагируем на полученную
              жалобу.
            </p>
            <p>
              22.3. Споры по ставкам и розыгрышам должны быть открыты в течение 3 (трёх) дней с момента возникновения
              ситуации. Поздние обращения не рассматриваются.
            </p>
            <p>
              22.4. При возникновении спора сначала обращайтесь в службу поддержки, которая предпримет все возможные
              шаги для урегулирования вопроса. При необходимости спор будет передан руководству Веб-сайта.
            </p>
            <p>
              22.5. Если урегулирование не достигнуто, вы можете подать жалобу в лицензирующий орган — Gaming Services
              Provider N.V.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>23. Делегирование прав</h2>

            <p>
              Вы не можете передавать свои права или обязательства по данным Условиям без письменного согласия
              Веб-сайта.
            </p>
            <p>
              Мы оставляем за собой право делегировать свои права и обязанности третьим лицам без вашего согласия, при
              условии, что услуги будут предоставляться на сопоставимом уровне качества.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>24. Делимость</h2>

            <p>
              Если какое-либо положение настоящих Условий будет признано недействительным или неисполнимым, оно будет
              адаптировано в максимально допустимой форме, сохраняя суть. Остальные положения останутся в силе.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>25. Нарушение настоящих Условий</h2>

            <p>
              В случае нарушения вами настоящих Условий, мы имеем право приостановить или закрыть вашу учетную запись и
              отказать в дальнейшем предоставлении услуг без предварительного уведомления.
            </p>
            <p>Вы будете уведомлены о принятых мерах после их реализации.</p>
          </div>

          <div className={styles.contentItem}>
            <h2>26. Общие положения</h2>

            <p>
              26.1. Условия действуют с момента первого доступа к Сервису и остаются в силе даже после закрытия вашей
              учетной записи.
            </p>

            <p>
              26.2. Все термины применимы во множественном и единственном числе, а также к любому типу юридического
              лица.
            </p>

            <p>
              26.3. Отказ от исполнения любого положения действителен только в письменной форме и не означает отказ от
              других прав или положений.
            </p>

            <p>
              26.4. Продолжая пользоваться Сервисом, вы подтверждаете, что полностью согласны с Условиями и
              отказываетесь от любых последующих возражений.
            </p>

            <p>26.5. Условия регулируются законодательством Кюрасао.</p>

            <p>
              26.6. Настоящие Условия являются полным соглашением между вами и Компанией и заменяют любые предыдущие
              договоренности.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>27. Политика возврата средств</h2>

            <p>
              27.1. Возврат средств невозможен, если предполагаемый депозит (включая бонусные средства) уже был
              использован или отыгран на Веб-сайте.
            </p>

            <p>
              27.2. Мы оставляем за собой право задержать возврат средств или обратную транзакцию до момента завершения
              полной верификации.
            </p>

            <ul>
              <li>
                Мы можем потребовать нотариально заверенную копию документа, удостоверяющего личность или иной
                официально подтверждённый документ.
              </li>
              <li>
                В случае непредоставления необходимых документов в течение 28 дней, возврат не осуществляется,
                клиентский счёт подлежит закрытию, а все средства на счёте аннулируются. Решение является окончательным
                и не подлежит обжалованию.
              </li>
            </ul>

            <p>27.3. Клиент обязан использовать честные методы игры.</p>

            <ul>
              <li>
                Запрещено любое влияние на исход игр, включая использование автоматических программ, математических
                систем ставок и т.д.
              </li>
              <li>Нарушение этого условия может стать основанием для отказа в возврате средств.</li>
            </ul>

            <p>27.4. Возврат может быть произведён с удержанием комиссии, отражающей:</p>
            <ul>
              <li>Фактические издержки Компании, связанные с обработкой транзакций;</li>
              <li>Потери или убытки, понесённые Компанией в связи с действиями Клиента.</li>
              <li>Размер удержания рассчитывается индивидуально и зависит от характера обращения.</li>
            </ul>

            <p>27.5. Запрос на возврат средств рассматривается индивидуально.</p>

            <ul>
              <li>После принятия решения средства будут возвращены в течение 5 рабочих дней.</li>
            </ul>
          </div>

          <div className={styles.contentItem}>
            <h2>28. Бонусная политика</h2>

            <p>
              28.1. Веб-сайт вправе самостоятельно определять условия проведения бонусных акций, включая, но не
              ограничиваясь: денежными бонусами, бесплатными вращениями, временными улучшениями условий и
              индивидуальными заданиями (далее — "цели").
            </p>

            <p>
              28.2. Клиент обязан заранее ознакомиться с условиями каждого бонусного предложения до его активации.
              Условия конкретного бонуса публикуются на странице бонусных правил в личном кабинете или в соответствующих
              уведомлениях.
            </p>

            <p>28.3. Цель и целевой баланс.</p>

            <p>
              28.3.1. Цель — это обязательное условие для завершения бонуса, выраженное в установленном коэффициенте от
              изначальной суммы реального и бонусного балансов.
            </p>

            <p>Целевой баланс — это сумма, зафиксированная на момент активации бонуса, которая включает:</p>
            <ul>
              <li>средства на реальном балансе;</li>
              <li>средства на бонусном балансе;</li>
              <li>множитель цели.</li>
            </ul>

            <p>
              После активации бонуса система рассчитывает целевой баланс и фиксирует его как ориентир для выполнения
              условия бонуса.
            </p>

            <p>Достижение целевого баланса считается завершением бонусной активности, при котором:</p>
            <ul>
              <li>
                вся сумма на бонусном балансе автоматически переводится на реальный баланс, за исключением случаев,
                когда действуют ограничения на максимальный выигрыш с бонуса;
              </li>
              <li>ограничения на вывод средств снимаются.</li>
            </ul>

            <p>28.3.2. Пример активации бонуса:</p>
            <p>
              Если Клиент активирует бонус в размере 100% (удвоение) от депозита на сумму 5 000 рублей с целью х3, то
              для выполнения цели требуется достичь суммарного баланса (реального и бонусного): (5 000 + 5 000) × 3 = 30
              000 рублей.
            </p>

            <p>28.3.3. До достижения цели:</p>
            <ul>
              <li>Вывод средств невозможен;</li>
              <li>Попытка перевести средства с бонусного баланса на реальный баланс будет ограничена системой;</li>
              <li>Нарушение условий цели может привести к аннулированию бонуса и связанных с ним выигрышей.</li>
            </ul>

            <p>
              Веб-сайт оставляет за собой право изменять условия выполнения целей, а также методику расчета целевого
              баланса без предварительного уведомления.
            </p>

            <p>28.3.4. Обработка превышения при активации бонуса.</p>

            <p>
              В случае, если на момент активации бонуса сумма средств на реальном балансе превышает максимально
              допустимую для участия в бонусе, остаток будет автоматически переведен на баланс «Сейф».{' '}
            </p>

            <p>Средства, переведённые в «Сейф»:</p>
            <ul>
              <li>не участвуют в бонусной активности;</li>
              <li>не учитываются при выполнении цели;</li>
              <li>становятся доступными для использования и вывода после завершения бонуса.</li>
            </ul>

            <p>
              Информация о перемещении средств отображается в «Профиле». Веб-сайт оставляет за собой право изменять
              предельные суммы активации бонусов и правила обработки превышения без предварительного уведомления.
            </p>

            <p>28.4. Еженедельный буст.</p>

            <p>
              Акция “Буст” активируется еженедельно, по понедельникам в период с 08:00 до 12:00 по МСК — с последующим
              начислением не позднее 12:00 по МСК на баланс «Сейфа».
            </p>

            <p>
              Бонус предоставляется персонально, и каждый Клиент, которому он начислен, будет уведомлен вручную
              сотрудником Компании через официальные каналы связи.
            </p>

            <p>
              Формула расчёта буста: (Сумма ставок с реального баланса - Сумма ставок в Live играх - Бонусные средства,
              переведённые на реальный баланс) × 4% × 10%
            </p>

            <p>или в обозначениях: (Sum Bet Real - Sum Bet Live - bonus2real) × 0.04 × 0.10</p>

            <p>
              Веб-сайт оставляет за собой право вносить изменения в механизм начисления буста, включая временные рамки,
              порядок уведомлений и коэффициенты расчёта, без предварительного уведомления.
            </p>

            <p>
              28.5. Все бонусные предложения, включая бусты и цели, предоставляются по усмотрению Веб-сайта. Условия
              могут быть изменены или отменены без предварительного уведомления.
            </p>

            <p>
              28.6. На время действия активного бонуса доступ к лайв-играм, играм формата «краш», а также другим играм с
              низкой волатильностью ограничен системой. Попытки обхода указанных ограничений считаются нарушением и
              могут привести к блокировке учётной записи, аннулированию бонуса и связанных с ним выигрышев.
            </p>

            <p>
              28.7. При активации бонуса запрещено использовать стратегии ставок с минимальным риском (например,
              одновременные ставки на противоположные исходы в рулетке или других аналогичных играх). Компания оставляет
              за собой право аннулировать бонус и связанные с ним выигрыши в случае выявления таких действий.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>29. Программа лояльности</h2>

            <p>
              29.1. Компания предоставляет Клиентам право участвовать в программе лояльности, условия которой
              устанавливаются исключительно Компанией и могут быть изменены без предварительного уведомления. Участие в
              программе лояльности подразумевает получение вознаграждений за активность Клиента в рамках Сервиса.
            </p>

            <p>
              29.2. Уровень лояльности Клиента определяется на основании общей суммы ставок, совершённых на реальные
              денежные средства в игровых автоматах. Для достижения каждой следующей ступени уровня лояльности
              необходимо выполнить установленный оборот ставок.
            </p>

            <p>
              29.3. Ставки, совершённые в лайв-играх, настольных играх, а также в играх формата «краш», не учитываются
              при расчёте оборота для уровней лояльности. Текущий процент выполнения условий до следующего уровня
              отображается в разделе профиля «Лояльность».
            </p>

            <p>
              29.4. Статус Клиента в рамках программы лояльности рассчитывается и обновляется системой автоматически,
              исходя из общей суммы совершённых ставок. Информация о текущем статусе на Веб-сайте может отображаться с
              задержкой до 15 минут.
            </p>

            <p>
              29.5. Начиная с третьего уровня («Бронза»), Клиент получает бонус за достижение нового уровня лояльности.
              Бонус становится доступен сразу после обновления статуса на Веб-сайте. Вместе с повышением уровня
              лояльности улучшаются условия еженедельных бонусов. Подробную информацию о текущих условиях бонусов и
              статусах можно найти в разделе профиля «Лояльность».
            </p>

            <p>
              29.6. Для использования бонусных средств, полученных в рамках программы лояльности, учётная запись Клиента
              должна быть полностью верифицирована, включая указание даты рождения и предоставление документов,
              удостоверяющих личность. Компания оставляет за собой право запросить необходимые документы и данные для
              подтверждения личности Клиента в любой момент по своему усмотрению.
            </p>

            <p>
              29.7. Бездепозитные бонусы, полученные в рамках программы лояльности, требуют выполнения условий цели для
              завершения бонуса.
            </p>

            <p>
              29.8. Если иное не оговорено в конкретном бонусном предложении, срок действия бездепозитных бонусов
              составляет 1 календарный день с момента активации.
            </p>

            <p>
              29.9. Участие в программе лояльности ограничено одним Клиентом по следующим признакам: фамилия, имя,
              отчество, дата рождения, ИНН, адрес проживания, игровая учётная запись, IP-адрес, устройство, адрес
              электронной почты, номер телефона, банковская карта или платёжный метод.
            </p>

            <p>
              29.10. Компания оставляет за собой право в одностороннем порядке вносить изменения в программу лояльности
              и её условия без предварительного уведомления Клиентов.
            </p>

            <p>
              29.11. Уровни лояльности Клиента актуализируются каждые 30 календарных дней с момента последнего изменения
              статуса. По истечении данного периода система автоматически пересчитывает уровень лояльности, исходя из
              суммы ставок, совершённых Клиентом за последние 30 дней. В случае возникновения особых обстоятельств
              Клиент вправе обратиться в службу поддержки Компании с просьбой о сохранении текущего уровня лояльности.
              Компания рассматривает такие запросы индивидуально и принимает решение по собственному усмотрению.
            </p>
          </div>

          <div className={styles.contentItem}>
            <h2>30. «Сейф»</h2>

            <p>
              30.1. «Сейф» – это отдельный виртуальный баланс Клиента, предназначенный для безопасного хранения денежных
              средств внутри учётной записи.
            </p>

            <p>
              30.2. Средства, размещённые в «Сейфе», не участвуют в текущей игровой активности, ставках и бонусных
              программах до момента их перевода на реальный баланс Клиента.
            </p>

            <p>
              30.3. Клиент может свободно переводить средства между «Сейфом» и реальным балансом в разделе «Профиль».
              Ограничения на перевод могут действовать во время активности определённых бонусов и акций, условия которых
              указаны отдельно.
            </p>

            <p>
              30.4. Вывод средств напрямую из «Сейфа» невозможен. Для осуществления вывода денежных средств, находящихся
              в «Сейфе», Клиенту необходимо предварительно перевести их на реальный баланс в разделе «Профиль».
            </p>

            <p>
              30.5. Компания вправе ограничить доступность перевода средств из «Сейфа» на время проведения технических
              работ, акций, бонусных активностей или в случае возникновения вопросов безопасности.
            </p>

            <p>
              30.6. В случае возникновения вопросов или сложностей, связанных с использованием «Сейфа», Клиенту следует
              обратиться в службу поддержки Компании по указанным на сайте каналам связи.
            </p>
          </div>
        </div>
      </div>
      <Footer />
      {isMobile && <NavBarBottom />}
    </>
  );
};
