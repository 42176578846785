import { Button } from '../../../../../atoms/Button/Button';

export const getButton = (data, handleClick, inProgressBonus) => {
  if (data?.status === 'ALLOW') {
    return <Button buttonText='Активировать бонус' onClick={handleClick} />;
  }
  if (data?.status === 'DISALLOW' && inProgressBonus) {
    return <Button buttonText='Уже есть активный бонус' disabled={true} />;
  }
  if (data?.status === 'DISALLOW') {
    return <Button buttonText='Бонус недоступен' disabled={true} />;
  }
  if (data?.status === 'IN_PROGRESS') {
    return <Button buttonText='Отменить бонус' onClick={handleClick} />;
  }
};
