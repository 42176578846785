import { IMaskInput } from 'react-imask';
import { useDispatch, useSelector } from 'react-redux';

import { setPaymentState } from '../../../../../redux/slices/paymentSlice';
import { getModalIconByMethod } from '../../../../../utils/getModalIconByMethod';
import PasteIcon from './pasteIcon.svg?react';
import styles from './WithdrawalRequisitesAdd.module.scss';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';

export const useInputByMethod = () => {
  const { paymentMethod, primaryMethod, crypto, requisiteNumber } = useSelector(store => store.paymentSlice);

  const dispatch = useDispatch();
  const Icon = getModalIconByMethod(paymentMethod);

  const handleChange = e => {
    dispatch(setPaymentState({ requisiteNumber: e.target?.value.trim() }));
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const trimmedText = text.trim();

      if (crypto?.walletLength !== trimmedText.length) {
        dispatch(
          setNotifications({
            type: 'error',
            message: 'WRONG_PASTE_WALLET',
            params: { walletLength: crypto?.walletLength },
          }),
        );
        return;
      }
      handleChange({ target: { value: trimmedText.trim() } });
    } catch (error) {
      console.error('Failed to read clipboard:', error);
    }
  };

  const renderInput = () => {
    if (paymentMethod === 'SBP P2P RUB' || paymentMethod === 'TRANS SBP P2P RUB') {
      return (
        <IMaskInput
          id='requisiteNumber'
          name='requisiteNumber'
          mask='+7 (000) 000 00-00'
          unmask={true}
          onAccept={value => handleChange({ target: { value: `7${value}` } })}
          placeholder='Введите номер телефона'
          maxLength={18}
        />
      );
    }

    if (paymentMethod === 'MC/VISA/MIR P2P RUB') {
      return (
        <IMaskInput
          id='requisiteNumber'
          name='requisiteNumber'
          mask='0000 0000 0000 0000 000'
          unmask={true}
          onAccept={value => handleChange({ target: { value } })}
          placeholder='Введите номер карты'
          maxLength={23}
        />
      );
    }

    if (paymentMethod === 'A2A RUB') {
      return (
        <IMaskInput
          id='requisiteNumber'
          name='requisiteNumber'
          mask='000 00 000 0 0000 0000000'
          unmask={true}
          onAccept={value => handleChange({ target: { value } })}
          placeholder='Введите номер счёта'
          maxLength={25}
        />
      );
    }

    if (primaryMethod === 'CRYPTO') {
      return (
        <>
          <input
            id='requisiteNumber'
            name='requisiteNumber'
            onChange={handleChange}
            placeholder='Введите адрес кошелька'
            maxLength={crypto?.walletLength}
            value={requisiteNumber}
          />
          <div className={styles.pasteIcon} onClick={handlePaste} role='button' tabIndex={0}>
            <PasteIcon />
          </div>
        </>
      );
    }
  };

  return (
    <div className={styles.cardField}>
      <div className={styles.icon}>
        <Icon />
      </div>
      {renderInput()}
    </div>
  );
};
