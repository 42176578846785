import axios from 'axios';

import { API_BASE_URL } from '../constants/constants';

export const makeRequest = async (method, url, data = {}, token, handleError) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    withCredentials: true,
    url,
    method,
    data: method !== 'get' ? data : undefined,
    params: method === 'get' ? data : undefined,
    baseURL: API_BASE_URL,
  };

  try {
    const response = await axios(config);
    return response.data || null;
  } catch (error) {
    if (error.response?.status === 401) {
      const result = await handleError(error, config);
      if (result?.data) {
        return result.data;
      }
    } else {
      await handleError(error);
    }
    throw error;
  }
};
