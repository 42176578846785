import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useCrmCall } from '../../../../../api/useCrmCall';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';
import { clearState, setActiveRequisite } from '../../../../../redux/slices/paymentSlice';
import { Button } from '../../../../atoms/Button/Button';
import { NumberInput } from '../../../../molecules/NumberInput/NumberInput';
import { RequisiteSelect } from '../../../../molecules/RequisiteSelect/RequisiteSelect';
import { useWithdrawalClose } from '../useWithdrawalClose';
import { WithdrawalBase } from '../WithdrawalBase/WithdrawalBase';
import styles from './WithdrawalRequisites.module.scss';

export const WithdrawalRequisites = ({ closePortal }) => {
  const { accessToken } = useSelector(store => store.userSlice);
  const { withdrawalAmount, paymentMethod, activeRequisite, crypto } = useSelector(store => store.paymentSlice);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { postCrmRequest } = useCrmCall();
  const { t } = useTranslation('Withdrawal');
  const { t: tCrypto } = useTranslation('Crypto');
  const handleClosePortal = useWithdrawalClose(closePortal);

  const initWithdrawalMutation = useMutation({
    mutationFn: data => postCrmRequest('/payment/payout_init_by_id', data),
    onSuccess: () => {
      queryClient.invalidateQueries(['userBalance']);
      dispatch(setActiveRequisite(null));
      dispatch(clearState());
      dispatch(setNotifications({ type: 'success', message: 'SUCCESS_WITHDRAWAL' }));
      handleClosePortal();
    },
  });

  const data = {
    cardId: activeRequisite?.id,
    amount: withdrawalAmount,
    network: crypto?.network,
    currency: crypto?.currency,
  };

  useEffect(() => {
    const modalContent = document.querySelector('.ReactModal__Content');
    if (modalContent) modalContent.style.overflowY = 'visible';
    return () => {
      const modalContent = document.querySelector('.ReactModal__Content');
      if (modalContent) modalContent.style.overflowY = 'scroll';
    };
  }, []);

  return (
    <WithdrawalBase
      closePortal={closePortal}
      title={crypto?.network ? tCrypto(`${paymentMethod}.methodName`) : t(`${paymentMethod}.methodName`)}
      className={styles.modal}
      backTo='withdrawal'
    >
      <div className={styles.wrapper}>
        <RequisiteSelect activeRequisite={activeRequisite} setActiveRequisite={setActiveRequisite} />
        <NumberInput labelText='Сумма вывода' name='amount' disabled={true} value={withdrawalAmount || ''} />
        <Button
          buttonText='Вывод средств'
          isLoading={initWithdrawalMutation?.isPending}
          onClick={() => accessToken && initWithdrawalMutation?.mutate(data)}
          disabled={!activeRequisite}
        />
      </div>
    </WithdrawalBase>
  );
};
