import { useNavigate } from 'react-router-dom';

import BonusAvailable from '../../../../../../assets/components/ModalCategory/BonusAvailable.svg?react';
import BonusBuy from '../../../../../../assets/components/ModalCategory/BonusBuy.svg?react';
import LiveCasino from '../../../../../../assets/components/ModalCategory/LiveCasino.svg?react';
import MegaWays from '../../../../../../assets/components/ModalCategory/MegaWays.svg?react';
import Popular from '../../../../../../assets/components/ModalCategory/Popular.svg?react';
import New from '../../../../../../assets/components/NavBar/CategoryIco.svg?react';
import { useDisableInteractionOnScroll } from '../../../../../../hooks/useDisableInteractionOnScroll';
import useDisableScroll from '../../../../../../hooks/useDisableScroll';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import Skeleton from '../../../../../atoms/Skeleton/Skeleton';
import GamesModalWrapper from '../../GamesModalWrapper';
import { CategoryItem } from '../CategoryItem/CategoryItem';
import styles from './ModalContentCategory.module.scss';

const categories = [
  { title: 'Доступные с бонусом', category: 'with-bonus', img: BonusAvailable },
  { title: 'Популярные', category: 'popular', img: Popular },
  { title: 'Купить бонус', category: 'bonus-buy', img: BonusBuy },
  { title: 'Megaways', category: 'megaways', img: MegaWays },
  { title: 'Live Casino', category: 'live-casino', img: LiveCasino },
  { title: 'Новинки', category: 'new', img: New },
];

export const ModalContentCategory = () => {
  useDisableScroll();
  const { isMobile } = useWindowSize();

  const navigate = useNavigate();

  useDisableInteractionOnScroll();

  const handleClick = key => {
    navigate(key === 'new' ? `/${key}` : `/category/${key}`);
  };

  if (categories.length === 0) {
    return (
      <>
        <GamesModalWrapper>
          {Array.from({ length: isMobile ? 9 : 16 }, (el, i) => (
            <Skeleton key={i} className={styles.skeleton} />
          ))}
        </GamesModalWrapper>
      </>
    );
  }

  return (
    <>
      <GamesModalWrapper>
        {categories?.map(({ title, category, img }) => {
          return <CategoryItem key={title} title={title} img={img} onClick={() => handleClick(category)} />;
        })}
      </GamesModalWrapper>
    </>
  );
};
