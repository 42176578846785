import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setCryptoData, setPaymentMethod } from '../../../redux/slices/paymentSlice';
import { PaymentCard } from '../../atoms/PaymentCard/PaymentCard';
import cardStyles from '../../atoms/PaymentCard/PaymentCard.module.scss';
import Skeleton from '../../atoms/Skeleton/Skeleton';
import styles from './PaymentMethods.module.scss';
import { formatPaymentMethod } from './utils/formatPaymentMethod';
import { getRawCryptoList } from './utils/getRawCryptoList';

const disabledMethods = {
  deposit: ['CRYPTO'],
  withdrawal: ['CRYPTO', 'TRANS SBP P2P RUB'],
  safe: ['CRYPTO'],
};

export const PaymentMethods = ({ header, fiatMethods, cryptoMethods, minBadge = false }) => {
  const { commonModalType } = useSelector(store => store.commonModalSlice);
  const { paymentMethod, isMethodsLoading } = useSelector(store => store.paymentSlice);
  const ref = useRef();

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const rawCryptoList = getRawCryptoList(cryptoMethods);

  const handleCardClick = (methodName, network, currency) => {
    const walletLength = cryptoMethods?.[network]?.address_length;

    dispatch(setPaymentMethod(methodName));
    dispatch(setCryptoData({ network, currency, walletLength }));
  };

  const fiatMethodsToShow = fiatMethods?.paymentMethodList
    ?.filter(el => !disabledMethods?.[commonModalType || pathname.split('/').at(-1)]?.includes(el))
    .map(el => {
      return { name: el, paymentLimit: fiatMethods?.limits?.payment[el] };
    });

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className={styles.methodsContainer}>
      <p className={styles.methodsLabel}>{header}</p>
      <div className={styles.methods} ref={ref}>
        {isMethodsLoading &&
          Array.from({ length: 4 }).map((el, i) => <Skeleton key={`payCard-${i}`} className={cardStyles.methodCard} />)}

        {fiatMethodsToShow &&
          fiatMethodsToShow?.map(el => (
            <PaymentCard
              key={el.name}
              className={paymentMethod === el.name ? styles.selected : ''}
              paymentMethod={el}
              onClick={methodName => handleCardClick(methodName)}
              minBadge={minBadge}
            />
          ))}

        {rawCryptoList?.length > 0 &&
          rawCryptoList?.map(item => {
            const network = Object.keys(item)[0];
            return item[network].map(currency => {
              return (
                <PaymentCard
                  key={currency}
                  className={paymentMethod === formatPaymentMethod(network, currency) ? styles.selected : ''}
                  paymentMethod={{ name: formatPaymentMethod(network, currency) }}
                  onClick={methodName => handleCardClick(methodName, network, currency)}
                />
              );
            });
          })}
      </div>
    </div>
  );
};
