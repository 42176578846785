import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TribeDarkPng from '../../../../../assets/components/Header/TribeDark.png';
import TribeLightPng from '../../../../../assets/components/Header/TribeLight.png';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { ThemeContext } from '../../../../../theme/ThemeContext';
import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter';
import { BackButton } from '../../../../atoms/BackButton/BackButton';
import styles from '../../../../molecules/ModalHeader/ModalHeader.module.scss';
import { useFullscreen } from '../../useFullscreen';
import Fullscreen from './Fullscreen.svg?react';

const GameHeader = ({ title, gameRef }) => {
  const { finalTheme } = useContext(ThemeContext);

  const { t } = useTranslation('ModalHeader');
  const navigate = useNavigate();
  const mutationRef = useRef();

  const { isMobile } = useWindowSize();
  const { enterFullscreen } = useFullscreen(gameRef);

  useEffect(() => {
    const imageElement = mutationRef.current;
    if (imageElement) {
      imageElement.classList.remove(styles.fade);
      setTimeout(() => {
        imageElement.classList.add(styles.fade);
      }, 10);
    }
  }, [finalTheme]);

  const handleFullscreen = () => {
    enterFullscreen();
  };

  return (
    <div className={styles.modalHeader}>
      <BackButton handleGoBack={navigate(-1)} />

      {!isMobile && (
        <div className={styles.tribe}>
          <img
            ref={mutationRef}
            style={{ opacity: 0 }}
            src={finalTheme === 'dark' ? TribeDarkPng : TribeLightPng}
            alt={finalTheme === 'dark' ? 'tribe' : 'tribeLight'}
          />
        </div>
      )}

      <div className={styles.headerContentWrapper}>
        <h3>{t(title) || capitalizeFirstLetter(title)}</h3>
        <div className={styles.fullscreen} onClick={handleFullscreen}>
          <Fullscreen />
        </div>
      </div>
    </div>
  );
};

export default GameHeader;
