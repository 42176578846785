import clsx from 'clsx';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCrmCall } from '../../../../api/useCrmCall';
import ArrowRightSvg from '../../../../assets/components/Profile/ArrowRightSvg.svg?react';
import BonusSvg from '../../../../assets/components/Profile/BonusSvg.svg?react';
import CopySvg from '../../../../assets/components/Profile/CopySvg.svg?react';
import LogOutSvg from '../../../../assets/components/Profile/LogOutSvg.svg?react';
import LoyaltySvg from '../../../../assets/components/Profile/LoyaltySvg.svg?react';
import NotVerifSvg from '../../../../assets/components/Profile/NotVerifSvg.svg?react';
import DeskAvatar from '../../../../assets/components/Profile/pfp.png';
import SettingSvg from '../../../../assets/components/Profile/SettingSvg.svg?react';
import TransactionsSvg from '../../../../assets/components/Profile/TransactionsSvg.svg?react';
import VerifSvg from '../../../../assets/components/Profile/VerifSvg.svg?react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { closeAdditionModal } from '../../../../redux/slices/additionalModalSlice';
import { closeCommonModal, openCommonModal } from '../../../../redux/slices/commonModalSlice';
import { closeAllModals } from '../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../redux/slices/notificationsSlice';
import { logout } from '../../../../redux/slices/userSlice';
import ModalOverlay from '../../../atoms/ModalOverlay/ModalOverlay';
import ModalHeader from '../../../molecules/ModalHeader/ModalHeader';
import styles from './Profile.module.scss';
import { WalletDropdown } from './ui/WalletDropdown';

const PageMeta = () => (
  <Helmet>
    <meta name='robots' content='noindex, nofollow' />
  </Helmet>
);

export const Profile = () => {
  const { crmUserData } = useSelector(store => store.userSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation('Profile');
  const { getCrmRequest } = useCrmCall();
  const { isMobile } = useWindowSize();

  useEffect(() => {
    if (!document.body.classList.contains('disable-scroll')) {
      document.body.classList.add('disable-scroll');
      return () => {
        document.body.classList.remove('disable-scroll');
      };
    }
  }, []);

  const handleLogout = async () => {
    try {
      navigate('/');

      const response = await getCrmRequest(`/auth/logout`);

      if (response.status === 200) {
        dispatch(logout());
        dispatch(closeCommonModal());
        dispatch(closeAdditionModal());
        dispatch(closeAllModals());
      }
    } catch (error) {
      dispatch(setNotifications({ type: 'error', message: error?.response?.data?.message }));
    }
  };

  const handleCopy = copyText => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        dispatch(setNotifications({ type: 'notification', message: 'SUCCESS_COPY_ID' }));
      })
      .catch(() => {
        dispatch(setNotifications({ type: 'error', message: 'ERROR_COPY_ID' }));
      });
  };

  const handleClose = () => {
    dispatch(closeCommonModal());
  };

  const handleOpen = slug => dispatch(openCommonModal({ type: slug }));

  return (
    <>
      <PageMeta />
      <ModalOverlay
        className={clsx(styles.modalOverlay, styles.profileModalOverlay, styles.mobileOverlay)}
        zIndex={60000}
        closePortal={handleClose}
      >
        <div className={clsx(styles.modal, styles.profileModal)}>
          {isMobile && (
            <div className={styles.avatar}>
              <img src={DeskAvatar} alt='TODO' />
              <div className={styles.profileVerif}>{!crmUserData?.is_verified ? <VerifSvg /> : <NotVerifSvg />}</div>
            </div>
          )}
          <ModalHeader title='profile' showTitle={!isMobile} showBackArrow={false} closePortal={handleClose} />
          <div className={styles.profileModalContent}>
            {!isMobile && (
              <div className={styles.desktopContent}>
                <img src={DeskAvatar} alt='TODO' />
                <WalletDropdown user={crmUserData} />
              </div>
            )}
            <div className={styles.userInfo}>
              <div className={styles.userInfoUsername}>
                {crmUserData?.username}
                {/*<EditSvg />*/}
              </div>
              <div className={styles.userInfoID}>
                {t('id')}: {crmUserData?.id}
                <button className={styles.copyButton} onClick={() => handleCopy(crmUserData?.id)}>
                  <CopySvg />
                </button>
              </div>
            </div>

            {isMobile && <WalletDropdown user={crmUserData} />}

            <div className={styles.buttonsNav}>
              <div className={styles.buttons} onClick={() => handleOpen('deposit')}>
                {t('deposit')}
              </div>
              <div className={styles.buttons} onClick={() => handleOpen('withdrawal')}>
                {t('withdrawal')}
              </div>
              <div className={styles.buttons} onClick={() => handleOpen('safe')}>
                {t('safe')}
              </div>
            </div>

            <div className={styles.navWrapper}>
              <div
                className={styles.navComponent}
                onClick={() => {
                  dispatch(closeCommonModal());
                  navigate('/profile/bonuses');
                }}
              >
                <div className={styles.navComponentIco}>
                  <BonusSvg />
                </div>
                {t('bonuses')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
              <div
                className={styles.navComponent}
                onClick={() => {
                  dispatch(closeCommonModal());
                  navigate('/profile/loyalty');
                }}
              >
                <div className={styles.navComponentIco}>
                  <LoyaltySvg />
                </div>
                {t('loyalty')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
              <div
                className={styles.navComponent}
                onClick={() => {
                  dispatch(closeCommonModal());
                  navigate('/profile/transactions');
                }}
              >
                <div className={styles.navComponentIco}>
                  <TransactionsSvg />
                </div>
                {t('transactions')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
              <div
                className={styles.navComponent}
                onClick={() => {
                  dispatch(closeCommonModal());
                  navigate('/profile/settings');
                }}
              >
                <div className={styles.navComponentIco}>
                  <SettingSvg />
                </div>
                {t('settings')}
                <div className={styles.redirectButton}>
                  <ArrowRightSvg />
                </div>
              </div>
            </div>

            <button className={styles.logOut} onClick={handleLogout}>
              <div className={styles.logOutWrapper}>
                {t('logout')}
                <LogOutSvg />
              </div>
            </button>
          </div>
        </div>
      </ModalOverlay>
    </>
  );
};
