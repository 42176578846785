import { useLocation, useNavigate } from 'react-router-dom';

export const useWithdrawalClose = closePortal => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return modalType => {
    // Если флоу через старую модалку по прямому урл
    if (pathname === '/wallet') {
      navigate('/');
    }

    closePortal(modalType);
  };
};
