import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowLeftDarkSvg from '../../../assets/components/ArrowLeft/arrowLeftDark.svg?react';
import TribeDarkPng from '../../../assets/components/Header/TribeDark.png';
import TribeLightPng from '../../../assets/components/Header/TribeLight.png';
import ArrowLeftSvg from '../../../assets/components/Staff/ArrowLeftSvg.svg?react';
import { ThemeContext } from '../../../theme/ThemeContext';
import { useWindowSize } from '../../../hooks/useWindowSize';
import getSEOTitle from '../../../utils/getSEOTitle';
import TextAccordion from '../../molecules/TextAccordion/TextAccordion';
import { Footer } from '../../organisms/Footer/Footer';
import { Header } from '../../organisms/Header/Header';
import NavBarBottom from '../../organisms/NavBar/NavBarBottom/NavBarBottom';
import { bonuses, loyalty, other, register, transactions } from './constants/index';
import styles from './FAQ.module.scss';

const PageMeta = () => (
  <Helmet>
    <meta charSet='utf-8' />
    <title>Казино Восход {getSEOTitle(window.location.href)} | Часто задаваемые вопросы</title>
    <meta
      name='description'
      content='Найдите ответы на самые распространенные вопросы о казино Восход. Узнайте о регистрации, способах оплаты, бонусах и многом другом.'
    />
  </Helmet>
);

export const FAQ = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { finalTheme } = useContext(ThemeContext);
  const { t } = useTranslation('FAQ');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageMeta />
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          {!isMobile && (
            <div className={styles.tribe}>
              {finalTheme === 'dark' ? <img src={TribeDarkPng} alt='tribe' /> : <img src={TribeLightPng} alt='tribe' />}
            </div>
          )}
          <div className={styles.backWrapper} onClick={() => navigate('/')}>
            {finalTheme === 'dark' ? (
              <ArrowLeftSvg height='100%' width='100%' />
            ) : (
              <ArrowLeftDarkSvg height='100%' width='100%' />
            )}
            <h1 className={styles.title}>{t('FAQ')}</h1>
          </div>
        </div>
        <div className={styles.content}>
          <h2 className={styles.subtitle}>Регистрация</h2>
          {register.map(({ title, description }) => (
            <TextAccordion key={description.slice(0, 10)} title={title} description={description} />
          ))}

          <h2 className={styles.subtitle}>Транзакции</h2>
          {transactions.map(({ title, description }) => (
            <TextAccordion key={description.slice(0, 10)} title={title} description={description} />
          ))}

          <h2 className={styles.subtitle}>Бонусы</h2>
          {bonuses.map(({ title, description }) => (
            <TextAccordion key={description.slice(0, 10)} title={title} description={description} />
          ))}

          <h2 className={styles.subtitle}>Программа лояльности</h2>
          {loyalty.map(({ title, description }) => (
            <TextAccordion key={description.slice(0, 10)} title={title} description={description} />
          ))}

          <h2 className={styles.subtitle}>Другие вопросы</h2>
          {other.map(({ title, description }) => (
            <TextAccordion key={description.slice(0, 10)} title={title} description={description} />
          ))}
        </div>
      </div>
      <Footer />
      {isMobile && <NavBarBottom />}
    </>
  );
};
