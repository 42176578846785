import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { MainPage } from '../components/pages/MainPage/MainPage';
import { openCommonModal } from '../redux/slices/commonModalSlice';

const COMMON_MODAL_ROUTES = ['/wallet'];

export const FallbackRoute = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (COMMON_MODAL_ROUTES.includes(pathname)) {
      dispatch(openCommonModal({ type: 'deposit' }));
    }
  }, [pathname, dispatch]);

  if (COMMON_MODAL_ROUTES.includes(pathname)) {
    return <MainPage />;
  }

  return <Navigate to='/404' replace />;
};
