import * as Progress from '@radix-ui/react-progress';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { STATIC_URL } from '../../../../../../constants/constants';
import { useWindowSize } from '../../../../../../hooks/useWindowSize';
import { setClickedBonus } from '../../../../../../redux/slices/bonusesSlice';
import { prepareNewsImageString } from '../../../../../../utils/prepareNewsImageString';
import styles from './ActiveBonusItem.module.scss';

const getProgress = (target, current) => {
  if (!Number.isNaN(current)) {
    return Math.floor((current * 100) / target);
  }
  return 0;
};

export const ActiveBonusItem = ({ data }) => {
  const { crmUserData } = useSelector(store => store.userSlice);

  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const img = prepareNewsImageString(data?.promo?.image, STATIC_URL);
  const currentProgress = Math.ceil(crmUserData?.wallets?.code?.credit + crmUserData?.wallets?.code?.air);

  const handleBonusClick = () => {
    dispatch(setClickedBonus(data));
    navigate(`/profile/bonuses/${data?.id}`);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div
          onClick={handleBonusClick}
          className={styles.container}
          style={{
            backgroundImage: img ? `url(${img})` : 'none',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div>
            <div className={styles.progressTargets}>
              <p>{currentProgress || 0}</p>
              <p>{Math.ceil(data?.progressData?.targetBalance)}</p>
            </div>
            <Progress.Root
              className={styles.progressRoot}
              value={getProgress(Math.ceil(data?.progressData?.targetBalance), currentProgress)}
            >
              <Progress.Indicator
                className={styles.progressIndicator}
                style={{
                  transform: `translateX(-${100 - getProgress(Math.ceil(data?.progressData?.targetBalance), currentProgress)}%)`,
                  transition: '1s',
                }}
              />
            </Progress.Root>
            <p className={styles.title}>{data?.promo?.ru?.title}</p>
          </div>
        </div>
        {!isMobile && (
          <div className={styles.textContainer}>
            {data?.settings?.targetPercent !== 0 && (
              <>
                <div className={styles.targetContainer}>
                  <p>Цель:</p>
                  <p>x{data?.settings?.targetPercent / 100}</p>
                </div>
              </>
            )}

            {data?.settings?.awardPercent > 0 && (
              <>
                <div className={styles.devider} />
                <div className={styles.targetContainer}>
                  <p>Размер бонуса:</p>
                  <p>{data?.settings?.awardPercent}%</p>
                </div>
              </>
            )}

            <div className={styles.fullText} dangerouslySetInnerHTML={{ __html: data?.promo?.ru?.full_text }} />
          </div>
        )}
      </div>
    </>
  );
};
