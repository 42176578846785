import { useRef } from 'react';

import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Footer } from '../../../organisms/Footer/Footer';
import { Header } from '../../../organisms/Header/Header';
import { NavBarHome } from '../../../organisms/NavBar/NavBarHome/NavBarHome';
import { SearchBar } from '../../../organisms/SearchBar/SearchBar';
import styles from '../GamePage.module.scss';
import GameHeader from './GameHeader/GameHeader';

export const DesktopLayout = ({ gameData, gameUrl, gameKey }) => {
  const gameRef = useRef();
  const { isDesktop, isTablet } = useWindowSize();

  return (
    <>
      <Header />
      <div className={styles.contentWrapper}>
        {isDesktop && (
          <div className={styles.desktopSearchWrapper}>
            <NavBarHome />
            <SearchBar lockScroll />
          </div>
        )}
        {isTablet && (
          <>
            <SearchBar lockScroll />
            <NavBarHome />
          </>
        )}
        <div className={styles.modal}>
          {(isDesktop || isTablet) && (
            <GameHeader title={gameData?.data?.game?.translates?.en?.name || ''} gameRef={gameRef} />
          )}
          <iframe
            ref={gameRef}
            className={styles.iframe}
            src={gameUrl}
            title={gameKey}
            style={{
              borderRadius: 0,
            }}
            allowFullScreen={true}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
