import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { STATIC_URL } from '../../../../../../constants/constants';
import { setClickedBonus } from '../../../../../../redux/slices/bonusesSlice';
import { prepareNewsImageString } from '../../../../../../utils/prepareNewsImageString';
import { Button } from '../../../../../atoms/Button/Button';
import styles from './BonusItem.module.scss';
import { getButtonText } from './getButtonText';
import Lock from './lock.svg?react';

export const BonusItem = ({ data }) => {
  const {
    filteredBonuses: { inProgressBonus },
  } = useSelector(store => store.bonusesSlice);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const img = prepareNewsImageString(data?.promo?.image, STATIC_URL);
  const title = data?.promo?.ru?.title;

  const handleClick = () => {
    dispatch(setClickedBonus(data));
    navigate(`/profile/bonuses/${data?.id}`);
  };

  const buttonText = getButtonText(data, inProgressBonus);

  return (
    <div
      onClick={handleClick}
      className={styles.container}
      style={{
        backgroundImage: img ? `url(${img})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {data?.status === 'DISALLOW' && <div className={styles.lockBlur} />}
      {data?.status === 'DISALLOW' && <Lock className={styles.lock} />}
      <p className={styles.title}>{title}</p>
      <Button className={styles.button} buttonText={buttonText} />
    </div>
  );
};
