import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DepSvg from '../../../../assets/components/NavBar/Deposit.svg?react';
import HomeSvg from '../../../../assets/components/NavBar/Home.svg?react';
import SupSvg from '../../../../assets/components/NavBar/Support.svg?react';
import { getAuthUrl } from '../../../../constants/ssoConstants';
import { openAdditionModal } from '../../../../redux/slices/additionalModalSlice';
import { openCommonModal } from '../../../../redux/slices/commonModalSlice';
import styles from './NavBarBottom.module.scss';

export const NavBarBottom = () => {
  const { accessToken } = useSelector(store => store.userSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => dispatch(openAdditionModal({ type: 'support' }));

  return (
    <div className={styles.wrapper}>
      <div className={styles.navBarBottom}>
        <div className={styles.buttonsContainer}>
          <button className={styles.buttonRight} onClick={handleClick}>
            <div className={styles.buttonRightIco}>
              <SupSvg />
            </div>
            Поддержка
          </button>
          <button onClick={() => navigate('/')} className={styles.buttonCenter}>
            <div className={styles.buttonCenterIco}>
              <HomeSvg />
            </div>
            Главная
          </button>
          <button
            onClick={() =>
              accessToken ? dispatch(openCommonModal({ type: 'deposit' })) : (window.location = getAuthUrl())
            }
            className={styles.buttonLeft}
          >
            <div className={styles.buttonLeftIco}>
              <DepSvg />
            </div>
            Депозит
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBarBottom;
