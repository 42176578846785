import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filteredGames: [],
  recentGames: [],
  providers: null,
};

const gamesSlice = createSlice({
  name: 'gamesSlice',
  initialState,
  reducers: {
    setFilteredGames(state, action) {
      state.filteredGames = action.payload;
    },
    setRecentGames(state, action) {
      state.recentGames = action.payload;
    },
    setProviders(state, action) {
      state.providers = action.payload;
    },
  },
});

export default gamesSlice.reducer;
export const { setRecentGames, setFilteredGames, setProviders } = gamesSlice.actions;
