import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useCrmCall } from '../../../../../api/useCrmCall';
import useDisableScroll from '../../../../../hooks/useDisableScroll';
import { openCommonModal } from '../../../../../redux/slices/commonModalSlice';
import { openModal } from '../../../../../redux/slices/modalSlice';
import { setNotifications } from '../../../../../redux/slices/notificationsSlice';
import { setActiveRequisite } from '../../../../../redux/slices/paymentSlice';
import { getIsFlagEnabled } from '../../../../../utils/getIsFlagEnabled';
import { getMaskedRequisit } from '../../../../../utils/getMaskedRequisit';
import { getModalIconByMethod } from '../../../../../utils/getModalIconByMethod';
import { Button } from '../../../../atoms/Button/Button';
import { WithdrawalBase } from '../WithdrawalBase/WithdrawalBase';
import styles from './WithdrawalRequisitesDelete.module.scss';

export const WithdrawalRequisitesDelete = ({ closePortal }) => {
  const { accessToken } = useSelector(store => store.userSlice);
  const { paymentMethod, activeRequisite } = useSelector(store => store.paymentSlice);
  const { t } = useTranslation('Withdrawal');
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { postCrmRequest } = useCrmCall();

  const deleteMutation = useMutation({
    mutationFn: () => postCrmRequest('/payment/payout_hide_requisite', { reqId: activeRequisite?.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userData'] }).then(() => {
        dispatch(openCommonModal({ type: 'withdrawalStep2' }));
        dispatch(setActiveRequisite(null));
        dispatch(setNotifications({ type: 'success', message: 'SUCCESS_PAYMENT_DATA_DELETE' }));
      });
    },
  });

  const handleSubmit = e => {
    e.stopPropagation();
    deleteMutation.mutate();
  };

  const handleBack = () => dispatch(openCommonModal({ type: 'withdrawalStep2' }));

  const Icon = getModalIconByMethod(paymentMethod);

  return (
    <WithdrawalBase
      closePortal={closePortal}
      title={t(`deleteModalTitle`)}
      className={styles.modal}
      showBackArrow={false}
    >
      <div className={styles.wrapper}>
        <div className={styles.requisiteContainer}>
          <Icon />
          <div className={styles.requisiteInfo}>
            {activeRequisite?.bank?.name ? (
              <div className={styles.requisiteComment}>{activeRequisite?.bank?.name}</div>
            ) : (
              <div className={styles.requisiteComment}>Кошелек {activeRequisite?.index}</div>
            )}
            {activeRequisite?.cardNumber && (
              <div className={styles.requisiteNumber}>
                {getMaskedRequisit(activeRequisite?.cardNumber, paymentMethod)}
              </div>
            )}
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          <Button buttonText='Назад' reversed={true} onClick={handleBack} />
          <Button
            buttonText='Удалить реквизит'
            onClick={accessToken && handleSubmit}
            isLoading={deleteMutation.isPending}
          />
        </div>
      </div>
    </WithdrawalBase>
  );
};
